/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ApolloError, DocumentNode, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { UserContext } from '../context/user-context';
import { isTokenAboutToExpire } from '../utils/refresh-token';

interface Props {
  mutation: DocumentNode;
  onCompleted?: (data: any) => void;
  onError?: (e: any) => void;
}

interface IMutateReq {
  variables?: any;
}
interface UseQueryHookResponse {
  data: any;
  loading: boolean;
  called: boolean;
  error?: ApolloError;
  mutate: (data: IMutateReq) => Promise<any>;
}

export const useMutationHook = ({
  mutation,
  onCompleted = () => {},
  onError = () => {},
}: Props): UseQueryHookResponse => {
  const userContext = useContext(UserContext);
  // checkAndRefreshToken(userContext.token);
  const [gqlMutate, { data, loading, error, called }] = useMutation(mutation, {
    onCompleted: data => {
      onCompleted(data);
    },
    onError: e => {
      console.log(e);
      onError(e);
    }
  });

  const mutate = async ({ variables }: IMutateReq) => {
    if (isTokenAboutToExpire(userContext.token)) {
      console.log(`Refreshing the token`);
      const user = await Auth.currentAuthenticatedUser({bypassCache: true});
      userContext.setUser(user);
      const newToken = user?.signInUserSession?.accessToken?.jwtToken;
      await gqlMutate({
        context: {
          headers: {
            authorization: newToken,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        variables,
      });
      return;
    }
    await gqlMutate({
      context: {
        headers: {
          authorization: userContext.token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      variables,
    });
    return;
  };

  return { data, loading, mutate, error, called };
};
