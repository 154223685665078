export interface Patron {
  _id: string;
  name?: string;
  email?: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  cognitoID?: string;
  currentLoginMechanism?: string;
}

export enum UserType {
  patron = 'patron',
}
