import { Item } from "../../types";

type Device = {
  token: string;
  browser: string;
  os: string;
};

export type Store = {
  id: string;
  ownerID: string;
  storeName: string;
  displayName: string;
  storeFrontImageUrl: string;
  isOpen: boolean;
  isDisabled: boolean;
  updatedOn: Date;
  createdOn: Date;
  isDeleted: boolean;
};

export interface StoreDetails {
  menu: Menu;
  owner: Vendor;
}

export interface StoreWithDetails extends Store, StoreDetails { }

export type Vendor = {
  _id?: string;
  businessName: string;
  tradingName: string;
  imageUrl: string;
  abn: string;
  bankAccount: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  hoursOfOperation: string;
  venueID: string;
  sectionIDs?: string[];
  isOpen: boolean;
  stores: Array<Store>;
  isDisabled?: boolean;
};

export type MenuItemGroupItem = {
  storeID: string;
  itemID: string;
  position: number;
  item: Item;
};

export interface MenuItemGroup {
  id: string;
  name: string;
  itemIDs: Array<MenuItemGroupItem>;
  position: number;
}

export type Menu = {
  id: string;
  ownerID: string;
  description: string;
  imageUri?: string;
  itemGroups: Array<MenuItemGroup>;
  updatedOn?: Date;
  createdOn?: Date;
  isActive: boolean;
  version?: number;
};



export enum integrationTypes {
  POS = "POS",
  PMS = "PMS"
}

export enum integrationStatus {
  Pending = "PENDING",
  Processing = "PROCESSING",
  Active = "ACTIVE",
  Failed = "FAILED",
  Inactive = "INACTIVE",
  Disconnected = "DISCONNECTED",
}

export interface IntegrationMeta {
  id: string;
  name: string;
  label: string;
  ownerID: string;
  recieverID: string;
  connectedOn: Date;
  updatedOn: Date;
  status: integrationStatus;
  type: integrationTypes;
  meta: unknown;
}

export interface POSStoreIntegration extends IntegrationMeta {
  meta: {
    storeID: string; // Liively Store that is connected.

    settings: {
      usePOSMenu: boolean; // Indicates if POS menu is fetched and used. Enabled by default  when Integration is Active.
      pushOrders: boolean; // Indicates if new Liively Orders are pushed to POS. Enabled by default when Integration is Active.
    };
    platformMeta: {
      locationHash: string; // Doshii Location ID
    };
    dataSyncInfo?: {
      menuSyncdAt: Date;
      syncdMenuID: string;
    };
  };
}

export interface PMSIntegration extends IntegrationMeta {
  meta: {
    settings: {
      usePMSPatronLandingPage: boolean;
    }
    platformMeta: {
      hotelID: string;
      apiKey: string;
    }
    dataSyncInfo?: Record<string, unknown>;
  };
}