import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  visible?: boolean;
  style?: any;
}

export function Loader({ visible = false, style={} }: Props) {
  if (!visible) {
    return <></>;
  }

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'fixed',
        height: '100%',
        width: '100vw',
        zIndex: 1000,
        ...style
      }}
    >
      <CircularProgress size={35} />
    </Box>
  );
}
