/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge, IconButton } from '@mui/material';
import { useQueryHook } from '../hooks/use-query.hook';
import { GET_ALL_NOTIFICATIONS } from '../graphql/query';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_SUBSCRIPTION } from '../graphql/subscriptions';
import { useSubscription } from '@apollo/client';
import { NotificationContext } from '../context/notification-context';
import Fab from '@mui/material/Fab';
import { UserContext } from '../context/user-context';
import { NOTIFICATIONS_ROUTE } from '../constants/routes';
import { useState } from 'react';
import { useEffect } from 'react';
import { OrderContext } from '../context/order-context';
import { NotificationType } from '../types/notification.types';

const sleep = (timeout: number) =>
  new Promise(resolve => setTimeout(resolve, timeout));

export const Notification = () => {
  const { patron } = useContext(UserContext);
  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);
  const [open, setOpen] = useState(false);
  const orderContext = useContext(OrderContext);

  const { data, fetch: fetchGetAllNotifications } = useQueryHook({
    query: GET_ALL_NOTIFICATIONS,
    skip: !patron,
    variables: {
      payload: {
        filter: {
          subscriberID: patron?._id,
          seen: false,
        },
        limit: 10,
      },
    },
  });

  useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: {
      subscriberID: patron?._id,
    },
    onSubscriptionData: async (data: any) => {
      notificationContext.callSubscribers();

      await sleep(1000);

      await fetchGetAllNotifications({
        payload: {
          filter: {
            subscriberID: patron?._id,
            seen: false,
          },
          limit: 10,
        },
      });
    },
  });

  const notifications = data?.getAllNotifications || [];
  const badgeContent = notifications.length > 10 ? '10+' : notifications.length;

  useEffect(() => {
    if (notifications.length) {
      setOpen(true);
      const latestNotification = notifications[0];
      if (latestNotification.type === NotificationType.ORDER) {
        orderContext.fetchActiveOrders();
      }
    }
  }, [notifications]);

  if (!notifications || !notifications.length) {
    return <></>;
  }

  if (!open) {
    return <></>;
  }

  return (
    <Fab
      color="primary"
      sx={{
        position: 'fixed',
        top: 80,
        right: 20,
      }}
      onClick={() => {
        navigate(NOTIFICATIONS_ROUTE);
      }}
    >
      <IconButton>
        <Badge badgeContent={badgeContent} color="primary">
          <NotificationsActiveIcon
            sx={{
              color: 'white',
            }}
          />
        </Badge>
      </IconButton>
    </Fab>
  );
};
