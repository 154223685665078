import { ReactNode } from "react";
import { OrderStatus, PatronOrderStatus, ServiceMethod } from "../types/order";
import { ReactComponent as RunnerIcon } from '../assets/icons/Runner.svg';
import { ReactComponent as VendorIcon } from '../assets/icons/VendorSticker.svg';
import { ReactComponent as PreparingIcon } from '../assets/icons/PreparingSticker.svg';
import { ReactComponent as PaymentFailIcon } from '../assets/icons/PaymentFail.svg';

type OrderStatusConfig = {
  [key: string]: {
    text?: string;
    textByServiceMethod?: (serviceMethod: string) => string;
    backgroundColor: string;
    header?: string;
    subHeader?: string;
    borderColor?: string;
    statusIcon?: ReactNode;
    headerColor?: string;
    headerTextColor?: string;
    bannerColor?: string;
    bodyTextColor?: string;
    helperTextColor?: string;
  };
};

export const orderStatusConfig: OrderStatusConfig = {
  [OrderStatus.RECEIVED]: {
    text: 'patron_portal.vendorOrder.msgStatusPending',
    headerTextColor: '#D2FF28',
    backgroundColor: '#667085',
    header: 'patron_portal.vendorOrder.titleStatusPending',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.PAYMENT_PENDING]: {
    text: 'patron_portal.vendorOrder.msgStatusPaymentPending',
    backgroundColor: '#FFF6A3',
    header: 'patron_portal.vendorOrder.titleStatusPaymentPending',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.ACCEPTED]: {
    text: 'patron_portal.vendorOrder.msgStatusAccepted',
    headerTextColor: '#2E7CF6',
    backgroundColor: '#C1E3F7',
    header: 'patron_portal.vendorOrder.titleStatusAccepted',
    statusIcon: <PreparingIcon />,
  },
  [OrderStatus.PREPARING]: {
    text: 'patron_portal.vendorOrder.msgStatusPreparing',
    headerTextColor: '#2E7CF6',
    backgroundColor: '#C1E3F7',
    header: 'patron_portal.vendorOrder.titleStatusPreparing',
    statusIcon: <PreparingIcon />,
  },
  [OrderStatus.PREPARED]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR
        ? 'patron_portal.vendorOrder.msgStatusReady'
        : serviceMethod === ServiceMethod.COLLECTION_POINT
          ? 'patron_portal.vendorOrder.msgStatusReadyCP'
          : 'patron_portal.vendorOrder.msgStatusReadyTTS';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    borderColor: '#D2FF28',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.READY_TO_COLLECT]: {
    text: 'patron_portal.vendorOrder.msgStatusReady',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    borderColor: '#D2FF28',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.ASSIGNED]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusAssignedCP'
        : 'patron_portal.vendorOrder.msgStatusAssignedTTS';
    },
    headerTextColor: '#FFFFFF',
    backgroundColor: '#00C2FF',
    bannerColor: '#2E7CF6',
    header: 'patron_portal.vendorOrder.titleStatusAssigned',
    subHeader: 'patron_portal.vendorOrder.subHeaderPicked',
    bodyTextColor: '#FFFFFF',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.PICKED]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusPickedCP'
        : 'patron_portal.vendorOrder.msgStatusPickedTTS';
    },
    headerTextColor: '#FFFFFF',
    backgroundColor: '#00C2FF',
    bannerColor: '#2E7CF6',
    header: 'patron_portal.vendorOrder.titleStatusPicked',
    subHeader: 'patron_portal.vendorOrder.subHeaderPicked',
    bodyTextColor: '#FFFFFF',
    statusIcon: <RunnerIcon />,
  },
  [OrderStatus.AT_COLLECTION_POINT]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusPickedCP'
        : 'patron_portal.vendorOrder.msgStatusPickedTTS';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    subHeader: 'patron_portal.vendorOrder.subHeaderAtCollectionPoint',
    borderColor: '#D2FF28',
    statusIcon: <RunnerIcon />,
  },
  [OrderStatus.COMPLETE]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.DELIVERY
        ? 'patron_portal.vendorOrder.msgStatusCompletedTTS'
        : 'patron_portal.vendorOrder.msgStatusCompletedCP';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#BBBCC7',
    bannerColor: '#BBBCC7',
    header: 'patron_portal.vendorOrder.titleStatusCompleted',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.DELIVERED]: {
    text: 'patron_portal.vendorOrder.msgStatusDelivered',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#BBBCC7',
    header: 'patron_portal.vendorOrder.titleStatusDelivered',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.REJECTED]: {
    text: 'patron_portal.vendorOrder.msgStatusRejected',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#FFB3FF',
    header: 'patron_portal.vendorOrder.titleStatusRejected',
    statusIcon: <VendorIcon />,
  },
  [OrderStatus.PAYMENT_REJECTED]: {
    text: 'patron_portal.vendorOrder.msgStatusAzuPaymentRejected',
    headerTextColor: '#FFFFFF',
    backgroundColor: '#F84244',
    header: 'patron_portal.vendorOrder.titleStatusRejected',
    statusIcon: <PaymentFailIcon />,
  },
  [OrderStatus.PATRON_CANCELLED]: {
    text: 'patron_portal.vendorOrder.msgStatusCancelled',
    headerTextColor: '#FFF',
    backgroundColor: '#F84244',
    header: 'patron_portal.vendorOrder.titleStatusCancelled',
    statusIcon: <VendorIcon />,
  },
};

type PatronOrderStatusConfig = {
  [key: string]: {
    text: string,
  }
}


export const patronOrderStatusConfig: PatronOrderStatusConfig = {
  [PatronOrderStatus.RECEIVED]: {
    text: 'Received',
  },
  [PatronOrderStatus.PREPARING]: {
    text: 'Preparing'
  },
  [PatronOrderStatus.PREPARED]: {
    text: 'Prepared'
  },
  [PatronOrderStatus.READY_TO_COLLECT]: {
    text: 'Ready to Collect'
  },
  [PatronOrderStatus.PARTIALLY_READY_TO_COLLECT]: {
    text: 'Ready to Collect'
  },
  [PatronOrderStatus.ASSIGNED]: {
    text: 'Runner Assigned'
  },
  [PatronOrderStatus.PARTIALLY_ASSIGNED]: {
    text: 'Runner Assigned'
  },
  [PatronOrderStatus.DELIVERED]: {
    text: 'Delivered'
  },
  [PatronOrderStatus.PATRON_CANCELLED]: {
    text: 'Cancelled'
  },
  [PatronOrderStatus.REJECTED]: {
    text: 'Rejected'
  },
  [PatronOrderStatus.PAYMENT_REJECTED]: {
    text: 'Payment Failed'
  },
}