import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactElement;
  buttonCount?: number;
  bannerActive?: boolean;
  style?: any;
}

export const BottomBox = ({ children, style }: Props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: 'auto',
        bottom: 0,
        boxSizing: 'border-box',
        ...style
      }}
    >
      {children}
    </Box>
  );
};
