import { Divider } from '@mui/material';

interface Props {
  orientation?: 'horizontal' | 'vertical' | undefined;
  borderStyle?: string;
  color?: string;
}

export const Border = ({
  orientation = 'horizontal',
  borderStyle = '',
  color = 'black',
}: Props) => {
  return (
    <Divider
      orientation={orientation}
      sx={{
        borderColor: color,
        width: '100%',
        borderStyle: borderStyle,
      }}
    />
  );
};
