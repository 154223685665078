import { Box, Collapse, Grid, styled } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Border } from '../elements/border';
import { Text } from '../elements/text';
import {
  OrderStatus,
  ServiceMethod,
  VendorOrder,
} from '../types/order';
import { useState } from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { ReactComponent as RunnerIcon } from '../assets/icons/Runner.svg';
import { ReactComponent as VendorIcon } from '../assets/icons/VendorSticker.svg';
import { ReactComponent as PreparingIcon } from '../assets/icons/PreparingSticker.svg';
import { ReactComponent as PaymentFailIcon } from '../assets/icons/PaymentFail.svg';
import { VenueTypes } from '../pages/home/home';

export const orderStatusConfig: OrderStatusConfig = {
  [OrderStatus.RECEIVED]: {
    text: 'patron_portal.vendorOrder.msgStatusPending',
    headerTextColor: '#D2FF28',
    backgroundColor: '#667085',
    header: 'patron_portal.vendorOrder.titleStatusPending',
    statusIcon: <VendorIcon />,
    stepperIndex: 0,
  },
  [OrderStatus.PAYMENT_PENDING]: {
    text: 'patron_portal.vendorOrder.msgStatusPaymentPending',
    backgroundColor: '#FFF6A3',
    header: 'patron_portal.vendorOrder.titleStatusPaymentPending',
    statusIcon: <VendorIcon />,
    stepperIndex: 0,
  },
  [OrderStatus.ACCEPTED]: {
    text: 'patron_portal.vendorOrder.msgStatusAccepted',
    headerTextColor: '#2E7CF6',
    backgroundColor: '#00C2FF',
    header: 'patron_portal.vendorOrder.titleStatusAccepted',
    statusIcon: <PreparingIcon />,
    stepperIndex: 1,
  },
  [OrderStatus.PREPARING]: {
    text: 'patron_portal.vendorOrder.msgStatusPreparing',
    headerTextColor: '#2E7CF6',
    backgroundColor: '#00C2FF',
    header: 'patron_portal.vendorOrder.titleStatusPreparing',
    statusIcon: <PreparingIcon />,
    stepperIndex: 1,
  },
  [OrderStatus.PREPARED]: {
    textByServiceMethod: (
      serviceMethod: string,
      venueType?: string,
      seat?: string,
    ) => {
      if (venueType === VenueTypes.DINE_IN && seat !== '0') {
        return 'patron_portal.vendorOrder.msgStatusReadyForDelivery';
      }
      return serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR
        ? 'patron_portal.vendorOrder.msgStatusReady'
        : serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusReadyCP'
        : 'patron_portal.vendorOrder.msgStatusReadyTTS';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    borderColor: '#D2FF28',
    statusIcon: <VendorIcon />,
    stepperIndex: 2,
  },
  [OrderStatus.READY_TO_COLLECT]: {
    text: 'patron_portal.vendorOrder.msgStatusReady',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    borderColor: '#D2FF28',
    statusIcon: <VendorIcon />,
    stepperIndex: 2,
  },
  [OrderStatus.ASSIGNED]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusAssignedCP'
        : 'patron_portal.vendorOrder.msgStatusAssignedTTS';
    },
    headerTextColor: '#FFFFFF',
    backgroundColor: '#00C2FF',
    bannerColor: '#2E7CF6',
    header: 'patron_portal.vendorOrder.titleStatusAssigned',
    subHeader: 'patron_portal.vendorOrder.subHeaderPicked',
    bodyTextColor: '#FFFFFF',
    statusIcon: <VendorIcon />,
    stepperIndex: 2,
  },
  [OrderStatus.PICKED]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusPickedCP'
        : 'patron_portal.vendorOrder.msgStatusPickedTTS';
    },
    headerTextColor: '#FFFFFF',
    backgroundColor: '#00C2FF',
    bannerColor: '#2E7CF6',
    header: 'patron_portal.vendorOrder.titleStatusPicked',
    subHeader: 'patron_portal.vendorOrder.subHeaderPicked',
    bodyTextColor: '#FFFFFF',
    statusIcon: <RunnerIcon />,
    stepperIndex: 2,
  },
  [OrderStatus.AT_COLLECTION_POINT]: {
    textByServiceMethod: (serviceMethod: string) => {
      return serviceMethod === ServiceMethod.COLLECTION_POINT
        ? 'patron_portal.vendorOrder.msgStatusPickedCP'
        : 'patron_portal.vendorOrder.msgStatusPickedTTS';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#D2FF28',
    bannerColor: '#D2FF28',
    header: 'patron_portal.vendorOrder.titleStatusReady',
    subHeader: 'patron_portal.vendorOrder.subHeaderAtCollectionPoint',
    borderColor: '#D2FF28',
    statusIcon: <RunnerIcon />,
    stepperIndex: 2,
  },
  [OrderStatus.COMPLETE]: {
    textByServiceMethod: (
      serviceMethod: string,
      venueType?: string,
      seat?: string,
    ) => {
      if (venueType === VenueTypes.DINE_IN && seat !== '0') {
        return 'patron_portal.vendorOrder.msgStatusCompletedTTS';
      }
      return serviceMethod === ServiceMethod.DELIVERY
        ? 'patron_portal.vendorOrder.msgStatusCompletedTTS'
        : 'patron_portal.vendorOrder.msgStatusCompletedCP';
    },
    headerTextColor: '#2A2A2A',
    backgroundColor: '#000000',
    bannerColor: '#BBBCC7',
    header: 'patron_portal.vendorOrder.titleStatusCompleted',
    statusIcon: <VendorIcon />,
    stepperIndex: 3,
  },
  [OrderStatus.DELIVERED]: {
    text: 'patron_portal.vendorOrder.msgStatusDelivered',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#000000',
    header: 'patron_portal.vendorOrder.titleStatusDelivered',
    statusIcon: <VendorIcon />,
    stepperIndex: 3,
  },
  [OrderStatus.REJECTED]: {
    text: 'patron_portal.vendorOrder.msgStatusRejected',
    headerTextColor: '#2A2A2A',
    backgroundColor: '#F84244',
    header: 'patron_portal.vendorOrder.titleStatusRejected',
    statusIcon: <VendorIcon />,
    stepperIndex: 0,
  },
  [OrderStatus.PAYMENT_REJECTED]: {
    text: 'patron_portal.vendorOrder.msgStatusAzuPaymentRejected',
    headerTextColor: '#FFFFFF',
    backgroundColor: '#F84244',
    header: 'patron_portal.vendorOrder.titleStatusRejected',
    statusIcon: <PaymentFailIcon />,
    stepperIndex: 0,
  },
  [OrderStatus.PATRON_CANCELLED]: {
    text: 'patron_portal.vendorOrder.msgStatusCancelled',
    headerTextColor: '#FFF',
    backgroundColor: '#F84244',
    header: 'patron_portal.vendorOrder.titleStatusCancelled',
    statusIcon: <VendorIcon />,
    stepperIndex: 0,
  },
};

const optionSymbol: { [key: string]: string } = {
  singleSelect: '',
  multiSelect: '+',
};

interface IVendorOrderCardProps {
  order: VendorOrder;
  index: number;
  isPastOrder: boolean;
}

type OrderStatusConfig = {
  [key: string]: {
    text?: string;
    textByServiceMethod?: (
      serviceMethod: string,
      venueType?: string,
      seat?: string,
    ) => string;
    backgroundColor: string;
    header?: string;
    subHeader?: string;
    borderColor?: string;
    statusIcon?: ReactNode;
    headerColor?: string;
    headerTextColor?: string;
    bannerColor?: string;
    bodyTextColor?: string;
    helperTextColor?: string;
    stepperIndex: number;
  };
};

const Elipse = styled(Box)(() => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 14,
}));

export const VendorOrderCard = ({
  order,
  index,
  isPastOrder,
}: IVendorOrderCardProps) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const [isExpanded, setIsExpanded] = useState(false);

  return isPastOrder ? (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <ItemsCard order={order} isPastOrder={isPastOrder} />
      </Box>
    </>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Collapse
          in={isExpanded}
          sx={{
            width: '100%',
            '&.MuiCollapse-hidden': {
              display: 'none',
            },
          }}
          timeout="auto"
          unmountOnExit
        >
          <ItemsCard order={order} isPastOrder={isPastOrder}/>
        </Collapse>
        <Box
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: '8px',
          }}
        >
          <Text
            text={
              isExpanded
                ? t('patron_portal.order.labelHideOrderDetails', {
                    ns: 'patron_portal',
                  })
                : t('patron_portal.order.labelViewOrderDetails', {
                    ns: 'patron_portal',
                  })
            }
            fontWeight={700}
            fontSize={16}
          />
          <Box>
            {isExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface ItemsCardProp {
  order: VendorOrder;
  isPastOrder: boolean;
}

const ItemsCard = ({ order, isPastOrder}: ItemsCardProp) => {
  const { t } = useTranslation(['patron_portal', 'common']);

  return (
    <Box mx={'16px'} mb={'6px'}>
      {order?.items?.map(item => {
        const itemOptions = item.customisations || [];
        return (
          <Grid key={item._id} mt={2} item xs={12}>
            <Box display={'flex'}>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Elipse
                  style={{
                    background: isPastOrder
                      ? item.isRejected ||
                        order.orderStatus === OrderStatus.REJECTED
                        ? '#CECECE'
                        : 'none'
                      : 
                      item.isUpdated
                      ? '#CECECE'
                      : '#F6FFD6',
                  }}
                >
                  <Text
                    text={
                      item?.isUpdated
                        ? item?.updatedQuantity.toString()
                        : item?.count?.toString()
                    }
                    style={{
                      fontWeight: '550',
                      textDecoration: item.isRejected ? 'line-through' : ( isPastOrder && order.orderStatus === OrderStatus.REJECTED ? 'line-through': 'none' ),
                      color: item.isRejected && !isPastOrder
                        ? '#D4D4D4'
                        : isPastOrder
                        ? item.isUpdated
                          ? 'none'
                          : '#2A2A2A'
                        : 'none',
                    }}
                  />
                </Elipse>
              </Grid>
              <Grid item xs={isPastOrder? 8: item.isRejected || item.isUpdated ? 5 : 8}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box>
                    <Text
                      text={item.name}
                      fontSize={16}
                      fontWeight={500}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: item.isRejected ? '#CECECE' : ( isPastOrder && order.orderStatus === OrderStatus.REJECTED ? '#CECECE': '#2A2A2A' ),
                        textDecoration:
                          item.isRejected ||
                          order.orderStatus === OrderStatus.REJECTED
                            ? 'line-through'
                            : 'none',
                      }}
                    />
                  </Box>
                  {(itemOptions.length > 0 && !isPastOrder) && (
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                      <Text
                        text={t('patron_portal.order.labelAddSelections', {
                          ns: 'patron_portal',
                        })}
                        fontSize={12}
                        fontWeight={400}
                        style={{
                          paddingTop: '2px',
                          color: item.isRejected ? '#CECECE' : '#2A2A2A',
                          textDecoration:
                            item.isRejected ||
                            order.orderStatus === OrderStatus.REJECTED
                              ? 'line-through'
                              : 'none',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={5} display={'flex'} alignItems={'center'}>
                {order.orderStatus != OrderStatus.REJECTED && (
                  <Box flex="1" overflow="hidden">
                    {(item.isRejected && !isPastOrder) && (
                      <Text
                        text={t('patron_portal.order.statusShortRejected', {
                          ns: 'patron_portal',
                        })}
                        fontSize={13}
                        fontColor="red"
                        style={{
                          paddingLeft: 2,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      />
                    )}
                    {(item.isUpdated && !isPastOrder )&& (
                      <Text
                        text={t('patron_portal.order.msgAvailableItemCount', {
                          ns: 'patron_portal',
                          updatedQuantity: item.updatedQuantity,
                          count: item.count,
                        })}
                        fontSize={13}
                        fontColor="red"
                        style={{
                          paddingLeft: '2px',
                          paddingRight: '2px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      />
                    )}
                  </Box>
                )}
              </Grid>
            </Box>

            <Box width={'100%'} mt={2}>
              <Border color={'#D1C7C7'} />
            </Box>
          </Grid>
        );
      })}
    </Box>
  );
};
