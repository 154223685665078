import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CAPTURE_PATRON_DETAILS,
  SSO_AUTH_SUCCESS,
  VIEW_CART_ROUTE,
} from '../constants/routes';
import { UserContext } from '../context/user-context';

interface IUserPostLoginNavigateHook {
  postNavigationHandler: () => void;
}

export const usePostLoginNavigation = (): IUserPostLoginNavigateHook => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const handler = () => {
    if (!userContext.isLoggedIn) {
      return;
    }
    const user = userContext.cognitoUser;

    const payload = user?.signInUserSession?.idToken?.payload || {};
    const isSSOAuth = !!payload?.identities?.find(
      (i: any) =>
        i.providerName?.toLowerCase() == 'google' ||
        i.providerName?.toLowerCase() == 'apple',
    );

    const isPatronIDAvailable = !!userContext?.patron?._id;
    const isPatronDetailsCaptured = !!userContext?.patron?.name;

    if (isPatronIDAvailable && !isPatronDetailsCaptured) {
      navigate(CAPTURE_PATRON_DETAILS);
      return;
    }

    if (isSSOAuth && (!isPatronDetailsCaptured || !userContext.phoneNumber)) {
      navigate(SSO_AUTH_SUCCESS);
      return;
    }

    navigate(VIEW_CART_ROUTE);
  };

  return { postNavigationHandler: handler };
};
