import {
  Box,
  Button,
  Collapse,
  Dialog,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { Header } from '../components/header';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useTranslation } from 'react-i18next';
import i18next from '../i18n/config';
import { Text } from '../elements/text';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Image } from '../elements/image';
import {
  CUSTOMER_SUPPORT_URL,
  getOrderTrackingRoute,
  LIST_VENDORS_PAGE_ROUTE,
  VIEW_ALL_ORDERS,
  VIEW_CART_ROUTE,
} from '../constants/routes';
import { GET_PATRON_ORDER, GET_VENUE } from '../graphql/query';
import { Loader } from '../elements/loader';
import { useContext, useState } from 'react';
import {
  OrderStatus,
  PatronOrder,
  PatronOrderStatus,
  ServiceMethod,
  VendorOrder,
} from '../types/order';
import { CustomInput } from '../elements/input';
import { Border } from '../elements/border';
import { CustomButton } from '../elements/button';
import { getPriceWithCurrency } from '../utils/charges';
import {
  orderStatusConfig,
  VendorOrderCard,
} from '../components/vendor-order-card';
import { useEffect } from 'react';
import { OrderContext } from '../context/order-context';
import { useQueryHook } from '../hooks/use-query.hook';
import { NotificationContext } from '../context/notification-context';
import { CartContext, CartItem } from '../context/cart-context';
import { useMutationHook } from '../hooks/use-mutation';
import { CANCEL_ORDER } from '../graphql/mutation';
import { CustomerSupportButton } from '../components/customer-support-button';
import { CompoundText } from '../elements/compound-text';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { NetworkErrorPage } from './network-error';
import { ReactComponent as SneakerIcon } from '../assets/icons/SneakerSticker.svg';
import Marquee from 'react-fast-marquee';
import { useQuery } from '@apollo/client';
import { AppContext } from '../context/app-context';
import { CollectionPoint, Venue } from '../types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { OrderStatusText } from '../components/order-tracking-page/order-status-text';
import { v1 } from 'uuid';
import { OrderTrackingInfo } from '../components/order-tracking-page/order-tracking-card';
import { getEntryPointRoute } from '../utils/get-entry-point-route';
import { VenueTypes } from './home/home';

export const OrderTracking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const { orderID = '' } = useParams();
  const [searchParams] = useSearchParams();
  const notificationContext = useContext(NotificationContext);
  const cartContext = useContext(CartContext);
  const appContext = useContext(AppContext);
  const orderContext = useContext(OrderContext);
  const [openModal, setOpenModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data,
    loading: initialLoading,
    fetch: fetchGetPatronOrder,
    error: patronOrderError,
    errorObject,
  } = useQueryHook({
    query: GET_PATRON_ORDER,
    variables: {
      id: orderID,
    },
    onCompleted: (data) => {
      const location = data?.getPatronOrder.location
      fetchVenueData({
        id: location?.venue
      })
    }
  });

  const TIME_INTERVAL_IN_SECS = 60;
  const reloadOrder = async (payload = {}, isAutoReload = false) => {
    if (isAutoReload) {
      let orderLastRefreshedAt = sessionStorage.getItem('orderLastRefreshedAt');
      if (!orderLastRefreshedAt) {
        sessionStorage.setItem('orderLastRefreshedAt', `${Date.now()}`);
        orderLastRefreshedAt =
          sessionStorage.getItem('orderLastRefreshedAt') || '';
      }
      if (
        Math.floor((Date.now() - parseInt(orderLastRefreshedAt)) / 1000) <
        TIME_INTERVAL_IN_SECS
      ) {
        return;
      }
    }
    await fetchGetPatronOrder(payload);
    sessionStorage.setItem('orderLastRefreshedAt', `${Date.now()}`);
  };

  useEffect(() => {
    history.pushState('view-all-orders', '', VIEW_ALL_ORDERS);
  }, []);

  // Reload order status at regular intervals
  useEffect(() => {
    const interval = setInterval(async () => {
      await fetchGetPatronOrder(
        {
          id: orderID,
        },
        true,
      );
    }, (TIME_INTERVAL_IN_SECS + 1) * 1000);
    return () => clearInterval(interval);
  }, []);

  const { mutate: cancelOrder, loading: cancelOrderLoading } = useMutationHook({
    mutation: CANCEL_ORDER,
    onCompleted: () => {
      reloadOrder({
        id: orderID,
      });
    },
  });

  useEffect(() => {
    if (
      searchParams.get('payment_intent') ||
      searchParams.get('payment_intent_client_secret')
    ) {
      orderContext.clear();
      cartContext.clear();

      navigate(getOrderTrackingRoute(orderID));
    }

    notificationContext.addSubcribers({
      id: 'order-tracking-fetch-order',
      call: () => {
        reloadOrder({ id: orderID });
      },
    });

    return () => {
      notificationContext.clear();
    };
  }, []);

  const order: PatronOrder = data?.getPatronOrder;
  const location = order?.location;
  const vendorOrders = order?.vendorOrders || [];

  const { loading: fetchingVenue, data: venueData, fetch: fetchVenueData } = useQueryHook({
    query: GET_VENUE,
    variables: {
      id: location?.venue,
    },
    skipInitialCall: true
  });
  const venue: Venue = venueData?.getVenue;

  const selectedCollectionPoint = venue?.collectionPoints?.find(
    (_collectionPoint: CollectionPoint) =>
      _collectionPoint?.id === location?.collectionPoint,
  );

  const getCancellationStatus = () => {
    if (order?.orderStatus === PatronOrderStatus.REJECTED) return false;
    const ordersWithoutRejected = vendorOrders.filter(
      vendorOrder => vendorOrder.orderStatus !== OrderStatus.REJECTED,
    );
    const changedVendorOrder = ordersWithoutRejected.find(
      vendorOrder => vendorOrder.orderStatus !== OrderStatus.RECEIVED,
    );
    if (changedVendorOrder) return false;
    return true;
  };

  const canCancel = getCancellationStatus();
  const cancelOrderHandler = () => {
    cancelOrder({
      variables: {
        id: orderID,
      },
    });
  };

  if (initialLoading || fetchingVenue) {
    return <Loader visible={true} />;
  }

  const itemsPaymentFailed = () => {
    const paymentFailedOrders = vendorOrders.filter(
      vendorOrder => vendorOrder.orderStatus === OrderStatus.PAYMENT_REJECTED,
    );

    const itemsToCart: CartItem[] = paymentFailedOrders.flatMap(vendorOrder =>
      vendorOrder.items.map(item => ({
        id: item._id,
        name: item.name,
        count: item.count,
        customisations: item.customisations?.map(
          customisation => customisation._id,
        ),
      })),
    );
    return itemsToCart;
  };

  const header = (
    <Grid item xs={12}>
      <Header
        backIconRoute={VIEW_ALL_ORDERS}
        middleComponent={
          <Text
            fontSize={18}
            fontWeight={700}
            text={t('patron_portal.order.labelMyOrder', {
              ns: 'patron_portal',
            })}
          />
        }
      />
    </Grid>
  );

  const vendorOrderStatuses = order?.vendorOrders?.map(
    vendorOrder => vendorOrder.orderStatus,
  );
  let navigationImage = venue?.patronNavigationImageUrl || '';
  switch (order?.serviceMethod) {
    case ServiceMethod.COLLECT_FROM_VENDOR:
      navigationImage =
        venue?.sections.find(section => section.id === order.location.section)
          ?.cncNavigationImageUrl ||
        venue?.patronNavigationImageUrl ||
        '';
      break;
    case ServiceMethod.COLLECTION_POINT:
      navigationImage =
        venue?.sections.find(section => section.id === order.location.section)
          ?.cncNavigationImageUrl ||
        venue?.patronNavigationImageUrl ||
        '';
      break;

    default:
      navigationImage = venue?.patronNavigationImageUrl || '';
      break;
  }

  return (
    <Grid
      container
      xs={12}
      sm={8}
      md={6}
      lg={3}
      margin={'auto'}
      //styling to mock mobile borders on desktop - TBD
      sx={{
        border: { lg: '2px solid #CECECE' },
        borderRadius: { lg: '54px' },
        background: '#F5F5F5',
        padding: { lg: '8px 12px' },
        boxShadow: {
          lg: 'inset 0 0 2px 2px dimgrey, inset 0 0 0 6px #e2e3e9, 0px -1px 13px 9px rgba(255,255,255,0.8) inset',
        },
      }}
    >
      <DirectionModal
        isModalOpen={openModal}
        onClose={() => setOpenModal(false)}
        image={navigationImage}
      />
      <Loader visible={cancelOrderLoading} />
      {header}

      {patronOrderError && errorObject?.networkError && (
        <Dialog
          open={patronOrderError ? true : false}
          PaperComponent={props => (
            <Paper {...props} sx={{ borderRadius: '18px' }} />
          )}
          fullWidth={true}
        >
          <NetworkErrorPage statusIcon={<SneakerIcon />} />
        </Dialog>
      )}

      {vendorOrders?.map((vendorOrder: VendorOrder, index) => {
        const id = vendorOrder._id;
        const vendorOrderNo = vendorOrder.isOrderUpdated
          ? `#${vendorOrder?.orderNumber} (Partial)`
          : `#${vendorOrder?.orderNumber}`;
        return (
          <>
            <Grid item xs={12} key={id} p={2}>
              <OrderTrackingInfo
                order={vendorOrder}
                orderNo={vendorOrderNo}
                orderID={orderID}
                orderStatus={vendorOrder?.orderStatus}
                isClickAndCollectEnabled={order?.isClickAndCollectEnabled}
                serviceMethod={vendorOrder?.serviceMethod}
                orderBannerImage={venue?.venueLandscapeImageUrl}
                index={index}
                collectionPointLabel={selectedCollectionPoint?.label}
              />
            </Grid>
            <Grid item xs={12}>
              <Box width={'100%'}>
                <Border borderStyle={'dashed'} color={'#B5B5B5'} />
              </Box>
            </Grid>
          </>
        );
      })}

      <Grid item container p={2} mb={'186px'}>
        <Grid item xs={6}>
          <Text
            text={t('common.text.total', { ns: 'common' })}
            fontWeight={700}
            fontSize={16}
            style={{
              color:
                order?.pricing?.total != order?.pricing?.initialTotal
                  ? '#D1C7C7'
                  : '#2A2A2A',
            }}
          />
          {order?.pricing?.total != order?.pricing?.initialTotal && (
            <Text text="Revised Total" fontWeight={700} fontSize={16} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Box style={{ float: 'right' }}>
            {order?.pricing?.total != order?.pricing?.initialTotal && (
              <Text
                fontWeight={700}
                fontSize={16}
                text={getPriceWithCurrency(order?.pricing?.initialTotal)}
                style={{
                  textDecoration: 'line-through',
                  color:
                    order?.pricing?.total != order?.pricing?.initialTotal
                      ? '#D1C7C7'
                      : '#2A2A2A',
                }}
              />
            )}
            <Text
              fontWeight={700}
              fontSize={16}
              text={getPriceWithCurrency(
                order?.pricing?.total,
                vendorOrders[0]?.orderCurrency?.symbol,
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={8}
        md={6}
        lg={3}
        p={2}
        m={'auto'}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          background: '#FFFFFF',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow: '0px 6px 14px 2px #F5F5F5',
        }}
      >
        <Grid
          item
          display="flex"
          flexDirection={'column'}
          gap="24px"
          width={'100%'}
        >
          <ActionButton
            orderStatus={vendorOrderStatuses}
            setOpenModal={setOpenModal}
            itemsToCart={itemsPaymentFailed()}
          />
          
          {!canCancel && (
            <CustomerSupportButton
              title={t('common.label.customerSupport', { ns: 'common' })}
            />
          )}
        </Grid>

        {canCancel && (
          <Grid item mt={2} width={'100%'}>
            <Button
              onClick={cancelOrderHandler}
              variant="outlined"
              fullWidth={true}
              sx={{
                marginY: '10px',
                width: '98%',
                height: '48px',
                fontWeight: '600',
                fontSize: '18px',
                WebkitBorderRadius: '30px',
                textTransform: 'none',
                p: '8px',
                color: 'red',
                borderRadius: '30px',
                backgroundColor: 'white',
                boxShadow: '0px 0px 0px 8px white',
                borderColor: 'red',
                border: '1.5px solid red',
                '&:focus': {
                  border: '1px solid red',
                },
              }}
            >
              {t('patron_portal.order.labelCancelButton', {
                ns: 'patron_portal',
              })}
            </Button>
            <Box textAlign={'center'}>
              <Text
                fontSize={14}
                fontColor="red"
                text={t('patron_portal.order.msgCancelButtonAvailability', {
                  ns: 'patron_portal',
                })}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

type ActionButtonProps = {
  orderStatus: OrderStatus[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  itemsToCart: CartItem[];
};

const ActionButton = ({
  orderStatus,
  setOpenModal,
  itemsToCart,
}: ActionButtonProps) => {
  const { addMultipleItemsToCart } = useContext(CartContext);
  const { venueType } = useContext(AppContext);
  const navigate = useNavigate();
  const collectionOrderStatuses = [
    OrderStatus.READY_TO_COLLECT,
    OrderStatus.AT_COLLECTION_POINT,
    OrderStatus.PICKED,
    OrderStatus.PREPARED,
  ];
  const { t } = useTranslation(['patron_portal', 'common']);
  const showDirections =
    orderStatus?.length > 0 && venueType?.value !== VenueTypes.DINE_IN &&
    orderStatus.some(o => collectionOrderStatuses.includes(o));

  if (showDirections) {
    return (
      <CustomButton
        title={t('patron_portal.order.showDirections', { ns: 'patron_portal' })}
        style={{ height: '48px' }}
        onClick={() => {
          setOpenModal(true);
        }}
      />
    );
  }

  const handleCheckoutRetryPayment = () => {
    addMultipleItemsToCart(itemsToCart, navigate(VIEW_CART_ROUTE));
  };

  if (orderStatus?.includes(OrderStatus.PAYMENT_REJECTED)) {
    return (
      <CustomButton
        title={t('patron_portal.order.btnCheckoutRetryPayment', {
          ns: 'patron_portal',
        })}
        style={{ height: '48px' }}
        onClick={handleCheckoutRetryPayment}
      />
    );
  }
  return (
    <CustomButton
      title={t('common.home', { ns: 'common' })}
      style={{ height: '48px' }}
      onClick={() => {
        navigate(getEntryPointRoute(venueType?.value || ''));
      }}
    />
  );
};

const DirectionModal = ({
  isModalOpen,
  onClose,
  image,
}: {
  isModalOpen: boolean;
  onClose: () => void;
  image: string;
}) => {
  return (
    <>
      <Drawer
        anchor="bottom"
        open={isModalOpen}
        onClose={onClose}
        disableAutoFocus={true}
        closeAfterTransition
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            maxHeight: '80%',
            overflowY: 'auto',
            boxShadow: 0,
          },
        }}
      >
        <Box display={'flex'} justifyContent={'center'}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'relative',
            }}
          >
            <CloseRoundedIcon
              sx={{
                fontSize: '20px',
                color: '#2A2A2A',
                cursor: 'pointer',
                padding: '2px',
                borderRadius: '48%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                boxShadow: 1,
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            pointerEvents: 'auto',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            backgroundColor: '#FAFAFA',
            overflow: 'hidden',
            paddingBottom: '30vh',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Image
              style={{
                height: '100%',
                borderRadius: 2,
              }}
              src={image}
            />
            {/* <Box
              m={'20px'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text text={'Collect From'} fontSize={32} fontWeight={900} />
              <Text text={location} fontSize={32} />
            </Box> */}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
