import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { ReactComponent as VendorIcon } from '../../assets/icons/VendorHome.svg';
import { ReactComponent as VendorRejectedIcon } from '../../assets/icons/VendorCancel.svg';
import { ReactComponent as PotIcon } from '../../assets/icons/CookingPot.svg';
import { ReactComponent as CartBagIcon } from '../../assets/icons/CartBag.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import { OrderStatus } from '../../types/order';
import { orderStatusConfig } from '../vendor-order-card';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'rgb(0,0,0)',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'rgb(0,0,0)',

		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
	orderStatus: string;
}>(({ theme, ownerState, orderStatus }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	filter: 'grayscale(100%)',
	...(ownerState.active && {
		backgroundColor: orderStatusConfig[orderStatus]?.backgroundColor,
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		filter: 'grayscale(0%)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'rgb(0,0,0)',
		filter: 'grayscale(0%)',
	}),
}));

interface IColorlibStepIcon extends StepIconProps {
	orderStatus: OrderStatus;
}

const ColorlibStepIcon = (props: IColorlibStepIcon) => {
	const { active, completed, className, orderStatus, icon } = props;

	console.log(active, completed, icon);


	const icons: { [index: string]: React.ReactElement } = {
		1: (orderStatus === OrderStatus.REJECTED) ? <VendorRejectedIcon /> : <VendorIcon />,
		2: <PotIcon />,
		3: <CartBagIcon />,
		4: <CheckIcon />,
	};

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
			orderStatus={orderStatus}
		>
			{icons[String(icon)]}
		</ColorlibStepIconRoot>
	);
}

const steps = [
	'Select campaign settings',
	'Create an ad group',
	'Create an ad',
	'Preview'
];

interface IOrderStatusStepper {
	orderStatus: OrderStatus;

}

export const OrderStatusStepper = (props: IOrderStatusStepper) => {
	const { orderStatus } = props;
	return (
		<Stack sx={{ width: '100%' }}>
			<Stepper
				alternativeLabel
				activeStep={orderStatusConfig[orderStatus]?.stepperIndex}
				connector={<ColorlibConnector />}
			>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel StepIconComponent={(props) => <ColorlibStepIcon orderStatus={orderStatus} {...props} />}></StepLabel>
					</Step>
				))}
			</Stepper>
		</Stack>
	);
}
