import { useMediaQuery } from "@mui/material";
import { Text } from "../../elements/text"

interface IOrderStatusText {
  orderStatus: string;
  fontColor?: string;
}

export const OrderStatusText = ({ orderStatus, fontColor = '#2A2A2A' }: IOrderStatusText) => {
  const isNarrowScreen = useMediaQuery('(max-width: 410px)');

  return (
    <Text
      text={orderStatus}
      fontWeight={700}
      fontSize={isNarrowScreen ? 20 : 24}
      fontColor={fontColor}
      style={{
        lineHeight: 1.3,
        marginTop: '3px'
      }}
    />
  )
}