import './index.css';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import config from './config';
import { getMainDefinition } from '@apollo/client/utilities';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Auth } from 'aws-amplify';

const graphqlURL = config.graphql;

const httpLink = new HttpLink({
  uri: graphqlURL,
});

const wsLink = createSubscriptionHandshakeLink({
  url: config.subscriptionURL,
  region: config.region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => {
      return (await Auth.currentAuthenticatedUser()).signInUserSession
        .accessToken.jwtToken;
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink as any,
  httpLink,
);

export const graphqlClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
