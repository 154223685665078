import { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../elements/button';
import { Image } from '../elements/image';
import { Text } from '../elements/text';
import { CartContext } from '../context/cart-context';
import { Loader } from '../elements/loader';
import { getEntryPointRoute } from '../utils/get-entry-point-route';
import { AppContext } from '../context/app-context';

export const EmptyCart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);
  const { cartLoading } = useContext(CartContext);
  const { venueType, selectedVendor, store: storeInContext } = useContext(AppContext);

  if (cartLoading) {
    return <Loader visible={cartLoading} />;
  }

  console.log(
    selectedVendor,
    getEntryPointRoute(
      venueType?.value || '',
      storeInContext?.value
    ),
  );

  return (
    <Grid container marginTop={12}>
      <Grid item>
        <Box textAlign={'center'}>
          <Box maxWidth={150} maxHeight={200} m="auto">
            <Image src={require('../assets/images/empty-cart.png')} />
          </Box>

          <Box mt={2}>
            <Text
              fontSize={24}
              fontWeight={700}
              text={t('patron_portal.cart.msgEmptyCart', {
                ns: 'patron_portal',
              })}
            />
          </Box>

          <Box mt={1}>
            <Text
              fontSize={18}
              text={t('patron_portal.cart.msgNoItemsAdded', {
                ns: 'patron_portal',
              })}
            />
          </Box>
        </Box>
        <Box mt={4}>
          <CustomButton
            onClick={() =>
              navigate(
                getEntryPointRoute(
                  venueType?.value || '',
                  storeInContext?.value
                ),
              )
            }
            title={t('patron_portal.cart.btnLabelFillCart', {
              ns: 'patron_portal',
            })}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
