import React from 'react';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import { CountryData } from 'react-phone-input-2';
import Config from '../config';

const PhoneInput = React.lazy(() => import('react-phone-input-2' /* webpackChunkName: "react-phone-input" */))

interface Props {
  value: string;
    // eslint-disable-next-line no-unused-vars
  onChange: (phoneNumber: string, cc: string) => void;
}

export const PhoneNumberInput = ({ value: val, onChange: setVal,  }: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);

  return (
    <PhoneInput
      specialLabel=""
      // preferredCountries={['au','ae','us','sa']}
      country={(Config.region==="ap-southeast-2")?'au' : 'ae' }
      placeholder={t('common.label.enterPhoneNumber', { ns: 'common' })}
      value={val}
      autoFormat={false}
      onChange={(val,cc) => {
        setVal('+' + val, cc ? (cc as CountryData).countryCode: "");
        console.log(`val is ${val}, cc is: ${(cc as CountryData).countryCode}`)
      }}
      containerStyle={{
        width: '100%',
      }}
      dropdownStyle={{
        width: 230,
      }}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: false,
      }}
      inputStyle={{
        outline: 'none',
        width: '100%',
        height: 40,
      }}
    />
  );
};
