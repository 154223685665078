import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Divider,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardOutlined';

import { MenuItemGroup, MenuItemGroupItem } from './types';
import { Text } from '../../elements/text';
import { ItemCard } from '../item-card';

function MenuItemGroupList({ itemGroup , defaultExpanded}: { itemGroup: MenuItemGroup, defaultExpanded: boolean }) {
  return (
    <Accordion defaultExpanded = {defaultExpanded}>
      <StyledAccordionSummary expandIcon={<ArrowDownwardIcon />} sx={{ my: 0 }}>
        <Typography variant="h5">{itemGroup.name}</Typography>
      </StyledAccordionSummary>
      <Divider></Divider>
      <AccordionDetails>
        <Stack spacing={2}>
          {itemGroup.itemIDs.map((item: MenuItemGroupItem, index: number) => (
            <React.Fragment key={item.itemID}>
              <ItemCard key={item.itemID} item={item.item} storeID={item.storeID}/>
              {index != itemGroup.itemIDs.length - 1 && <Divider></Divider>}
            </React.Fragment>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default MenuItemGroupList;

// Todo: Move Out
const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => ({
  '&.MuiAccordionSummary-root': {
    minHeight: 40,

    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));
