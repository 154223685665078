import { Text } from '../elements/text';
import {
    Box,
    Button,
    Dialog,
    Divider,
    Grid,
    Paper,
  } from '@mui/material';
import { useQueryHook } from '../hooks/use-query.hook';
import { GET_PATRON_ORDER, GET_VENUE } from '../graphql/query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PatronOrder, VendorOrder } from '../types/order';
import { Image } from '../elements/image';
import logo from '../assets/images/Group 1.png'
import DateObject from "react-date-object";
import { useContext, useEffect } from 'react';
import { Border } from '../elements/border';
import { useTranslation } from 'react-i18next';
import { getPriceWithCurrency } from '../utils/charges';
import { CustomButton } from '../elements/button';
import { getPastOrderTrackingRoute, VIEW_ALL_ORDERS } from '../constants/routes';
import { PastOrderTrackingInfo } from '../components/order-tracking-page/past-order-tracking-card';
import { Loader } from '../elements/loader';
import { NotificationContext } from '../context/notification-context';
import { CartContext } from '../context/cart-context';
import { OrderContext } from '../context/order-context';
import { NetworkErrorPage } from './network-error';
import { ReactComponent as SneakerIcon } from '../assets/icons/SneakerSticker.svg';
import { usePDF } from 'react-to-pdf';

export const PastOrderTracking = () => {

  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const { orderID = '' } = useParams();
  const [searchParams] = useSearchParams();
  const notificationContext = useContext(NotificationContext);
  const cartContext = useContext(CartContext);
  const orderContext = useContext(OrderContext);

  const {
    data,
    loading: initialLoading,
    fetch: fetchGetPatronOrder,
    error: patronOrderError,
    errorObject,
  } = useQueryHook({
    query: GET_PATRON_ORDER,
    variables: {
      id: orderID,
    },
    onCompleted: (data) => {
      const location = data?.getPatronOrder.location
      fetchVenueData({
        id: location?.venue
      })
    }
  });

  const TIME_INTERVAL_IN_SECS = 60;
  const reloadOrder = async (payload = {}, isAutoReload = false) => {
    if (isAutoReload) {
      let orderLastRefreshedAt = sessionStorage.getItem('orderLastRefreshedAt');
      if (!orderLastRefreshedAt) {
        sessionStorage.setItem('orderLastRefreshedAt', `${Date.now()}`);
        orderLastRefreshedAt =
          sessionStorage.getItem('orderLastRefreshedAt') || '';
      }
      if (
        Math.floor((Date.now() - parseInt(orderLastRefreshedAt)) / 1000) <
        TIME_INTERVAL_IN_SECS
      ) {
        return;
      }
    }
    await fetchGetPatronOrder(payload);
    sessionStorage.setItem('orderLastRefreshedAt', `${Date.now()}`);
  };

  useEffect(() => {
    history.pushState('view-all-orders', '', VIEW_ALL_ORDERS);
  }, []);

  // Reload order status at regular intervals
  useEffect(() => {
    const interval = setInterval(async () => {
      await fetchGetPatronOrder(
        {
          id: orderID,
        },
        true,
      );
    }, (TIME_INTERVAL_IN_SECS + 1) * 1000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (
      searchParams.get('payment_intent') ||
      searchParams.get('payment_intent_client_secret')
    ) {
      orderContext.clear();
      cartContext.clear();

      navigate(getPastOrderTrackingRoute(orderID));
    }

    notificationContext.addSubcribers({
      id: 'order-tracking-fetch-order',
      call: () => {
        reloadOrder({ id: orderID });
      },
    });

    return () => {
      notificationContext.clear();
    };
  }, []);

  const order: PatronOrder = data?.getPatronOrder;
  const location = order?.location;
  const vendorOrders = order?.vendorOrders || [];
  const { toPDF, targetRef } = usePDF({filename: `order-${order?.orderNumber}.pdf`});

  const { loading: fetchingVenue, data: venueData, fetch: fetchVenueData } = useQueryHook({
    query: GET_VENUE,
    variables: {
      id: location?.venue,
    },
    skipInitialCall: true
  });
  const date = new DateObject({
    date: order?.createdAt,
    format: "DD MMMM YY",
  });

      const header = (
        <>
          <Box
            display="flex"
            position="relative"
            margin="auto"
            flexDirection="column"
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              height: '17vh'
            }}
          >
            <Box
              height='30%'
              maxWidth={'100%'}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Image
                src={logo}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              />
            </Box>
            <Box
              height='50%'
              width="100%"
              pt={1}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Text
                text={`Order #${order?.orderNumber}`}
                fontWeight={700}
                fontSize={18}
                style={{
                  lineHeight: 1.2,
                  paddingBottom: '2%'
                }}
              />
              <Text
                text={date.format()}
                fontWeight={500}
                fontSize={18}
                style={{
                  lineHeight: 1.2,
                  paddingBottom: '2%'
                }}
              />
              <Text
                text={"TAX INVOICE"}
                fontWeight={700}
                fontSize={18}
                style={{
                  lineHeight: 1.2,
                  paddingBottom: '2%'
                }}
              />
            </Box>
          </Box>
          <Box
              width="100%"
              pb={2}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                textAlign: 'center',
              }}>
              <Divider orientation="horizontal" sx={{ borderColor: '#2A2A2A' }} />
            </Box>
        </>
      )

      if (initialLoading || fetchingVenue) {
        return <Loader visible={true} />;
      }
    return (
      <>
        <Grid
          container
          xs={12}
          sm={8}
          md={6}
          mb={2}
          lg={3}
          margin={'auto'}
          //styling to mock mobile borders on desktop - TBD
          sx={{
            background: '#F5F5F5',
            padding: { lg: '8px 12px' },
          }}
          ref={targetRef}
        >
          {header}
          {patronOrderError && errorObject?.networkError && (
            <Dialog
              open={patronOrderError ? true : false}
              PaperComponent={props => (
                <Paper {...props} sx={{ borderRadius: '18px' }} />
              )}
              fullWidth={true}
            >
              <NetworkErrorPage statusIcon={<SneakerIcon />} />
            </Dialog>
          )}
          {vendorOrders?.map((vendorOrder: VendorOrder, index) => {
            const id = vendorOrder._id;
            const vendorOrderNo = vendorOrder.isOrderUpdated
              ? `#${vendorOrder?.orderNumber} (Partial)`
              : `#${vendorOrder?.orderNumber}`;
            console.log('id ', vendorOrder._id);
            return (
              <>
                <Grid item xs={12} key={id} paddingY={4}>
                  <PastOrderTrackingInfo
                    order={vendorOrder}
                    orderStatus={vendorOrder?.orderStatus}
                    serviceMethod={vendorOrder?.serviceMethod}
                    index={index}
                  />
                </Grid>
                <Grid item xs={12} paddingBottom={1}>
                  <Box width={'100%'}>
                    <Border borderStyle="dashed" color={'#2A2A2A'} />
                  </Box>
                </Grid>
              </>
            );
          })}

          <Grid item container p={2} mb={'186px'}>
            <Grid item xs={6}>
              <Text
                text={t('common.text.totalTaxInclusive', { ns: 'common' })}
                fontWeight={700}
                fontSize={16}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ float: 'right' }}>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  text={getPriceWithCurrency(
                    order?.pricing?.total,
                    vendorOrders[0]?.orderCurrency?.symbol,
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={8}
          md={6}
          lg={3}
          p={2}
          m={'auto'}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            background: '#FFFFFF',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            boxShadow: '0px 6px 14px 2px #F5F5F5',
          }}
        >
          <Grid
            item
            display="flex"
            flexDirection={'column'}
            gap="24px"
            width={'100%'}
          >
            <CustomButton
              title={t('common.back', { ns: 'common' })}
              style={{ height: '48px' }}
              onClick={() => {
                navigate(VIEW_ALL_ORDERS);
              }}
            />
          </Grid>

          <Grid item mt={2} width={'100%'}>
            <Button
              onClick={() => toPDF()}
              variant="outlined"
              fullWidth={true}
              sx={{
                marginY: '10px',
                width: '98%',
                height: '48px',
                fontWeight: '600',
                fontSize: '18px',
                WebkitBorderRadius: '30px',
                textTransform: 'none',
                p: '8px',
                color: 'black',
                outlineOffset: '-8px',
                borderRadius: '30px',
                backgroundColor: '#D2FF28',
                boxShadow: '0px 0px 0px 8px #D2FF28',
                borderColor: 'red',
                border: '1.5px solid black',
                '&:hover': {
                  background: '#D2FF28',
                  border: '1px solid black',
                },
                '&:focus': {
                  border: '1px solid black',
                },
              }}
            >
              {t('patron_portal.order.labelDownloadButton', {
                ns: 'patron_portal',
              })}
            </Button>
          </Grid>
        </Grid>
      </>
    );
}
