import { useLocation } from 'react-router-dom';
import { Store } from '../components/vendor-menu/types';
import {
  HOME_PAGE_ROUTE,
  LIST_VENDORS_PAGE_ROUTE,
  STORES_LIST_ROUTE,
  VIEW_CART_ROUTE,
  getListItemsRoute,
  getStoreMenuRoute,
} from '../constants/routes';
import { VenueTypes } from '../pages/home/home';

/**
 * [Gets route for page where patron can add items or select vendors based on venue-type]
 * @param  {[string]} venueType [venue type]
 * @param  {[string]} store [Store object]
 * @param  {[string]} selectedVendorId [selected vendor/only vendor in dine-in]
 */
export const getEntryPointRoute = (
  venueType: string,
  store?: Store,
  selectedVendorId?: string,
): string => {
  switch (venueType) {
    case VenueTypes.DINE_IN:
      if (!store) return HOME_PAGE_ROUTE;
      return getStoreMenuRoute(store.ownerID, store.id);
    case VenueTypes.HOTEL:
      if (!store) return HOME_PAGE_ROUTE;
      if (location.pathname === VIEW_CART_ROUTE) {
        return getStoreMenuRoute(store.ownerID, store.id);
      }
      return STORES_LIST_ROUTE;
    case VenueTypes.EVENT:
      if (location.pathname === VIEW_CART_ROUTE) {
        if (!selectedVendorId) return LIST_VENDORS_PAGE_ROUTE;
        return getListItemsRoute(selectedVendorId);
      }
      return HOME_PAGE_ROUTE;
    default:
      return LIST_VENDORS_PAGE_ROUTE;
  }
};
