import React from 'react';
import { Box, createTheme, Grid, ThemeProvider } from '@mui/material';
import { customTheme } from './theme';
import AppContextProvider from './context/app-context';
import CartContextProvider from './context/cart-context';
import { SnackbarProvider } from 'notistack';
import { Wizard } from './wizard';
import UserContextProvider from './context/user-context';
import OrderContextProvider from './context/order-context';
import NotificationContextProvider from './context/notification-context';

// TODO: Make it more customizable in upcoming PR
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    serviceMethodColors: {
      tts: {
        main: '#d2ff27',
      },
      cnc: {
        main: '#00c2ff',
      },
      cp: {
        main: '#ffffff',
      },
    },
    black: '#2a2a2a',
  },
});

function App() {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={1000}>
      <AppContextProvider>
        <CartContextProvider>
          <UserContextProvider>
            <NotificationContextProvider>
              <OrderContextProvider>
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      background: customTheme.background,
                      minHeight: '100vh',
                    }}
                  >
                    <Wizard />
                  </Box>
                </ThemeProvider>
              </OrderContextProvider>
            </NotificationContextProvider>
          </UserContextProvider>
        </CartContextProvider>
      </AppContextProvider>
    </SnackbarProvider>
  );
}

export default App;
