import { Box, Grid, Icon, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import '../styles/network-error-page.css';
import { ReactComponent as RSALimitIcon } from '../assets/icons/RSALimitSticker.svg';
import { Text } from '../elements/text';
import { CustomButton } from '../elements/button';
import { CartContext } from '../context/cart-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryType, PreOrderSummary } from '../types';
import { GET_PRE_ORDER_SUMMARY } from '../graphql/query';
import { useQuery } from '@apollo/client';
import { AppContext } from '../context/app-context';
import { VIEW_CART_ROUTE } from '../constants/routes';
import { useCreateOrder } from '../hooks/use-order.hook';
import { Header } from '../components/header';
import { useTranslation } from 'react-i18next';
import { Loader } from '../elements/loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { getEntryPointRoute } from '../utils/get-entry-point-route';

export const RSACompliance = () => {
  const [doAutoCheckout, setDoAutoCheckout] = useState<boolean>(false);
  // TODO: make the timer as a prop or config.
  const {
    isSectionClickAndCollectEnabled,
    stadium,
    serviceMethod,
    venueType,
    selectedVendor,
    store: storeInContext,
  } = useContext(AppContext);
  const { cart, removeMultipleFromCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);
  const isNarrowScreen = useMediaQuery('(max-width: 350px)');
  const { state } = useLocation();
  const { rsaNoticeMessage } = state as any;

  const cartItems = Object.keys(cart).map(key => {
    return {
      itemID: cart[key].id,
      count: cart[key].count,
      storeID: cart[key].storeID,
      customisations: cart[key].customisations,
      serviceMethod: serviceMethod?.value,
    };
  });

  const {
    data,
    loading: preOrderSummaryLoading,
    refetch: fetchPreOrderSummary,
  } = useQuery(GET_PRE_ORDER_SUMMARY, {
    variables: {
      preOrderSummaryInput: {
        items: cartItems,
        venueId: stadium?.value,
        serviceMethod: serviceMethod?.value,
      },
    },
  });

  const preOrderSummary = (data?.getPreOrderSummary as PreOrderSummary) || {};
  const { available = [], unavailable = [] } = preOrderSummary;
  const alcoholFilteredItems = [...available, ...unavailable].filter(
    item => item.category === CategoryType.ALCOHOL,
  );
  const nonAlcoholItems = [...available, ...unavailable].filter(
    item => item.category === CategoryType.OTHER,
  );

  const removeAlcoholAndShop = () => {
    removeMultipleFromCart(alcoholFilteredItems.map(item => item.key));
    navigate(
      getEntryPointRoute(
        venueType?.value || '',
        storeInContext?.value
      ),
    );
  };

  const removeAlcoholToCheckout = async () => {
    removeMultipleFromCart(alcoholFilteredItems.map(item => item.key));
    setDoAutoCheckout(true);
  };

  useEffect(() => {
    if (doAutoCheckout && alcoholFilteredItems.length === 0) {
      setDoAutoCheckout(false);
      continueToCheckoutHandler();
    }
  }, [cart]);

  const [createOrder, { loading: isCreateOrderLoading, data: a }] =
    useCreateOrder({
      onError: () => {
        fetchPreOrderSummary({
          preOrderSummaryInput: {
            items: cartItems,
            venueId: stadium?.value,
            serviceMethod: serviceMethod?.value,
          },
        });
      },
    });

  const continueToCheckoutHandler = async () => {
    await createOrder();
  };

  return (
    <Grid container height={'auto'} justifyContent={'center'}>
      <Loader visible={isCreateOrderLoading || preOrderSummaryLoading} />
      <Grid item xs={12}>
        <Header
          backIconRoute={VIEW_CART_ROUTE}
          middleComponent={
            <Text
              text={t('patron_portal.order.labelMyCart', {
                ns: 'patron_portal',
              })}
              fontSize={18}
              fontWeight={700}
            />
          }
          disableProfileIcon={true}
        />
      </Grid>
      <Grid item margin={'auto'} textAlign={'center'} mx={2}>
        <Box mt={'60px'}>
          <Text
            fontSize={isNarrowScreen ? 18 : 20}
            fontWeight={700}
            text={rsaNoticeMessage}
            fontColor={'#1D2306'}
          />
        </Box>
        <Box textAlign={'center'} mt={'50px'}>
          <Box m="auto">
            <Icon
              style={{
                animation: 'scale-up 0.5s ease',
                height: '100%',
                width: '100%',
              }}
            >
              {<RSALimitIcon />}
            </Icon>
          </Box>

          <Box mt={'35px'}>
            <Text
              fontSize={isNarrowScreen ? 18 : 20}
              fontWeight={700}
              text={t('patron_portal.notice.msgAgeProhibited', {
                ns: 'patron_portal',
              })}
              fontColor={'#1D2306'}
            />
          </Box>

          <Box margin={'auto'} marginTop={'35px'}>
            {nonAlcoholItems.length > 0 && (
              <CustomButton
                onClick={removeAlcoholToCheckout}
                title={t('patron_portal.cart.btnLabelRemoveItemCheckout', {
                  ns: 'patron_portal',
                })}
              />
            )}
          </Box>
        </Box>

        <Box margin={'auto'} marginTop={'24px'} marginBottom={'28px'}>
          <CustomButton
            onClick={removeAlcoholAndShop}
            title={t('patron_portal.notice.buttonRemoveAndKeepShopping', {
              ns: 'patron_portal',
            })}
            secondary={true}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
