import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE_ROUTE } from '../constants/routes';
import { AppContext } from '../context/app-context';
import { CartContext } from '../context/cart-context';
import { UserContext } from '../context/user-context';
import { Loader } from '../elements/loader';
import { VenueTypes } from './home/home';
import { OrderContext } from '../context/order-context';

export const Logout = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const cartContext = useContext(CartContext);
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);

  useEffect(() => {
    const venueCode = appContext?.venueCode?.value;
    const venueType = appContext?.venueType?.value;

    console.log('Clearing...');

    localStorage.clear();
    appContext.clear();
    cartContext.clear();
    userContext.clear();
    orderContext.clear();
    navigate(venueType === VenueTypes.DINE_IN ? `${HOME_PAGE_ROUTE}?venue=${venueCode}` : HOME_PAGE_ROUTE);

  }, []);

  return <Loader visible={true} />;
};
