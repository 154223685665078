import { Box, Button, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Header } from '../components/header';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { GET_ALL_PATRON_ORDERS } from '../graphql/query';
import { Loader } from '../elements/loader';
import { UserContext } from '../context/user-context';
import { PatronOrder, PatronOrderStatus } from '../types/order';
import { Border } from '../elements/border';
import { ReactComponent as ReceiptIcon } from '../assets/icons/Receipt.svg';
import {
  getPastOrderTrackingRoute,
  HOME_PAGE_ROUTE,
  PROFILE_ROUTE,
} from '../constants/routes';
import { BottomBox } from '../elements/bottom-box';
import { CustomButton } from '../elements/button';
import { useQueryHook } from '../hooks/use-query.hook';
import { CustomerSupportButton } from '../components/customer-support-button';
import { OrderContext } from '../context/order-context';
import { customTheme } from '../theme';
import { patronOrderStatusConfig } from '../utils/order-config';
import { EmptyCart } from '../components/empty-cart';
import { NoActiveOrders } from '../components/no-active-orders';

export const PastOrders = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const orderStatusList = [
    PatronOrderStatus.ASSIGNED,
    PatronOrderStatus.DELIVERED,
    PatronOrderStatus.PARTIALLY_ASSIGNED,
    PatronOrderStatus.PATRON_CANCELLED,
    PatronOrderStatus.PREPARED,
    PatronOrderStatus.PREPARING,
    PatronOrderStatus.REJECTED,
    PatronOrderStatus.RECEIVED,
    PatronOrderStatus.READY_TO_COLLECT,
    PatronOrderStatus.PARTIALLY_READY_TO_COLLECT,
    PatronOrderStatus.PAYMENT_REJECTED,
  ]


  const { data: activeOrders, loading: initialLoading, fetch: fetchAllOrders } = useQueryHook({
    query: GET_ALL_PATRON_ORDERS,
    variables: {
      payload: {
        filter: {
          orderStatus: orderStatusList,
          patronID: userContext.patron?._id,
          start: today,
        },
      },
    },
  });

  if (initialLoading) {
    return <Loader visible={initialLoading} />;
  }

  const orders: PatronOrder[] = activeOrders?.getAllPatronOrders?.data || [];

  orderContext.setHasActiveOrders(orders);

  const handleViewPastOrders = () => {
    fetchAllOrders({
      payload: {
        filter: {
          orderStatus: orderStatusList,
          patronID: userContext.patron?._id,
        },
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Loader visible={initialLoading} />
        <Header
          backIconRoute={PROFILE_ROUTE}
          middleComponent={
            <Text
              text={t('patron_portal.order.labelMyOrders', {
                ns: 'patron_portal',
              })}
              fontSize={18}
              fontWeight={700}
            />
          }
        />
      </Grid>
      {orders.length > 0 ?
        orders.map(order => {
          const orderID = order._id;
          const venueName = order?.location?.venueName;
          const orderStatus = patronOrderStatusConfig[order?.orderStatus].text
          const orderTitle = `${venueName} #${order?.orderNumber} • ${orderStatus}`;

          return (
            <Grid key={order._id} item container>
              <Grid
                item
                container
                xs={12}
                p={2}
                display={'flex'}
                alignItems={'center'}
              >
                <Grid
                  item
                  onClick={() => {
                    navigate(getPastOrderTrackingRoute(orderID));
                  }}
                  xs={8.5}
                >
                  <Box display="flex" alignItems={'center'}>
                    <ReceiptIcon />
                    <Box ml={1}>
                      <Text fontSize={16} fontWeight={700} text={orderTitle} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3.5}>
                  <Box sx={{ float: 'right' }}>
                    <Text
                      fontSize={16}
                      text={`${new Date(
                        order?.createdAt || '',
                      ).toLocaleDateString('en-AU', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                      })}`}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Border />
            </Grid>
          );
        })
        :
        <NoActiveOrders />
      }
      <Grid width={'100%'} display={'flex'} justifyContent={'center'} mb={'200px'}>
        <Button
          style={{ color: '#2A2A2A', fontFamily: customTheme.fontFamily, fontWeight: 800, fontSize: 18, letterSpacing: 0 }}
          onClick={handleViewPastOrders}
        >
          {t('common.label.btnViewPastOrder', { ns: 'common' })}
        </Button>
      </Grid>
      {orders.length > 0 &&
        <BottomBox>
          <Box
            sx={{
              textAlign: 'center',
              background: 'linear-gradient(to top, #FFFF, #F5F5F5)',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              boxShadow: '0px 8px 32px 6px #F5F5F5',
            }}
            px={2}
            py={2}
          >
            {/* <Box
            maxWidth="70%"
            margin="auto"
            mb={2}
            display={'flex'}
            justifyContent={'center'}
          >
            <Text
              fontWeight={500}
              fontSize={16}
              fontColor={'#D1C7C7'}
              text={t('patron_portal.order.labelClickForDetails', {
                ns: 'patron_portal',
              })}
            />
          </Box> */}

            <CustomButton
              style={{ mb: '24px' }}
              title={t('common.home', { ns: 'common' })}
              onClick={() => navigate(HOME_PAGE_ROUTE)}
            />
            <CustomerSupportButton
              title={t('common.label.customerSupport', { ns: 'common' })}
            />
          </Box>
        </BottomBox>
      }
    </Grid >
  );
};
