import { Box, Divider, Grid, IconButton, TextField } from '@mui/material';
import { Header } from '../components/header';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { useQueryHook } from '../hooks/use-query.hook';
import { GET_ALL_NOTIFICATIONS, GET_VENDOR_ORDER } from '../graphql/query';
import { useContext, useState } from 'react';
import { UserContext } from '../context/user-context';
import { MARK_AS_SEEN } from '../graphql/mutation';
import { useMutationHook } from '../hooks/use-mutation';
import { NotificationOut } from '../types/notification.types';
import { CustomButton } from '../elements/button';
import CloseIcon from '@mui/icons-material/Close';
import { BottomBox } from '../elements/bottom-box';
import { Loader } from '../elements/loader';
import { getOrderTrackingRoute } from '../constants/routes';

export const NotificationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const { patron } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const {
    data,
    fetch: fetchGetAllNotifications,
    loading: initialLoading,
  } = useQueryHook({
    query: GET_ALL_NOTIFICATIONS,
    variables: {
      payload: {
        filter: {
          subscriberID: patron?._id,
          seen: false,
        },
        limit: 10,
      },
    },
  });

  const { fetch: fetchVendorOrder } = useQueryHook({
    query: GET_VENDOR_ORDER,
    skipInitialCall: true,
  });

  const notifications = data?.getAllNotifications || [];

  const onCloseNotificationHandler = async (notification: NotificationOut) => {
    await markAsSeen({
      variables: {
        ids: [notification._id],
      },
    });

    await fetchGetAllNotifications({
      payload: {
        filter: {
          subscriberID: patron?._id,
          seen: false,
        },
        limit: 10,
      },
    });
  };

  const { mutate: markAsSeen } = useMutationHook({
    mutation: MARK_AS_SEEN,
  });

  const onNotificationHandler = async (notification: NotificationOut) => {
    try {
      setLoading(true);
      let body = JSON.parse(notification.body || `{}`);
      //TODO: A saftey check, if in case body in the db saved as string re parse.
      if (typeof body == 'string' || body instanceof String) {
        body = JSON.parse(body.toString());
      }
      if (notification.type == 'ORDER') {
        await markAsSeen({
          variables: {
            ids: [notification._id],
          },
        });
        await fetchGetAllNotifications({
          payload: {
            filter: {
              subscriberID: patron?._id,
              seen: false,
            },
            limit: 10,
          },
        });

        // TODO: Navigate based on body.status
        if (body.patronOrderID) {
          navigate(getOrderTrackingRoute(body.patronOrderID));
        } else {
          console.log('fetch Vendor');
          const result = await fetchVendorOrder({
            id: body.id,
          });
          console.log('fetch Vendor done');
          navigate(
            getOrderTrackingRoute(result?.data?.getVendorOrder?.patronOrderID),
          );
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const clearAllNotificaions = async () => {
    await markAsSeen({
      variables: {
        ids: notifications.map((n: NotificationOut) => n._id),
      },
    });

    await fetchGetAllNotifications({
      payload: {
        filter: {
          subscriberID: patron?._id,
          seen: false,
        },
        limit: 10,
      },
    });
  };

  if (initialLoading) {
    return <Loader visible={true} />;
  }

  return (
    <Grid container>
      <Loader visible={loading} />
      <Grid xs={12}>
        <Header
          middleComponent={
            <Text
              text={t('common.label.notifications', { ns: 'common' })}
              fontWeight={600}
              fontSize={18}
            />
          }
        />
      </Grid>
      {notifications.length ? (
        <Grid item container xs={12} pb={'100px'}>
          {notifications?.map(
            (notification: NotificationOut, index: number) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  sx={{
                    ':hover': {
                      background: '#ededed',
                    },
                  }}
                  key={notifications._id + '_' + index}
                >
                  <Grid item container>
                    <Grid
                      onClick={() => onNotificationHandler(notification)}
                      item
                      xs={10}
                      p={2}
                      sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
                    >
                      <Text
                        fontWeight={700}
                        fontSize={14}
                        text={notification?.message?.toString()}
                      />
                    </Grid>
                    <Grid
                      sx={{ cursor: 'pointer', alignItems: 'center' }}
                      xs={2}
                      p={1}
                    >
                      <Box display={'flex'}>
                        <IconButton
                          onClick={() =>
                            onCloseNotificationHandler(notification)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box width="100%">
                    <Divider />
                  </Box>
                </Grid>
              );
            },
          )}
        </Grid>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            height: '80vh',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Text
            text={t('common.label.msgNoNotifications', { ns: 'common' })}
            fontWeight={700}
            fontSize={16}
          />
        </Box>
      )}
      {notifications.length ? (
        <BottomBox>
          <Box
            width={'100%'}
            sx={{ background: 'white', boxSizing: 'border-box' }}
            p={2}
          >
            <CustomButton
              title={t('common.label.clearAll', { ns: 'common' })}
              onClick={clearAllNotificaions}
            />
          </Box>
        </BottomBox>
      ) : (
        <></>
      )}
    </Grid>
  );
};
