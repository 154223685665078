import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { ServiceMethod } from '../types/order';
import { Palette } from '@mui/icons-material';

interface Props {
  serviceMethod: string;
  serviceAvailable: boolean;
  venueName: string;
}

export const OrderTypeStatusBar = ({
  serviceMethod,
  serviceAvailable,
  venueName,
}: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const { palette } = useTheme();
  let bannerMessage = '';
  let bannerColor = '';

  const setBannerStyle = () => {
    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        bannerMessage = t('patron_portal.delivery.msgAvlblTypeTTS', {
          ns: 'patron_portal',
        });
        bannerColor = palette.serviceMethodColors.tts.main;
        break;

      case ServiceMethod.COLLECT_FROM_VENDOR:
        bannerMessage = t('patron_portal.delivery.msgAvlblTypeCNC', {
          ns: 'patron_portal',
        });
        bannerColor = palette.serviceMethodColors.cnc.main;
        break;

      case ServiceMethod.COLLECTION_POINT:
        bannerMessage = t('patron_portal.delivery.msgAvlblTypeCP', {
          ns: 'patron_portal',
        });
        bannerColor = palette.serviceMethodColors.cp.main;
        break;

      default:
        return;
    }
  };
  setBannerStyle();
  return (
    <Stack
      spacing={1}
      direction={'row'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        background: palette.black,
        paddingX: '6px',
        paddingY: '3px',
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
          fill={serviceAvailable ? bannerColor : palette.error.main}
        />
      </svg>
      {serviceAvailable && (
        <Text text={bannerMessage} fontSize={14} fontColor={'#fff'} />
      )}
      {!serviceAvailable && (
        <Text
          text={t('patron_portal.vendorListing.notOperatingMsg', {
            ns: 'patron_portal',
            venueName: venueName,
          })}
          fontSize={14}
          fontColor={'#fff'}
        />
      )}
    </Stack>
  );
};
