import awsConfig from './aws-exports';

type AppConfig = {
  [key: string]: {
    host: string;
    graphql: string;
    subscriptionURL: string;
    region: string;
    firebaseConfig: {
      apiKey: string;
      messagingSenderId: string;
      appId: string;
      projectId: string;
      vapidKey: string;
    };
    oauth: {
      domain: string,
      scope: string[],
      redirectSignIn: string,
      redirectSignOut: string,
      responseType: string,
    },
  };
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  ),
);
const signInURLs = awsConfig.oauth.redirectSignIn.split(',');
const signOutURLs = awsConfig.oauth.redirectSignOut.split(',');

export const configTemplate: AppConfig = {
  "bahrain-dev": {
    host: 'https://backend.ae.dev.${DOMAIN}',
    graphql: 'https://backend.ae.dev.${DOMAIN}/graphql',
    subscriptionURL: 'https://subscription-service.ae.dev.${DOMAIN}/graphql',
    region: 'me-south-1',
    firebaseConfig: {
      apiKey: 'AIzaSyA4AouVNcdChrcLs_f-9px3lSLr5FK_0AY',
      messagingSenderId: '160930205567',
      appId: '1:160930205567:web:563565c40d6204a974d44a',
      projectId: 'liively-dev',
      vapidKey:
        'BKs1ccATnNqfVrHagO0Izy5Fu1bIyc0AR5JyqIBUr-jaBIhMH0i7ComRd0e2fE-zEuA9axlh6kq1zGpQ8B97rx4',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'auth-ae.dev.${DOMAIN}',
      redirectSignIn: isLocalhost ? signInURLs[0] : signInURLs[1],
      redirectSignOut: isLocalhost ? signOutURLs[0] : signOutURLs[1],
    },
  },
  "bahrain-prod": {
    graphql: 'https://backend.ae.prod.${DOMAIN}/graphql',
    host: 'https://backend.ae.prod.${DOMAIN}',
    subscriptionURL: 'https://subscription-service.ae.prod.${DOMAIN}/graphql',
    region: 'me-south-1',
    firebaseConfig: {
      apiKey: 'AIzaSyDjsTbk_h_tJByGvjCCIu_tRSULKYD0kaE',
      vapidKey:
        'BCCpEfiLjgNAnBViYgQREL1jsbLxipz81Bns4qKaF1d56eDdQQWLJRvAK26tCFXjDM1x8hPlujBIk_hlbZNavcw',
      messagingSenderId: '21770235552',
      appId: '1:21770235552:web:15f0f4aeba92b75d3263c5',
      projectId: 'liively-prod',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'liively-prod-ae.auth.me-south-1.amazoncognito.com',
    },
  },
  "dev": {
    host: 'https://backend.dev.${DOMAIN}',
    graphql: 'https://backend.dev.${DOMAIN}/graphql',
    subscriptionURL: 'https://subscription-service.dev.${DOMAIN}/graphql',
    region: 'ap-southeast-2',
    firebaseConfig: {
      apiKey: 'AIzaSyA4AouVNcdChrcLs_f-9px3lSLr5FK_0AY',
      messagingSenderId: '160930205567',
      appId: '1:160930205567:web:563565c40d6204a974d44a',
      projectId: 'liively-dev',
      vapidKey:
        'BKs1ccATnNqfVrHagO0Izy5Fu1bIyc0AR5JyqIBUr-jaBIhMH0i7ComRd0e2fE-zEuA9axlh6kq1zGpQ8B97rx4',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'auth.dev.liively.app', // 'liively-dev.auth.ap-southeast-2.amazoncognito.com',
      redirectSignIn: isLocalhost ? signInURLs[0] : signInURLs[1],
      redirectSignOut: isLocalhost ? signOutURLs[0] : signOutURLs[1],
    },
  },
  "stage": {
    host: 'https://backend.stage.${DOMAIN}',
    graphql: 'https://backend.stage.${DOMAIN}/graphql',
    subscriptionURL: 'https://subscription-service.stage.${DOMAIN}/graphql',
    region: 'ap-southeast-2',
    firebaseConfig: {
      apiKey: 'AIzaSyDjsTbk_h_tJByGvjCCIu_tRSULKYD0kaE',
      vapidKey:
        'BCCpEfiLjgNAnBViYgQREL1jsbLxipz81Bns4qKaF1d56eDdQQWLJRvAK26tCFXjDM1x8hPlujBIk_hlbZNavcw',
      messagingSenderId: '21770235552',
      appId: '1:21770235552:web:15f0f4aeba92b75d3263c5',
      projectId: 'liively-prod',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'auth.stage.liive.ly',
    },
  },
  "prod": {
    graphql: 'https://backend.prod.${DOMAIN}/graphql',
    host: 'https://backend.prod.${DOMAIN}',
    subscriptionURL: 'https://subscription-service.prod.${DOMAIN}/graphql',
    region: 'ap-southeast-2',
    firebaseConfig: {
      apiKey: 'AIzaSyDjsTbk_h_tJByGvjCCIu_tRSULKYD0kaE',
      vapidKey:
        'BCCpEfiLjgNAnBViYgQREL1jsbLxipz81Bns4qKaF1d56eDdQQWLJRvAK26tCFXjDM1x8hPlujBIk_hlbZNavcw',
      messagingSenderId: '21770235552',
      appId: '1:21770235552:web:15f0f4aeba92b75d3263c5',
      projectId: 'liively-prod',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'auth.prod.liively.app',
    },
  },
  local: {
    host: 'http://localhost:4000',
    graphql: 'http://localhost:4000/graphql',
    subscriptionURL: 'https://subscription-service.dev.liive.ly/graphql',
    region: 'ap-southeast-2',
    firebaseConfig: {
      apiKey: 'AIzaSyA4AouVNcdChrcLs_f-9px3lSLr5FK_0AY',
      messagingSenderId: '160930205567',
      appId: '1:160930205567:web:563565c40d6204a974d44a',
      projectId: 'liively-dev',
      vapidKey:
        'BKs1ccATnNqfVrHagO0Izy5Fu1bIyc0AR5JyqIBUr-jaBIhMH0i7ComRd0e2fE-zEuA9axlh6kq1zGpQ8B97rx4',
    },
    oauth: {
      ...awsConfig.oauth,
      domain: 'auth.dev.liively.app',
      redirectSignIn: isLocalhost ? signInURLs[0] : signInURLs[1],
      redirectSignOut: isLocalhost ? signOutURLs[0] : signOutURLs[1],
    },
  },
};

export const appConfig = {
  get: function(env: string) {
    const config = configTemplate[env];
    const host = window.location.host;
    if (!host.startsWith('localhost')) {
      const domain = host.substring(host.search(/[\w\d]+\.[\w\d]+$/));
      
      config.host = config.host.replaceAll("${DOMAIN}", domain);
      config.graphql = config.graphql.replaceAll("${DOMAIN}", domain);
      config.subscriptionURL = config.subscriptionURL.replaceAll("${DOMAIN}", domain);
      config.oauth.domain = config.oauth.domain.replaceAll("${DOMAIN}", domain);

      const signInURL = signInURLs.find(url => url.indexOf(domain) > -1) || signInURLs[1];
      config.oauth.redirectSignIn = isLocalhost ? signInURLs[0] : signInURL;
      
      const signOutURL = signOutURLs.find(url => url.indexOf(domain) > -1) || signOutURLs[1];
      config.oauth.redirectSignOut = isLocalhost ? signOutURL[0] : signOutURL;
    }

    return config;
  }
}

export default appConfig.get(process.env.REACT_APP_BUILD_ENV || 'local');
