import { Alert, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { IOModelVenueWithStores } from '../../types/io-models';
import {
  IVenueSectionDeliveryInfoMeta,
  VenueSeatChoice,
} from '../../pages/home/home';
import { ServiceMethod } from '../../types/order';
import { StoreWithDetails } from '../vendor-menu/types';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '../../elements/input';
import { CustomButton } from '../../elements/button';
import { useSnackbar } from 'notistack';

export const HotelRoomGuestInfoForm: React.FC<{
  venue: IOModelVenueWithStores;
  patronVenueChoices: VenueSeatChoice | undefined;
  serviceAvailable: boolean;
  refetchVenue: () => void;
  onLocationSelection: (
    selectedServiceMethod: ServiceMethod,
    deliveryLocationInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
    guestInfo?: any,
  ) => void;
}> = ({
  venue,
  patronVenueChoices,
  serviceAvailable,
  onLocationSelection,
  refetchVenue,
}) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const seat = patronVenueChoices?.serviceChoices?.deliveryInfo?.seat;
  const [selectedRoomNo, setRoomNo] = useState<string>(
    seat && seat != 'N/A' ? seat : '',
  );
  const [isValidInfo, setInfoValidity] = useState(false);
  const [updatedVenue, setUpdatedVenue] =
    useState<IOModelVenueWithStores | null>(null);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const allStoresClosed = venue.sellingStores.every(store => !store.isOpen);
      if (!allStoresClosed) {
        console.log('At least one store is open, no need to refetch');
        clearInterval(intervalId);
      } else {
        console.log('All stores are closed, refetching venue data');
        const updatedVenue: any = await refetchVenue();
        setUpdatedVenue(updatedVenue.data.getVenue);
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [refetchVenue, venue.sellingStores]);

  const selectedServiceMethod = ServiceMethod.COLLECT_FROM_VENDOR; // Defaulting to CNC (only available is CNC now)

  const goToNext = () => {
    const currentVenue = updatedVenue || venue;
    const stores = currentVenue?.sellingStores;
    console.log('Selling stores:', stores);

    const openStore = stores?.find(store => store.isOpen && store.menu);
    if (openStore) {
      onLocationSelection(selectedServiceMethod, {
        collectionPoint: undefined,
        row: undefined,
        seat: selectedRoomNo,
        section: venue.sections[0].id,
      });
    } else {
      enqueueSnackbar('Store is currently closed or unavailable.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        // autoHideDuration: 20000,
      });
    }
  };

  useEffect(() => {
    if (selectedRoomNo && selectedRoomNo.trim() != '') {
      setInfoValidity(true);
    } else {
      setInfoValidity(false);
    }
  }),
    [selectedRoomNo];

  return (
    <Stack>
      {/* <CustomSelect
        onChange={(value: string) => {
          setSection(value);
        }}
        data={sections}
        title={t(
          'patron_portal.seatSelectionForm.preferredServiceMethodLabel',
          {
            ns: 'patron_portal',
          },
        )}
        value={selectedSectionID}
    /> */}

      <CustomInput
        onChange={(roomno: string) => {
          setRoomNo(roomno.trim());
        }}
        title={t('common.roomNo', { ns: 'common' })}
        value={selectedRoomNo}
      />

      <CustomButton
        disabled={!isValidInfo}
        title={t('patron_portal.storeSelectionForm.submitButtonLabel', {
          ns: 'patron_portal',
        })}
        onClick={goToNext}
        style={{
          marginTop: '16px',
          color: 'white',
          border: '1px solid white',
          boxShadow: `0px 0px 0px 6px ${isValidInfo ? '#a18146' : '#735c31'}`,
          background: `${isValidInfo ? '#a18146' : '#735c31'}`,
          p: '8px',
          '&:hover': {
            background: '#735c31',
            border: '1px solid white',
            boxShadow: '0px 0px 0px 6px #735c31',
          },
          '&:focus': {
            border: '1px solid white',
          },
        }}
      />
    </Stack>
  );
};
