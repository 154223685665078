import { Box, Link, Stack, useTheme } from '@mui/material';
import { Text } from '../elements/text';
import { useNavigate } from 'react-router-dom';
import { VIEW_ALL_ORDERS } from '../constants/routes';
import { t } from 'i18next';

const PastOrdersBanner = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  return (
    <Stack
      spacing={1}
      direction={'row'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        background: palette.black,
        paddingX: '6px',
        paddingY: '3px',
        borderBottom: 'dashed white 1px',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
          fill={palette.info.main}
        />
      </svg>
      <Text
        text={t('patron_portal.order.msgPastOrdersPresent', {
          ns: 'patron_portal',
        })}
        fontColor="white"
        fontWeight={600}
      />

      <Link
        href="#"
        fontSize={14}
        onClick={() => navigate(VIEW_ALL_ORDERS)}
        color={palette.info.light}
      >
        {t('patron_portal.order.labelViewOrders', {
          ns: 'patron_portal',
        })}
      </Link>
    </Stack>
  );
};

export default PastOrdersBanner;
