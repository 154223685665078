import { ReactElement } from 'react';
import { Badge, Box, Button, Grid } from '@mui/material';
import { customTheme } from '../theme';
import { Text } from './text';
import { fontWeight } from '@mui/system';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  title: string;
  badgeTitle?: string;
  rightTitle?: string;
  disabled?: boolean;
  secondary?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  icon?: any;
  loading?: boolean;
  loadingText?: string | ReactElement;
}

export function CustomButton({
  onClick,
  title,
  badgeTitle,
  rightTitle,
  disabled = false,
  secondary = false,
  style = {},
  loading,
  loadingText,
  icon,
}: Props) {
  return (
    <Button
      variant="outlined"
      type="submit"
      style={{}}
      sx={{
        fontFamily: customTheme.fontFamily,
        background: disabled || secondary ? '#5C5C5C' : '#2A2A2A',
        minWidth: '98%',
        height: '48px',
        WebkitBorderRadius: '30px',
        borderRadius: '30px',
        color: '#D2FF28',
        fontWeight: '700',
        fontSize: '18px',
        outlineOffset: '-8px',
        textDecoration: 'none',
        textTransform: 'none',
        border: '1.5px solid #D2FF28',
        boxShadow: disabled || secondary ? '0px 0px 0px 8px #5C5C5C' : '0px 0px 0px 8px #2A2A2A',
        p: '8px',
        '&:hover': {
          background: disabled || secondary ? '#5C5C5C' : '#2A2A2A',
          border: '1px solid #D2FF28',
        },
        '&:focus': {
          border: '1px solid #D2FF28',
        },

        ...style,
      }
      }
      onClick={(e) => !disabled && onClick(e)}
    >
      {(icon && !rightTitle) ? (
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            {loading ? loadingText : title}
          </Box>
        </Grid>
      ) :
        rightTitle ? (
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={3} style={{ display: "flex", alignItems: 'center', justifyContent: "flex-start" }}>
              <Box>
                <Badge sx={{
                  "& .MuiBadge-badge": {
                    color: "black",
                    backgroundColor: "white",
                    border: '#D2FF28',
                    fontWeight: 600
                  }
                }} badgeContent={badgeTitle}>
                  {icon}
                </Badge>
              </Box>
            </Grid>
            <Grid item xs={5} style={{ display: "flex", justifyContent: "center" }}>
              <Box>
                {loading ? loadingText : title}
              </Box>
            </Grid>
            <Grid item xs={3} style={{ display: "flex", alignItems: 'center', justifyContent: "flex-end" }}>
              <Box>
                <Text text={rightTitle} fontColor={'#D2FF28'} fontSize={14} fontWeight={600} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              {loading ? loadingText : title}
            </Grid>
          </Grid>
        )}
    </Button >
  );
}
