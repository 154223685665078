import { Alert, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { IOModelVenueWithStores } from '../../types/io-models';
import {
  IVenueSectionDeliveryInfoMeta,
  VenueSeatChoice,
} from '../../pages/home/home';
import { ServiceMethod } from '../../types/order';
import { StoreWithDetails } from '../vendor-menu/types';
import { CustomSelect } from '../../elements/select';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '../../elements/input';
import { CustomButton } from '../../elements/button';
import { Section } from '../../types';
import { useSnackbar } from 'notistack';

export const TableServiceInfoForm: React.FC<{
  venue: IOModelVenueWithStores;
  patronVenueChoices: VenueSeatChoice | undefined;
  refetchVenue: () => void;
  serviceAvailable: boolean;
  onLocationSelection: (
    selectedServiceMethod: ServiceMethod,
    deliveryLocationInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
  ) => void;
}> = ({
  venue,
  patronVenueChoices,
  refetchVenue,
  onLocationSelection,
  serviceAvailable,
}) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const { enqueueSnackbar } = useSnackbar();

  const [isValidInfo, setInfoValidity] = useState(false);
  const [updatedVenue, setUpdatedVenue] =
    useState<IOModelVenueWithStores | null>(null);

  const selectedServiceMethod = ServiceMethod.COLLECT_FROM_VENDOR; // Defaulting to CNC (only available is CNC now)
  const [selectedSectionID, setSection] = useState<string | undefined>(
    patronVenueChoices?.serviceChoices?.deliveryInfo?.sectionID ?? undefined,
  );
  const [tableNo, setTableNo] = useState<string>(
    patronVenueChoices?.serviceChoices?.deliveryInfo?.seat ?? '',
  );
  const venueStores: IOModelVenueWithStores['sellingStores'] =
    venue?.sellingStores;
  const sections: Section[] =
    venue?.sections?.filter((section: Section) => !section.isDeleted) || [];
  const showTableNoInput = () => {
    const selectedSection = sections.find(sec => sec.id === selectedSectionID);
    return selectedSection?.label.toUpperCase() === 'DINE IN' ? true : false;
  };

  useEffect(() => {
    if (
      (showTableNoInput() &&
        tableNo &&
        tableNo.trim() != '' &&
        selectedSectionID) ||
      (!showTableNoInput() && selectedSectionID)
    ) {
      setInfoValidity(true);
    } else {
      setInfoValidity(false);
    }
  }),
    [tableNo, selectedSectionID];

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const allStoresClosed = venueStores.every(store => !store.isOpen);
      if (allStoresClosed) {
        console.log('All stores are closed, refetching venue data');
        const updatedVenue: any = await refetchVenue();
        setUpdatedVenue(updatedVenue.data.getVenue);
      } else {
        console.log('At least one store is open, no need to refetch');
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [refetchVenue, venueStores]);

  const goToNext = async () => {
    const currentVenue = updatedVenue || venue;
    const stores = currentVenue?.sellingStores;
    console.log('Selling stores:', stores);

    const openStore = stores?.find(store => store.isOpen && store.menu);
    if (openStore) {
      const selectedSection = sections.find(
        sec => sec.id === selectedSectionID,
      );
      onLocationSelection(
        selectedServiceMethod,
        {
          collectionPoint: undefined,
          row: 'N/A',
          seat: showTableNoInput() ? tableNo : '0',
          section: selectedSection?.id,
        },
        openStore,
      );
    } else {
      enqueueSnackbar('Store is currently closed or unavailable.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        // autoHideDuration: 20000,
      });
    }
  };

  return (
    <Stack>
      <CustomSelect
        onChange={(value: string) => {
          setSection(value);
        }}
        data={sections}
        title={t(
          'patron_portal.seatSelectionForm.preferredServiceMethodLabel',
          {
            ns: 'patron_portal',
          },
        )}
        value={selectedSectionID}
      />

      {showTableNoInput() && (
        <CustomInput
          onChange={setTableNo}
          title={t('common.tableNo', { ns: 'common' })}
          value={tableNo}
        />
      )}

      <CustomButton
        disabled={!isValidInfo}
        title={t('patron_portal.storeSelectionForm.submitButtonLabel', {
          ns: 'patron_portal',
        })}
        onClick={goToNext}
        style={{
          marginTop: '16px',
          color: 'white',
          border: '1px solid white',
          boxShadow: `0px 0px 0px 6px ${isValidInfo ? '#a18146' : '#735c31'}`,
          background: `${isValidInfo ? '#a18146' : '#735c31'}`,
          p: '8px',
          '&:hover': {
            background: '#735c31',
            border: '1px solid white',
            boxShadow: '0px 0px 0px 6px #735c31',
          },
          '&:focus': {
            border: '1px solid white',
          },
        }}
      />
    </Stack>
  );
};
