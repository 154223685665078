import jwtDecode, { JwtPayload } from "jwt-decode";

export const isTokenAboutToExpire = (token: string) => {
  if(token !== null && token !== undefined){
    const currentDate = Date.now()/1000;
    const decodeToken: JwtPayload = jwtDecode(token);
    if(decodeToken?.exp){
        const toRefreshTime = decodeToken?.exp + 60
        return currentDate > toRefreshTime ? true : false;
    }
  }
  return true;
}
