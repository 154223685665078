export enum NotificationType {
  ORDER = 'ORDER',
  CUSTOM = 'CUSTOM',
}

export interface NotificationOut {
  _id: string;
  message?: string;
  subscriberID: string;
  type: NotificationType;
  seen: boolean;
  body?: string;
  createdAt?: string;
  updatedAt?: string;
}
