import { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { Header } from '../components/header';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '../elements/text';
import { BottomBox } from '../elements/bottom-box';
import { CustomButton } from '../elements/button';
import {
  HOME_PAGE_ROUTE,
  LOG_OUT_ROUTE,
  VIEW_ALL_ORDERS,
  VIEW_CART_ROUTE,
} from '../constants/routes';
import { ReactComponent as ProfileIcon } from '../assets/icons/Profile.svg';
import { ReactComponent as ReceiptIcon } from '../assets/icons/Receipt.svg';
import { UserContext } from '../context/user-context';
import { NavigateBox } from '../elements/navigate-box';

export const ProfileRoute = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const userContext = useContext(UserContext);

  const patronName = userContext.patron?.name;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header
          backIconRoute={VIEW_CART_ROUTE}
          middleComponent={
            <Text
              text={t('common.label.profile', { ns: 'common' })}
              fontSize={18}
              fontWeight={700}
            />
          }
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <Box
          sx={{
            margin: 'auto',
            width: 100,
            height: 100,
          }}
        >
          <ProfileIcon />
        </Box>
        <Box
          sx={{
            m: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text
            text={t('patron_portal.msgProfileWelcome', {
              ns: 'patron_portal',
              patronName: patronName || 'User',
            })}
            fontSize={18}
            fontWeight={600}
          />
          <Text
            text={t('patron_portal.msgProfileWelcomeBack', {
              ns: 'patron_portal',
            })}
            fontSize={16}
            fontWeight={500}
          />
        </Box>
      </Grid>
      <Grid item xs={12} p={2}>
        <NavigateBox onClick={() => navigate(VIEW_ALL_ORDERS)}>
          <ReceiptIcon height={30} />
          <Box mt={0.5}>
            <Text
              fontColor={'#D2FF28'}
              fontSize={16}
              fontWeight={700}
              text={t('patron_portal.labelViewMyOrders', {
                ns: 'patron_portal',
              })}
            />
          </Box>
          <Box mt={0.5}>
            <ArrowForwardIcon onClick={() => navigate(VIEW_ALL_ORDERS)} />
          </Box>
        </NavigateBox>
      </Grid>

      <BottomBox>
        <>
          <Box p={2}>
            <CustomButton
              onClick={() => navigate(HOME_PAGE_ROUTE)}
              title={t('patron_portal.labelGoHome', { ns: 'patron_portal' })}
            />
          </Box>
          <Box p={2}>
            <CustomButton
              onClick={() => navigate(LOG_OUT_ROUTE)}
              title={t('common.labelSignout', { ns: 'common' })}
            />
          </Box>
        </>
      </BottomBox>
    </Grid>
  );
};
