
/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import {
  ApolloError,
  DocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { UserContext } from '../context/user-context';
import { extractAllErrorMesasges } from '../utils/graphql';
import { isTokenAboutToExpire } from '../utils/refresh-token';

interface Props {
  query: DocumentNode;
  variables?: object;
  onCompleted?: (data: any) => void;
  onError?: (err: ApolloError) => void
  skip?: boolean;
  skipInitialCall? : boolean;
  withoutToken?: boolean;
  pollInterval?: number;
}

interface UseQueryHookResponse {
  data: any;
  loading: boolean;
  fetch: any;
  error?: string;
  errorObject?: any;
  startPolling: (interval:number)=>void;
  stopPolling: ()=>void;
  called?:boolean
}

export const useQueryHook = ({
  query,
  variables,
  onCompleted = () => {},
  onError = () => {},
  skipInitialCall =  false,
  withoutToken = false,
  skip = false,
  pollInterval = 0,
}: Props): UseQueryHookResponse => {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [fetch, { data, loading, error, startPolling, stopPolling, called }] = useLazyQuery(query,
     {
      pollInterval: pollInterval,
    onError: e => {
      enqueueSnackbar(extractAllErrorMesasges(e), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    onCompleted: data => {
      onCompleted(data);
    },
  });

  useEffect(() => {
    if(!skipInitialCall) {
      makeQuery(variables)
    }
  }, []);

  const makeQuery = async (variables: any) => {
    if (skip) {
      return null;
    }

    if(withoutToken){
      return fetch({
        nextFetchPolicy: 'network-only',
        fetchPolicy: "network-only",
        variables,
      })
    }
    if (isTokenAboutToExpire(userContext.token)) {
      const user = await Auth.currentAuthenticatedUser({bypassCache: true});
      userContext.setUser(user);
      const newToken = user?.signInUserSession?.accessToken?.jwtToken;
      return fetch({
        nextFetchPolicy: 'network-only',
        fetchPolicy: "network-only",
        variables,
        context: {
          headers: {
            authorization: newToken,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      })
    }
    return fetch({
      nextFetchPolicy: 'network-only',
      fetchPolicy: 'network-only',
      variables,
      context: {
        headers: {
          authorization: userContext.token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    });
  }

  return {
    data,
    loading,
    fetch: async (variables: any) => {
      return makeQuery(variables);
    },
    error: extractAllErrorMesasges(error).join(' ,'),
    errorObject: error,
    startPolling,
    stopPolling,
    called
  };

};
