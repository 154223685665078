import React from 'react';
import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { HOME_PAGE_ROUTE } from '../constants/routes';
import { AppContext } from '../context/app-context';

export const SeatInfoProtecedRoute = () => {
  const { section, collectionPoint, seat, row, stadium, serviceMethod } = useContext(AppContext);
  if (section?.value && seat?.value && row?.value && stadium?.value && serviceMethod?.value) {
    return <Outlet />;
  }
  return <Navigate to={HOME_PAGE_ROUTE} />;
};
