/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Divider, Grid } from '@mui/material';
import map from 'lodash/map';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartLineItem } from '../components/cart-line-item';
import { EmptyCart } from '../components/empty-cart';
import { Header } from '../components/header';
import {
  CAPTURE_PATRON_DETAILS,
  LOGIN_ROUTE,
  RSA_LIMIT,
  VERIFY_AGE,
} from '../constants/routes';
import { AppContext } from '../context/app-context';
import { CartContext, CartItem } from '../context/cart-context';
import { UserContext } from '../context/user-context';
import { BottomBox } from '../elements/bottom-box';
import { CustomButton } from '../elements/button';
import { Loader } from '../elements/loader';
import { Text } from '../elements/text';
import { GET_PRE_ORDER_SUMMARY, GET_VENUE } from '../graphql/query';
import { useCreateOrder } from '../hooks/use-order.hook';
import {
  Notice,
  NoticeName,
  OrderNoticesResponse,
  PreOrderSummary,
  Pricing,
  SummaryItem,
  Venue,
} from '../types';
import {
  getMinimumOrderPriceWithCurrency,
  getPriceWithCurrency,
} from '../utils/charges';
import config from '../config';
import { ServiceMethod } from '../types/order';
import { getEntryPointRoute } from '../utils/get-entry-point-route';
import { NotesModal } from '../components/notes-modal';
import { OrderContext } from '../context/order-context';

export const Cart = () => {
  const {
    cart,
    addToCart,
    removeFromCart,
    removeMultipleFromCart,
    replaceCart,
    cartLoading,
  } = useContext(CartContext);
  const userContext = useContext(UserContext);
  const {
    isSectionClickAndCollectEnabled,
    stadium,
    serviceMethod,
    venueType,
    selectedVendor,
    store: storeInContext,
  } = useContext(AppContext);
  const { notes } = useContext(OrderContext);
  const [doAutoCheckout, setDoAutoCheckout] = useState<boolean>(false);
  const [noticesLoading, setNoticesLoading] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const { t } = useTranslation(['patron_portal', 'common']);

  const queryItems = Object.keys(cart).map(key => {
    return {
      itemID: cart[key].id,
      count: cart[key].count,
      storeID: cart[key].storeID,
      customisations: cart[key].customisations,
      serviceMethod: serviceMethod?.value,
    };
  });
  // console.log(`queryItems: ${JSON.stringify(queryItems)}`)

  const itemsForNotice = Object.keys(cart).map(key => {
    return {
      itemID: cart[key].id,
      count: cart[key].count,
      customisations: cart[key].customisations,
    };
  });

  const { loading: fetchingVenue, data: venueData } = useQuery(GET_VENUE, {
    variables: {
      id: stadium?.value,
    },
  });

  const venue: Venue = venueData?.getVenue;

  const {
    data,
    loading: preOrderSummaryLoading,
    refetch: fetchPreOrderSummary,
  } = useQuery(GET_PRE_ORDER_SUMMARY, {
    variables: {
      preOrderSummaryInput: {
        items: queryItems,
        venueId: stadium?.value,
        serviceMethod: serviceMethod?.value,
      },
    },
    onCompleted: (data: { getPreOrderSummary: PreOrderSummary }) => {
      const { available = [], unavailable = [] } =
        data.getPreOrderSummary || {};
      const cartItems: any[] = [];
      available.forEach((summaryItem: SummaryItem) =>
        cartItems.push({
          [summaryItem.key]: {
            id: summaryItem._id,
            count: summaryItem.count,
            storeID: summaryItem.storeID,
            customisations: map(summaryItem.customisations, '_id'),
          },
        }),
      );
      unavailable.forEach((summaryItem: SummaryItem) =>
        cartItems.push({
          [summaryItem.key]: {
            id: summaryItem._id,
            count: summaryItem.count,
            storeID: summaryItem.storeID,
            customisations: map(summaryItem.customisations, '_id'),
          },
        }),
      );
      replaceCart(Object.assign({}, ...cartItems));
    },
  });

  async function getOrderNotices(): Promise<Notice[]> {
    setNoticesLoading(true);
    const payload = {
      patronId: userContext.patron?._id,
      items: itemsForNotice,
      venueId: stadium?.value,
      isClickAndCollect: isSectionClickAndCollectEnabled?.value,
      serviceMethod: serviceMethod?.value,
    };

    try {
      const response = await fetch(`${config.host}/order/notices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: userContext?.token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        // TODO: add snackbar
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data: OrderNoticesResponse = await response.json();
      const noticesArray = data?.notices || [];
      setNoticesLoading(false);

      return noticesArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const getRSANoticeMessage = (orderNotice?: Notice) => {
    switch (orderNotice?.name) {
      case NoticeName.ALCOHOL_PER_ORDER_LIMIT:
        return t('patron_portal.notice.msgRSAPerOrderLimit', {
          ns: 'patron_portal',
          allowedQuantity: orderNotice?.allowedQuantity,
        });
      case NoticeName.ALCOHOL_ORDER_TIME_LIMIT:
        return t('patron_portal.notice.msgRSATimeIntervalLimit', {
          ns: 'patron_portal',
          allowedTimeInterval: orderNotice?.allowedInterval,
        });
      case NoticeName.ALCOHOL_24_HOUR_LIMIT:
        return t('patron_portal.notice.msgRSA24HourLimit', {
          ns: 'patron_portal',
          allowedQuantity: orderNotice?.allowedQuantity,
        });
      default:
        return t('patron_portal.notice.msgRSASetsLimit', {
          ns: 'patron_portal',
        });
    }
  };

  const handleCheckoutNotice = (orderNotices: Notice[]) => {
    const ageConfirmationNotice = orderNotices.find(
      (notice: Notice) => notice.name === NoticeName.AGE_CONFIRMATION,
    );
    const rsaLimitNotice = orderNotices.find(
      (notice: Notice) =>
        notice.name === NoticeName.ALCOHOL_ORDER_TIME_LIMIT ||
        notice.name === NoticeName.ALCOHOL_PER_ORDER_LIMIT ||
        notice.name === NoticeName.ALCOHOL_24_HOUR_LIMIT,
    );

    // TODO: replace with method \
    const checkParam =
      rsaLimitNotice?.allowedQuantity || rsaLimitNotice?.allowedInterval;

    const rsaNoticeMessage = getRSANoticeMessage(rsaLimitNotice);

    if (rsaLimitNotice) {
      navigate(RSA_LIMIT, {
        state: {
          rsaNoticeMessage: rsaNoticeMessage,
        },
      });
      return;
    }
    if (ageConfirmationNotice) {
      navigate(VERIFY_AGE);
      return;
    }
  };

  const [createOrder, { loading: isCreateOrderLoading, data: a }] =
    useCreateOrder({
      onError: () => {
        fetchPreOrderSummary({
          preOrderSummaryInput: {
            items: queryItems,
            venueId: stadium?.value,
            serviceMethod: serviceMethod?.value,
          },
        });
      },
    });

  const navigate = useNavigate();

  const isCartEmpty = Object.keys(cart).length === 0;

  // can be used again
  // const pricingBoxHeight = 200;

  const preOrderSummary = (data?.getPreOrderSummary as PreOrderSummary) || {};
  const { pricing = {}, available = [], unavailable = [] } = preOrderSummary;
  const { subTotal, total, deliveryCharge, serviceCharge } = pricing as Pricing;

  const getItemFromPreOrderSummary = (id: string) => {
    // TODO: For now we are not considering out of stock, this has be to handled when we consider outofstock.
    return [...available, ...unavailable].find(item => item?.key === id);
  };

  const isMinimumOrderAvailable = () => {
    const minOrderPrice = venue?.minimumOrderPrice;
    if (subTotal === 0) return false;
    return subTotal >= minOrderPrice ? false : true;
  };

  // const isVerifyAgeRequired = true
  const handleNotes = (e: any) => {
    setShowNotesModal(true);
  };

  const continueToCheckoutHandler = async () => {
    const isPatronIDAvailable = userContext.patron?._id;
    const isPatronDetailsCaptured = userContext.patron?.name;

    if (!userContext.isLoggedIn) {
      navigate(LOGIN_ROUTE);
      return;
    }
    if (!isPatronDetailsCaptured) {
      navigate(CAPTURE_PATRON_DETAILS);
      return;
    }

    const orderNotices = await getOrderNotices();
    if (
      isPatronIDAvailable &&
      isPatronDetailsCaptured &&
      orderNotices.length > 0
    ) {
      handleCheckoutNotice(orderNotices);
      return;
    }

    if (isPatronIDAvailable && isPatronDetailsCaptured) {
      await createOrder();
    }
  };
  const removeUnavailableItemsAndCheckout = () => {
    removeMultipleFromCart(unavailable.map(item => item.key));
    setDoAutoCheckout(true);
  };

  const removeUnavailableItemsAndShop = async () => {
    await removeMultipleFromCart(unavailable.map(item => item.key));
    // TODO: add snackbar on removing items
    navigate(getEntryPointRoute(venueType?.value || '', storeInContext?.value));
    window.location.reload();
  };

  async function handleIncrement(
    cartItemID: string,
    cartItem: CartItem,
    count: number,
  ) {
    addToCart(cartItemID, cartItem, count);
  }

  function handleDecrement(cartItemID: string, count: number) {
    removeFromCart(cartItemID, count);
  }

  function getCheckoutButton() {
    if (!userContext.isLoggedIn) {
      if (available.length > 0) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CustomButton
              disabled={preOrderSummaryLoading}
              title={t('patron_portal.cart.btnLabelLoginToCheckout', {
                ns: 'patron_portal',
              })}
              onClick={continueToCheckoutHandler}
            />
            {isMinimumOrderAvailable() && (
              <CustomButton
                disabled={preOrderSummaryLoading}
                secondary={true}
                title={t('patron_portal.cart.btnLabelKeepShopping', {
                  ns: 'patron_portal',
                })}
                onClick={() => {
                  navigate(
                    getEntryPointRoute(
                      venueType?.value || '',
                      storeInContext?.value,
                    ),
                  );
                }}
                style={{
                  marginTop: '26px',
                  border: '1.5px solid #FFFFFF',
                  background: '#2A2A2A',
                  boxShadow: '0px 0px 0px 8px #2A2A2A',
                  color: '#FFFFFF',
                }}
              />
            )}
          </Box>
        );
      } else {
        return (
          <CustomButton
            disabled={preOrderSummaryLoading}
            title={t('patron_portal.cart.btnLabelRemoveItemContinue', {
              ns: 'patron_portal',
            })}
            onClick={removeUnavailableItemsAndShop}
          />
        );
      }
    }
    if (unavailable.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CustomButton
            disabled={preOrderSummaryLoading}
            title={t('patron_portal.cart.btnLabelCheckout', {
              ns: 'patron_portal',
            })}
            onClick={continueToCheckoutHandler}
          />
          {isMinimumOrderAvailable() && (
            <CustomButton
              disabled={preOrderSummaryLoading}
              title={t('patron_portal.cart.btnLabelKeepShopping', {
                ns: 'patron_portal',
              })}
              onClick={() => {
                navigate(
                  getEntryPointRoute(
                    venueType?.value || '',
                    storeInContext?.value,
                  ),
                );
              }}
              style={{
                marginTop: '26px',
                border: '1.5px solid #FFFFFF',
                background: '#2A2A2A',
                boxShadow: '0px 0px 0px 8px #2A2A2A',
                color: '#FFFFFF',
              }}
            />
          )}
        </Box>
      );
    } else {
      if (available.length === 0) {
        return (
          <CustomButton
            disabled={preOrderSummaryLoading}
            title={t('patron_portal.cart.btnLabelRemoveItemContinue', {
              ns: 'patron_portal',
            })}
            onClick={removeUnavailableItemsAndShop}
          />
        );
      } else {
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CustomButton
              disabled={preOrderSummaryLoading}
              title={t('patron_portal.cart.btnLabelRemoveItemCheckout', {
                ns: 'patron_portal',
              })}
              onClick={removeUnavailableItemsAndCheckout}
            />
            <CustomButton
              disabled={preOrderSummaryLoading}
              secondary={true}
              title={t('patron_portal.cart.btnLabelRemoveItemContinue', {
                ns: 'patron_portal',
              })}
              onClick={removeUnavailableItemsAndShop}
              style={{ marginTop: '1em' }}
            />
          </Box>
        );
      }
    }
  }

  useEffect(() => {
    if (doAutoCheckout && unavailable.length === 0) {
      setDoAutoCheckout(false);
      continueToCheckoutHandler();
    }
  }, [cart]);

  if (preOrderSummaryLoading || fetchingVenue) {
    return <Loader visible={true} />;
  }

  const chargableServiceMethods: string[] = [
    ServiceMethod.COLLECTION_POINT,
    ServiceMethod.DELIVERY,
  ];
  const hasDeliveryCharge = chargableServiceMethods.includes(
    serviceMethod?.value || '',
  );

  return (
    <>
      <Grid container maxHeight={'80vh'} overflow={'auto'}>
        <Loader visible={isCreateOrderLoading || noticesLoading} />
        <Grid item xs={12}>
          <Header
            backIconRoute={getEntryPointRoute(
              venueType?.value || '',
              storeInContext?.value,
              selectedVendor?.value?._id,
            )}
            middleComponent={
              <Text
                text={t('patron_portal.order.labelMyOrder', {
                  ns: 'patron_portal',
                })}
                fontSize={18}
                fontWeight={700}
              />
            }
          />
        </Grid>

        {isCartEmpty ? (
          <Box margin="auto" p={4}>
            <EmptyCart />
          </Box>
        ) : (
          <>
            {unavailable.length > 0 && (
              <Grid item xs={12} md={8} margin="auto" paddingLeft={1}>
                <Box p={1}>
                  <Text
                    text={t('patron_portal.cart.msgItemsUnavailable', {
                      ns: 'patron_portal',
                    })}
                    fontSize={13}
                    fontWeight={400}
                    fontColor="red"
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} p={1}>
              <Box mb={1} mt={1} paddingLeft={1}>
                <Text
                  text={t('patron_portal.order.labelOrderDetails', {
                    ns: 'patron_portal',
                  })}
                  fontSize={18}
                  fontWeight={600}
                  fontColor={'#1D2306'}
                />
              </Box>
              <Divider sx={{ borderColor: 'black' }} />
            </Grid>

            <Grid item xs={12} md={8} marginX="auto" marginBottom={25}>
              {Object.keys(cart)?.map(key => {
                const item = getItemFromPreOrderSummary(key) as SummaryItem;
                const { pricing = {} } = item || {};
                const { singleQtyPrice } = pricing as Pricing;

                const alcoholSDE =
                  item?.additionalAttributes?.stdDrinkEquivalence || 0;

                return (
                  <Box pl={2} pr={2} key={key}>
                    <CartLineItem
                      name={item?.name}
                      count={item?.count}
                      price={singleQtyPrice}
                      outOfStock={item?.outOfStock}
                      storeIsClosed={item?.storeIsClosed}
                      vendorIsClosed={item?.vendorIsClosed}
                      showInMenu={item?.showInMenu}
                      customisations={item?.customisations}
                      category={item?.category}
                      alcoholSDE={alcoholSDE}
                      onIncrement={() => handleIncrement(key, cart[key], 1)}
                      onDecrement={() => handleDecrement(key, 1)}
                    />
                    <Divider
                      sx={{
                        borderColor: 'black',
                        paddingTop: 2,
                      }}
                    />
                  </Box>
                );
              })}
            </Grid>
          </>
        )}
      </Grid>
      {!isCartEmpty && (
        <BottomBox>
          <>
            <Box mb={1} mr={1} textAlign={'end'}>
              <button
                onClick={handleNotes}
                style={{
                  backgroundColor: '#FFFFFF',
                  height: '40px',
                  width: '100px',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  borderRadius: '10px',
                }}
              >
                {!notes ? 'Add Note' : 'Edit Note'}
              </button>
            </Box>

            <NotesModal
              open={showNotesModal}
              setShowNotesModal={setShowNotesModal}
            />
            {isMinimumOrderAvailable() && available && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#FF50DD',
                  paddingY: '3px',
                }}
              >
                <Text
                  text={t('patron_portal.cart.msgAddItemsToSave', {
                    ns: 'patron_portal',
                    amount: getMinimumOrderPriceWithCurrency(
                      venue?.minimumOrderPrice,
                      subTotal,
                    ),
                  })}
                  fontColor={'#FFFFFF'}
                />
              </Box>
            )}
            <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
            <Box
              height={'auto'}
              p={2}
              sx={{ background: '#FFFFFF', boxSizing: 'border-box' }}
              width="100%"
            >
              {cartLoading ? (
                <Box
                  width="100%"
                  height={95}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress sx={{ width: '100%' }} size={20} />
                </Box>
              ) : (
                <Box>
                  <Box display="flex" justifyContent={'space-between'} mb={1}>
                    <Text
                      text={t('patron_portal.order.labelSubTotal', {
                        ns: 'patron_portal',
                      })}
                      fontColor={'#667085'}
                    />
                    <Text
                      text={getPriceWithCurrency(subTotal || 0)}
                      fontColor={'#667085'}
                    />
                  </Box>
                  <Box display="flex" justifyContent={'space-between'} mb={1}>
                    <Text
                      text={t('patron_portal.order.labelServiceCharge', {
                        ns: 'patron_portal',
                      })}
                      fontColor={'#667085'}
                    />
                    <Text
                      text={getPriceWithCurrency(serviceCharge || 0)}
                      fontColor={'#667085'}
                    />
                  </Box>
                  {hasDeliveryCharge && (
                    <Box display="flex" justifyContent={'space-between'} mb={1}>
                      <Text
                        text={t('patron_portal.order.labelDeliveryCharge', {
                          ns: 'patron_portal',
                        })}
                        fontColor={'#667085'}
                      />
                      <Text
                        text={getPriceWithCurrency(deliveryCharge || 0)}
                        fontColor={'#667085'}
                      />
                    </Box>
                  )}
                  <Box display="flex" justifyContent={'space-between'} mb={2}>
                    <Text
                      text={t('patron_portal.order.labelTotal', {
                        ns: 'patron_portal',
                      })}
                      fontWeight={700}
                    />
                    <Text
                      text={getPriceWithCurrency(total || 0)}
                      fontWeight={700}
                    />
                  </Box>
                </Box>
              )}

              {getCheckoutButton()}
              {/* <CustomButton
                disabled={preOrderSummaryLoading}
                title={
                  userContext.isLoggedIn ? 'Checkout' : 'Login to checkout'
                }
                onClick={continueToCheckoutHandler}
              /> */}
            </Box>
          </>
        </BottomBox>
      )}
    </>
  );
};
