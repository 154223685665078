// Import the functions you need from the SDKs you need
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import config from '../config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
initializeApp(config.firebaseConfig);
const messaging = getMessaging();

export const fetchToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: config.firebaseConfig.vapidKey,
    });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      return currentToken;
    } else {
      return null;
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
