import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import includes from 'lodash/includes';
import pull from 'lodash/pull';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { customTheme } from '../../theme';
import { getPriceWithCurrency } from '../../utils/charges';
import { CheckboxOptionProps } from './types';

export function CheckboxOptions(props: CheckboxOptionProps) {
  const controller = useController({ name: props.name });
  const { field } = controller;
  const { options, itemCount } = props;
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);

  const handleCheck = (checkedId: string) => {
    const ids = includes(checkedOptions, checkedId)
      ? pull(checkedOptions, checkedId)
      : [...checkedOptions, checkedId];
    setCheckedOptions(ids);
    return ids;
  };

  return (
    <FormGroup>
      {options.map(option => (
        <Stack direction={'row'} key={option._id} my={1} ml={2} mr={1}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => field.onChange(handleCheck(option._id))}
                sx={{
                  padding: 0,
                  mr: 1.5,
                  alignSelf: 'start'
                }}
              />
            }
            label={option.name}
            value={option._id}
            sx={{
              fontFamily: customTheme.fontFamily,
              flexGrow: 1
            }}
          />
          {option.price !== 0 && (
            <Typography fontFamily={customTheme.fontFamily} variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              {getPriceWithCurrency(option.markupPrice)}
            </Typography>
          )}
          {checkedOptions.includes(option._id) && itemCount > 1 && (
            <Typography fontFamily={customTheme.fontFamily} variant="caption" color="grey" sx={{ mt: '2px' }}>
              (×{itemCount})
            </Typography>
          )}
        </Stack>
      ))
      }
    </FormGroup >
  );
}
