import { useQueryParams } from '../../hooks/use-query-params.hook';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/app-context';
import { useSnackbar } from 'notistack';
import {
  StoreWithDetails,
  integrationStatus,
  integrationTypes,
  Vendor,
} from '../../components/vendor-menu/types';
import { VenueListPage } from './landing-page-with-venue-list';
import { IOModelVenueWithStores } from '../../types/io-models';
import { OrderContext } from '../../context/order-context';
import PastOrdersBanner from '../../components/past-orders-banner';
import { ServiceMethod } from '../../types/order';
import { CartContext } from '../../context/cart-context';
import {
  HOME_PAGE_ROUTE,
  LIST_VENDORS_PAGE_ROUTE,
  STORES_LIST_ROUTE,
} from '../../constants/routes';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UseConfirmationDailog from '../../hooks/use-confirmation-dialog';
import { VenueHomePage } from './venue_home';
import { Loader } from '../../elements/loader';
import { useQuery } from '@apollo/client';
import {
  GET_VENDORS,
  GET_VENUE_BY_CODE,
  GET_VENUE_WITH_STORES,
} from '../../graphql/query';
import { extractAllErrorMesasges } from '../../utils/graphql';

export interface VenueSeatChoice {
  venueID: string | undefined;
  // venueType: VenueTypes | undefined;
  // allowedServiceMethods: string[]; // todo: enum
  serviceChoices: {
    serviceMethod: ServiceMethod | undefined;
    deliveryInfo: {
      sectionID?: string;
      // todo: define types
      row?: string;
      seat?: string;
      collectionPointID?: string;
    };
  };
}

// todo: make this a generic type
export type IVenueSectionDeliveryInfoMeta = {
  section?: string;
  // todo: define types
  row?: string;
  seat?: string;
  collectionPoint?: string; // CollectionPoint;
};

export enum VenueTypes {
  EVENT = 'EVENT',
  DINE_IN = 'DINE_IN',
  HOTEL = 'HOTEL',
}

export const HomePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const appContext = useContext(AppContext);
  const orderContext = useContext(OrderContext);
  const cartContext = useContext(CartContext);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const venueIdFromUrl = searchParams.get('id');

  const [venue, setVenue] = useState<IOModelVenueWithStores | null>(null);
  const [vendors, setVendors] = useState<StoreWithDetails[]>([]);
  const [{ venue: requestedVenueCode }] = useQueryParams(['venue']);
  const [{ id: requestedVenueID }] = useQueryParams(['id']);
  const { t } = useTranslation(['patron_portal', 'common']);
  const [isVenueSelectionConfirm, setVenueConfirmation] = useState(false);
  const [getConfirmation, Confirmation] = UseConfirmationDailog();
  const [patronVenueChoice, setPatronVenueChoice] = useState<VenueSeatChoice>({
    venueID: undefined,
    serviceChoices: {
      serviceMethod: undefined,
      deliveryInfo: {},
    },
  });

  const { loading: getVenueLoading, refetch: getVenueByCode } = useQuery(
    GET_VENUE_BY_CODE,
    {
      skip: true,
      onCompleted: data => {
        if (data?.getVenueByVenueCode) {
          setSelectedVenue(data?.getVenueByVenueCode);
        }
      },
      onError: e => {
        setVenue(null);
        setVenueConfirmation(false);
        enqueueSnackbar(extractAllErrorMesasges(e), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      },
    },
  );

  const { loading: getVenueByIDLoading, refetch: getVenue } = useQuery(
    GET_VENUE_WITH_STORES,
    {
      skip: true,
      onCompleted: data => {
        if (data?.getVenue) {
          setSelectedVenue(data?.getVenue);
        }
      },
      onError: e => {
        setVenue(null);
        setVenueConfirmation(false);
        enqueueSnackbar(extractAllErrorMesasges(e), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      },
    },
  );

  const { loading: getVenueByVendorLoading, refetch: getPublicVendors } =
    useQuery(GET_VENDORS, {
      skip: true,
      variables: { venueID: venue?.id },
      onCompleted: data => {
        if (data?.getPublicVendors && venue) {
          const filteredVendorsArray = data.getPublicVendors
            .filter((vendor: Vendor) => vendor.venueID === venue.id)
            .map((vendor: Vendor) => ({
              id: vendor.venueID,
              isDisabled: vendor.isDisabled,
              name: vendor.businessName,
              isOpen: vendor.isOpen,
            }));
          setVendors(filteredVendorsArray);
        } else {
          console.log('No vendors found');
        }
      },
      onError: e => {
        setVenue(null);
        setVenueConfirmation(false);
        enqueueSnackbar(extractAllErrorMesasges(e), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      },
    });

  useEffect(() => {
    if (venueIdFromUrl) {
      setVenueConfirmation(true);
    }
    if (venue) {
      getPublicVendors();
    }
  }, [venue, getPublicVendors]);

  useEffect(() => {
    const isValidID = params?.venueID?.match(/^[0-9a-fA-F]{24}$/);
    if (params?.venueID && !isValidID) {
      navigate(HOME_PAGE_ROUTE);
    } else if (params.venueID) {
      const { venueID = '' } = params;
      // setVenue(venueID);
    }
  }, [location]);

  useEffect(() => {
    if (requestedVenueCode && requestedVenueCode != '') {
      getVenueByCode({
        venueQueryParams: {
          filter: {
            venueCode: requestedVenueCode?.toLocaleLowerCase(),
          },
        },
        includeIntegrations: true,
        integrationStatus: integrationStatus.Active,
        integrationType: integrationTypes.PMS,
      });
    } else {
      if (requestedVenueID && requestedVenueID != '') {
        getVenue({
          id: requestedVenueID,
          includeIntegrations: true,
          integrationStatus: integrationStatus.Active,
          integrationType: integrationTypes.PMS,
        });
      } else {
        setVenue(null);
      }
    }
  }, [requestedVenueCode, requestedVenueID]);

  // Methods

  const setSelectedVenue = async (venue: IOModelVenueWithStores) => {
    let venueConfirmed = true;
    // if has context
    if (
      venue?.id &&
      appContext?.stadium?.value &&
      venue.id != appContext?.stadium?.value
    ) {
      console.log('confirm 1');
      venueConfirmed = (await getConfirmation(
        t('patron_portal.seatSelectionForm.seatChangeWarnTitle', {
          ns: 'patron_portal',
        }),
        t('patron_portal.seatSelectionForm.seatChangeWarnBody', {
          ns: 'patron_portal',
        }),
      )) as boolean;

      if (venueConfirmed) {
        cartContext.clear();
        appContext.clear();
        orderContext.clear();
      } else {
        navigate(`?id=${appContext?.stadium?.value}`, { replace: true });
        // location.reload();
      }
    }
    if (venueConfirmed) {
      setVenueConfirmation(true);
      setVenue(venue);
      setPatronVenueChoice({
        venueID: venue.id,
        serviceChoices: {
          deliveryInfo: {
            collectionPointID: appContext?.collectionPoint?.value,
            row: appContext?.row?.value,
            seat: appContext?.seat?.value,
            sectionID: appContext?.section?.value,
          },
          serviceMethod: appContext?.serviceMethod?.value as ServiceMethod,
        },
      });
    }
  };
  // On Service Method / Locatoin selection, continue item listing
  const gotoVenueOrdering = (
    selectedVenue: IOModelVenueWithStores,
    selectedServiceMethod: ServiceMethod,
    desiredDeliveryLocInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
    guestInfo?: any,
  ) => {
    // todo: Validate

    // Todo: Have common setter/getter methods in App Context instead of individual ones

    appContext.clear();
    // cartContext.clear();

    appContext.stadium?.setValue(selectedVenue.id);
    appContext.section?.setValue(desiredDeliveryLocInfo?.section ?? '');
    appContext.sections?.setValue(selectedVenue?.sections ?? []);
    appContext.collectionPoint?.setValue(
      desiredDeliveryLocInfo?.collectionPoint ?? '',
    );
    appContext.seat?.setValue(desiredDeliveryLocInfo.seat || 'N/A');
    appContext.row?.setValue(desiredDeliveryLocInfo.row || 'N/A');
    appContext.venueCurrencySymbol?.setValue(
      selectedVenue?.venueCurrency?.symbol,
    );

    // @deprecated (click and collect enabled)
    appContext.isSectionClickAndCollectEnabled?.setValue(
      selectedServiceMethod === ServiceMethod.COLLECT_FROM_VENDOR,
    );
    appContext.serviceMethod?.setValue(selectedServiceMethod);
    appContext.venueType?.setValue(selectedVenue.venueType);
    appContext.venueCode?.setValue(selectedVenue.venueCode);

    if (selectedStore && selectedStore.id) {
      appContext.store?.setValue(selectedStore);
      navigate(`/vendors/${selectedStore.ownerID}/stores/${selectedStore.id}`);
    } else {
      if (
        selectedVenue?.venueType === VenueTypes.DINE_IN ||
        selectedVenue?.venueType === VenueTypes.HOTEL ||
        appContext.whitelabelConfig?.value?.features?.useNewMenu
      ) {
        navigate(`${STORES_LIST_ROUTE}`);
      } else {
        navigate(LIST_VENDORS_PAGE_ROUTE);
      }
    }
  };
  console.log('vendors', vendors);
  return (
    <>
      {orderContext.hasActiveOrders && <PastOrdersBanner />}

      {isVenueSelectionConfirm ? (
        getVenueLoading === false &&
        getVenueByIDLoading === false &&
        getVenueByVendorLoading === false &&
        venue ? (
          <VenueHomePage
            venue={venue}
            vendors={vendors}
            refetchVenue={getVenue}
            refetchVendor={getPublicVendors}
            goNext={gotoVenueOrdering}
            patronVenueChoices={patronVenueChoice}
          />
        ) : (
          <Loader visible={true} />
        )
      ) : (
        <VenueListPage venueIDFromCache={appContext?.stadium?.value} />
      )}

      {!isVenueSelectionConfirm && <Loader visible={true} />}
      {Confirmation && <Confirmation />}
    </>
  );
};
