import { useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import { UserContext } from '../context/user-context';
import { fetchToken } from '../firebase/firebase';
import { ADD_DEVICE } from '../graphql/mutation';
import { GET_PATRON } from '../graphql/query';
import { Patron, UserType } from '../types/user';
import { getOperatingSystem } from '../utils/get-os';
import { useMutationHook } from './use-mutation';

interface IRepsone {
  loading: boolean;
}

export const useAuth = (): IRepsone => {
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext);

  const { mutate: addDevice } = useMutationHook({
    mutation: ADD_DEVICE,
  });

  const updateDeviceDetails = async (patron: Patron) => {
    if (patron && patron._id) {
      const deviceToken = await fetchToken();
      addDevice({
        variables: {
          input: {
            id: patron?._id,
            deviceDetails: {
              browser: browserName,
              os: getOperatingSystem(window),
              token: deviceToken,
            },
            userType: UserType.patron,
          },
        },
      });
    }
  };

  const [getPatron] = useLazyQuery(GET_PATRON, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const patron: Patron = data?.getPatron;
      userContext.setPatron(patron);
    },
  });

  const init = async () => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      userContext.setUser(user);
      const payload = user?.signInUserSession?.idToken?.payload || {};

      if (!payload.phone_number) {
        setLoading(false);
        return;
      }

      const getPatronData = await getPatron({
        context: {
          headers: {
            authorization: '',
          },
        },
        variables: {
          payload: {
            value: payload.phone_number,
            type: 'BY_PHONE_NUMBER',
          },
        },
      });

      await updateDeviceDetails(getPatronData?.data?.getPatron);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return { loading };
};
