import {
  Box, Grid,
  Icon,
  Link, useMediaQuery
} from '@mui/material';
import React, { useContext, useState } from 'react';
import '../styles/network-error-page.css';
import { ReactComponent as BeerIcon } from '../assets/icons/BeerSticker.svg';
import { ReactComponent as BeerVendorIcon } from '../assets/icons/BeerVendorSticker.svg';
import { Text } from '../elements/text';
import { CustomButton } from '../elements/button';
import Checkbox from '@mui/material/Checkbox';
import { CartContext } from '../context/cart-context';
import { useNavigate } from 'react-router-dom';
import { CategoryType, PreOrderSummary } from '../types';
import { GET_PRE_ORDER_SUMMARY } from '../graphql/query';
import { useQuery } from '@apollo/client';
import { AppContext } from '../context/app-context';
import {
  RSA_GUIIDELINES,
  VIEW_CART_ROUTE
} from '../constants/routes';
import { useCreateOrder } from '../hooks/use-order.hook';
import { Header } from '../components/header';
import { useTranslation } from 'react-i18next';
import { ServiceMethod } from '../types/order';
import { Loader } from '../elements/loader';
import { UserContext } from '../context/user-context';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { getEntryPointRoute } from '../utils/get-entry-point-route';

export const CheckOutNotice = () => {
  // TODO: make the timer as a prop or config.
  const [isVerifyBoxChecked, setIsVerifyBoxChecked] = useState<boolean>(false);
  const {
    isSectionClickAndCollectEnabled,
    stadium,
    serviceMethod,
    venueType,
    selectedVendor,
    store: storeInContext,
  } = useContext(AppContext);
  const { cart, removeMultipleFromCart } = useContext(CartContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);
  const isNarrowScreen = useMediaQuery('(max-width: 350px)');

  const cartItems = Object.keys(cart).map(key => {
    return {
      itemID: cart[key].id,
      count: cart[key].count,
      storeID: cart[key].storeID,
      customisations: cart[key].customisations,
      serviceMethod: serviceMethod?.value,
    };
  });

  const {
    data,
    loading: preOrderSummaryLoading,
    refetch: fetchPreOrderSummary,
  } = useQuery(GET_PRE_ORDER_SUMMARY, {
    variables: {
      preOrderSummaryInput: {
        items: cartItems,
        venueId: stadium?.value,
        serviceMethod: serviceMethod?.value,
      },
    },
  });

  const preOrderSummary = (data?.getPreOrderSummary as PreOrderSummary) || {};
  const { available = [], unavailable = [] } = preOrderSummary;

  const removeItemsWithAlcoholAndShop = () => {
    const alcoholFilteredItems = [...available, ...unavailable].filter(
      item => item.category === CategoryType.ALCOHOL,
    );
    removeMultipleFromCart(alcoholFilteredItems.map(item => item.key));
    navigate(
      getEntryPointRoute(
        venueType?.value || '',
        storeInContext?.value
      ),
    );
  };

  const continueToCheckoutHandler = async () => {
    await createOrder();
  };

  const [createOrder, { loading: isCreateOrderLoading, data: a }] =
    useCreateOrder({
      onError: () => {
        fetchPreOrderSummary({
          preOrderSummaryInput: {
            items: cartItems,
            venueId: stadium?.value,
            serviceMethod: serviceMethod?.value,
          },
        });
        navigate(VIEW_CART_ROUTE);
      },
      savePatronAgeConsent: true,
    });

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsVerifyBoxChecked(event.target.checked);
  };

  const showValidIDMessage =
    serviceMethod?.value === ServiceMethod.COLLECT_FROM_VENDOR
      ? t('patron_portal.notice.msgCNCShowValidId', {
        ns: 'patron_portal',
      })
      : t('patron_portal.notice.msgTTSShowValidId', {
        ns: 'patron_portal',
      });

  return (
    <Grid container height={'auto'} justifyContent={'center'}>
      <Loader visible={isCreateOrderLoading || preOrderSummaryLoading} />
      <Grid item xs={12}>
        <Header
          leftComponent={
            <ArrowBackIcon onClick={() => navigate(VIEW_CART_ROUTE)} />
          }
          middleComponent={
            <Text
              text={t('patron_portal.order.labelMyCart', {
                ns: 'patron_portal',
              })}
              fontSize={18}
              fontWeight={700}
            />
          }
          disableProfileIcon={true}
        />
      </Grid>
      <Grid item margin={'auto'} textAlign={'center'} mx={2}>
        <Box mt={'60px'}>
          <Text
            fontSize={isNarrowScreen ? 18 : 20}
            fontWeight={400}
            text={showValidIDMessage}
            fontColor={'#FF50DD'}
          />
        </Box>
        <Box textAlign={'center'} mt={'50px'}>
          <Box m="auto">
            <Icon
              style={{
                animation: 'scale-up 0.5s ease',
                height: '100%',
                width: '100%',
              }}
            >
              {isSectionClickAndCollectEnabled ? (
                <BeerVendorIcon />
              ) : (
                <BeerIcon />
              )}
            </Icon>
          </Box>

          <Box mt={'35px'}>
            <Text
              fontSize={isNarrowScreen ? 18 : 20}
              fontWeight={700}
              text={t('patron_portal.notice.msgAgeProhibited', {
                ns: 'patron_portal',
              })}
              fontColor={'#1D2306'}
            />
            <Text
              fontSize={15}
              fontWeight={700}
              text={t('patron_portal.notice.msgRefuseDelivery', {
                ns: 'patron_portal',
              })}
              fontColor={'#FF50DD'}
              style={{
                marginTop: '24px',
              }}
            />
          </Box>

          <Box margin={'auto'} marginTop={'35px'}>
            <CustomButton
              onClick={continueToCheckoutHandler}
              disabled={!isVerifyBoxChecked}
              title={t('patron_portal.notice.buttonOver18ContinueCheckout', {
                ns: 'patron_portal',
              })}
              style={{
                fontSize: isNarrowScreen ? '16px' : '18px',
                color: !isVerifyBoxChecked ? '#FFFFFF' : '#D2FF28',
                border: !isVerifyBoxChecked
                  ? '1.5px solid #FFFFFF'
                  : '1.5px solid #D2FF28',
                background: !isVerifyBoxChecked ? '#CECECE' : '#2A2A2A',
                '&:hover': {
                  background: !isVerifyBoxChecked ? '#CECECE' : '#2A2A2A',
                },
                boxShadow: !isVerifyBoxChecked
                  ? '0px 0px 0px 8px #CECECE'
                  : '0px 0px 0px 8px #2A2A2A',
                '&:focus': {
                  border: !isVerifyBoxChecked
                    ? '1px solid #FFFFFF'
                    : '1px solid #D2FF28',
                },
              }}
            />
          </Box>

          <Box mt={'16px'} display={'flex'} alignItems={'center'}>
            <Checkbox
              onChange={handleCheckBox}
              checked={isVerifyBoxChecked}
              disabled={cart ? false : true}
              sx={{
                color: '#F84244',
                '&.Mui-checked': {
                  color: '#2A2A2A',
                  fontColor: '#2A2A2A',
                  fill: '#FFFFFF',
                  background: 'none',
                },
              }}
            />
            <Link
              href={RSA_GUIIDELINES}
              target="_blank"
              rel="noopener"
              sx={{
                color: isVerifyBoxChecked ? '#2A2A2A' : '#F84244',
                textDecoration: 'underline',
                fontWeight: '600',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
            >
              {t('patron_portal.notice.msgAcceptRSAGuidelines', {
                ns: 'patron_portal',
              })}
            </Link>
          </Box>
        </Box>

        <Box margin={'auto'} marginTop={'24px'} marginBottom={'28px'}>
          <CustomButton
            onClick={removeItemsWithAlcoholAndShop}
            title={t('patron_portal.notice.buttonRemoveAndKeepShopping', {
              ns: 'patron_portal',
            })}
            secondary={true}
            style={{
              fontSize: isNarrowScreen ? '16px' : '18px',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
