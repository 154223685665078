import { Box, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { CustomSelect } from '../../elements/select';
import { CustomInput } from '../../elements/input';
import { CustomButton } from '../../elements/button';
import { t } from 'i18next';
import { GET_PMS_GUEST_INFO } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import { Rule } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Loader } from '../../elements/loader';
import { ServiceMethod } from '../../types/order';
import { IVenueSectionDeliveryInfoMeta } from '../../pages/home/home';
import { StoreWithDetails } from '../vendor-menu/types';

export const PMSGuestInfoForm: React.FC<{
  venueID: string;
  integrationID: string;
  onLocationSelection: (
    selectedServiceMethod: ServiceMethod,
    deliveryLocationInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
    guestInfo?: any,
  ) => void;
}> = ({ venueID, integrationID, onLocationSelection }) => {
  const [fname, setFName] = useState<string>('');
  const [lname, setLName] = useState<string>('');
  const [reservationID, setReservationID] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const isButtonDisabled = false;

  const { loading: guestInfoLoading, refetch: getGuestInfo } = useQuery(
    GET_PMS_GUEST_INFO,
    {
      skip: true,
      variables: {
        venueID: venueID,
        integrationID: integrationID,
        reservationID: reservationID,
      },
      onCompleted: data => {
        // todo: Verify Guest Info with First Name/Last Name/ Stay Dates etc.
        // onLocationSelection();
      },
      onError: data => {
        // todo: Specific error message
        enqueueSnackbar('Error fetching guest information.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      },
    },
  );

  const onFormSubmit = async () => {
    getGuestInfo({
      venueID: venueID,
      integrationID: integrationID,
      reservationID: reservationID,
    });
  };

  return (
    <>
      <Loader visible={guestInfoLoading} />
      <Stack>
        <CustomInput
          onChange={setFName}
          title={t('common.label.firstName', { ns: 'common' })}
          value={fname}
        />

        <CustomInput
          onChange={setLName}
          title={t('common.label.lastName', { ns: 'common' })}
          value={lname}
        />

        <CustomInput
          onChange={setReservationID}
          title={t('patron_portal.seatSelectionForm.reservationID', {
            ns: 'patron_portal',
          })}
          value={reservationID}
        />

        <Box mt={2} width={1}>
          <CustomButton
            disabled={isButtonDisabled}
            title={t('patron_portal.storeSelectionForm.submitButtonLabel', {
              ns: 'patron_portal',
            })}
            onClick={onFormSubmit}
            style={{
              color: 'white',
              border: '1px solid white',
              //boxShadow: `0px 0px 0px 6px ${getButtonBackground()}`,
              // background: getButtonBackground(),
              p: '8px',
              mt: '32px',
              '&:hover': {
                background: '#735c31',
                border: '1px solid white',
                boxShadow: '0px 0px 0px 6px #735c31',
              },
              '&:focus': {
                border: '1px solid white',
              },
            }}
          />
        </Box>
      </Stack>
    </>
  );
};
