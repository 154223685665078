import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/app-context';
import { CustomButton } from '../../elements/button';
import { Image } from '../../elements/image';
import { Loader } from '../../elements/loader';
import { CustomSelect, MenuItemType } from '../../elements/select';
import { GET_VENUES } from '../../graphql/query';
import { Venue } from '../../types';
import { useQuery } from '@apollo/client';
import { images } from '../../constants/images';
import { useTranslation } from 'react-i18next';

export const VenueListPage: React.FC<{
  venueIDFromCache: string | undefined;
}> = ({ venueIDFromCache }) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const { t } = useTranslation(['patron_portal', 'common']);

  const [selectedVenue, setSelectedVenue] = useState<MenuItemType | undefined>(
    undefined,
  );

  const [landingBrandingImage, setLandingBrandingImage] = useState<string>(
    appContext.whitelabelConfig?.value?.landingImageUrl ||
      images.DEFAULT_LANDING_PAGE_IMAGE,
  );

  const { data, loading: venueListLoading } = useQuery(GET_VENUES, {
    variables: {
      venueQueryParams: {
        filter: {
          isDisabled: false,
        },
      },
    },
  });

  const venues = data?.getVenuesByFilter || [];

  const venuesSelectData: MenuItemType[] = venues
    .map((venue: Venue) => ({
      label: venue.name,
      id: venue.id,
      venueType: venue.venueType,
      venueCode: venue.venueCode,
    }))
    .sort((v1: MenuItemType, v2: MenuItemType) =>
      v1.label.localeCompare(v2.label),
    );

  useEffect(() => {
    if (venueIDFromCache && venues.length) {
      const ven = venues.find((v: Venue) => v.id == venueIDFromCache);
      if (ven) {
        setSelectedVenue({
          id: ven.id,
          label: ven.name,
          venueType: ven.venueType,
          venueCode: ven.venueCode,
        });
      }
    }
  }, [venueIDFromCache, venues]);

  const navigateToVenuePage = (venueCode: string) => {
    navigate(`?id=${venueCode}`, { replace: true });
    // location.reload();
  };

  const onVenueChange = (venueID: string) => {
    if (venueID) {
      const selectedVenue: MenuItemType | undefined = venuesSelectData?.find(
        (venue: MenuItemType) => venue.id === venueID,
      );

      setSelectedVenue(selectedVenue);
    }
  };

  const onVenueConfirm = () => {
    if (selectedVenue) {
      navigateToVenuePage(selectedVenue.id);
    }
  };

  if (venueListLoading) {
    return <Loader visible={venueListLoading} />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              minHeight: { md: '100vh' },
              justifyContent: 'center',

              display: 'flex',
            }}
          >
            <Image src={landingBrandingImage} alt={'Venue Image'} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack sx={{ bottom: 0 }} spacing={2} py={2} px={2}>
            <CustomSelect
              onChange={(value: string) => {
                onVenueChange(value);
              }}
              data={venuesSelectData}
              title={t('common.location', { ns: 'common' })}
              value={selectedVenue?.id}
            />

            {!selectedVenue && (
              <Typography
                alignSelf={'left'}
                variant="body2"
                sx={{ mt: '3px !important' }}
              >
                Please select a location to continue.
              </Typography>
            )}

            <Box flexGrow={1} mt={2}>
              <CustomButton
                disabled={!selectedVenue}
                title={t(
                  'patron_portal.seatSelectionForm.venueListButtonLabel',
                  {
                    ns: 'patron_portal',
                  },
                )}
                onClick={onVenueConfirm}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
