import { gql } from '@apollo/client';

export const INITIATE_SSO_AUTH_MUTATION = gql`
  mutation initiateSSOAuth {
    initiateSSOAuth {
      nextAction
    }
  }
`;

export const COGNITO_VERIFY_USER_ATTRIBUTE_MUTATION = gql`
  mutation cognitoVerifyUserAttribute(
    $payload: CognitoVerifyUserAttributeInput
  ) {
    cognitoVerifyUserAttribute(payload: $payload)
  }
`;

export const INITIATE_OTP_AUTH_MUTATION = gql`
  mutation initiateOTPAuth($phoneNumber: String!) {
    initiateOTPAuth(phoneNumber: $phoneNumber) {
      username
    }
  }
`;

export const ATTACH_PHONE_NUMBER_TO_USER = gql`
  mutation attachPhoneNumberToUser($payload: AttachPhoneNumberToUserInput!) {
    attachPhoneNumberToUser(payload: $payload)
  }
`;

export const INITIATE_EMAIL_OTP_AUTH_MUTATION = gql`
  mutation initiateEmailOTPAuth($phoneNumber: String!, $email: String!) {
    initiateEmailOTPAuth(phoneNumber: $phoneNumber, email: $email) {
      username
    }
  }
`;

export const UPDATE_PATRON_MUTATION = gql`
  mutation updatePatron($id: String!, $patron: UpdatePatronInput!) {
    updatePatronDetails(id: $id, patron: $patron) {
      _id
      name
      currentLoginMechanism
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($createOrderTypeInput: CreateOrderTypeInput) {
    createOrder(createOrderTypeInput: $createOrderTypeInput) {
      _id
      location {
        venue
        section
        collectionPoint
        row
        seat
      }
      pricing {
        subTotal
        deliveryCharge
        serviceCharge
        total
      }
      serviceMethod
      notes
      paymentIntentClientSecret
      createdAt
      updatedAt
    }
  }
`;

export const MARK_AS_SEEN = gql`
  mutation seenNotifications($ids: [String]) {
    seenNotifications(ids: $ids)
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelPatroOrder($id: String!) {
    cancelPatroOrder(id: $id) {
      _id
    }
  }
`;

export const ADD_DEVICE = gql`
  mutation addDeviceToUser($input: AddDeviceToUserInput!) {
    addDeviceToUser(input: $input)
  }
`;

export const CREATE_PAYMENT_AGREEMENT = gql`
  mutation createPaymentAgreement($createPaymentAgreementInput: CreatePaymentAgreementInput!){
    createPaymentAgreement(createPaymentAgreementInput: $createPaymentAgreementInput){
      maximumAmount
      paymentAgreementToken
      clientTransactionId
      status
      payerDetails{
        name
      }
      startDate
      endDate
    }
  }
`

export const AMEND_PAYMENT_AGREEMENT = gql`
  mutation amendPaymentAgreement($amendPaymentAgreementInput: AmendPaymentAgreementInput!){
    amendPaymentAgreement(amendPaymentAgreementInput: $amendPaymentAgreementInput){
      maximumAmount
      paymentAgreementToken
      clientTransactionId
      status
      payerDetails{
        name
      }
      startDate
      endDate
    }
  }
`
export const CANCEL_AND_CREATE_AGREEMENT = gql`
  mutation cancelAgreementAndCreateNew($cancelAgreementToken:String!, $newAgreementInput:CreatePaymentAgreementInput!) {
    cancelAgreementAndCreateNew(cancelAgreementToken:$cancelAgreementToken, newAgreementInput:$newAgreementInput) {
      maximumAmount
      paymentAgreementToken
      clientTransactionId
      status
      payerDetails{
        name
      }
      startDate
      endDate
    }
  }
`
export const INITIATE_AZUPAY_PAYMENT = gql`
  mutation initiateAzupayPayment($patronId:String!, $patronOrderId:String!, $agreementToken: String!){
    initiateAzupayPayment(patronId: $patronId, patronOrderId: $patronOrderId, agreementToken: $agreementToken){
      pending{
        orderId
        orderNumber
        amount
      }
      error {
        errorCode
        errorReason
      }
    }
  }
`
