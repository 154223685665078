import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LOGIN_ROUTE } from '../constants/routes';
import { UserContext } from '../context/user-context';

export const AuthWizard = () => {
  const userContext = useContext(UserContext);

  if (!userContext.isLoggedIn) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};
