import {
  Box,
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';

import { CustomButton } from '../elements/button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { customTheme } from '../theme';

import { OrderContext } from '../context/order-context';

interface Props extends DialogProps {
  title?: string;
  body?: string;
  bodyIcon?: ReactElement;
  buttonTitle?: string;
  onClose?: any;
  setShowNotesModal?: any;
}

export const NotesModal = ({
  open,
  setShowNotesModal,
  buttonTitle,
}: Props) => {
  const orderContext = useContext(OrderContext);
  const [notes, setNotes]= useState<string>(orderContext.notes || '')
  const { t } = useTranslation(['patron_portal', 'common']);
  const handleChange = (e: any) => {
    setNotes(e.target.value)
    setShowNotesModal(true)
  };
  const handleSubmitNotes = () => {
    if (notes != '') {
      orderContext?.setNotes(notes)
      setShowNotesModal(false);
    }
  };
  const handleClose = () => {
    setShowNotesModal(false);
  };


  return (
    <Dialog
      open={open}
      PaperComponent={props => (
        <Paper {...props} sx={{ borderRadius: '18px' }} />
      )}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
    >
      <Grid container direction={'row'} justifyContent={'center'}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'end'} mr={1} mt={1}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'relative',
              }}
            >
              <CloseRoundedIcon
                sx={{
                  fontSize: '30px',
                  color: '#2A2A2A',
                  cursor: 'pointer',
                  padding: '2px',
                  borderRadius: '48%',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                }}
              />
            </IconButton>
          </Box>
        </Grid>

        <Grid mt={{ md: 'auto', xs: 2 }} mb={'20px'} item xs={12} mx={4}>
          <Box textAlign={'center'}>
            <Text
              fontWeight={700}
              fontSize={'15px'}
              text={t('patron_portal.label.submitNotesText', {
                ns: 'patron_portal',
              })}
            />
          </Box>
          <Box mt={1.5} textAlign={'center'}>
            <TextField
              fullWidth
              multiline
              value={notes}
              onChange={handleChange}
              inputRef={input => input && input.focus()}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
            )}
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': { backgroundColor: '#D9D9D9' },
              }}
              inputProps={{
                maxLength: 120,
                style: {
                  fontFamily: customTheme.fontFamily,
                  height: '70px',
                  padding: '0px',
                  paddingLeft: '12px',

                  backgroundColor: '#D9D9D9',
                },
              }}
            />
          </Box>
        </Grid>

        <Grid mt={{ md: 'auto', xs: 2 }} mb={'20px'} item xs={12} mx={4}>
          <CustomButton
            title={
              buttonTitle ||
              t('patron_portal.label.submitNotes', {
                ns: 'patron_portal',
              })
            }
            onClick={handleSubmitNotes}
            style={{ height: '48px', secondary: 'false' }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
