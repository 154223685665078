import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource-variable/dm-sans';
import { Amplify } from 'aws-amplify';
import { getAmplifyConfig } from './amplify-config';
import { graphqlClient } from './graphql-client';

import { Loader } from './elements/loader';
import './i18n/config';

Amplify.configure(getAmplifyConfig());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={graphqlClient}>
        <Suspense fallback={<Loader visible={true} />}>
          <App />
        </Suspense>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
