import { Box, Dialog, Divider, Grid, Paper, Typography } from '@mui/material';
import { ReactElement, useContext } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Image } from '../elements/image';
import {
  GET_VENDORS,
  GET_VENUE,
  GET_VENUE_WITH_STORES,
} from '../graphql/query';
import { VendorCard } from '../components/vendors';
import { Header } from '../components/header';
import { CartIcon } from '../components/cart-icon';
import { SeatInfo } from '../elements/seat-info';
import { HOME_PAGE_ROUTE } from '../constants/routes';
import { AppContext } from '../context/app-context';
import { Loader } from '../elements/loader';
import {
  Vendor,
  Section as VenueSection,
  Venue,
  CollectionPoint,
} from '../types';
import { useQueryHook } from '../hooks/use-query.hook';
import { CustomButton } from '../elements/button';
import { useQuery } from '@apollo/client';
import { OrderTypeStatusBar } from '../components/order-type-status';
import { Text } from '../elements/text';
import { Store, StoreWithDetails } from '../components/vendor-menu/types';
import { StoreCard } from '../components/vendor/store-card';

export function StoreListing(): ReactElement {
  const {
    section,
    row,
    seat,
    stadium,
    isSectionClickAndCollectEnabled,
    collectionPoint,
    serviceMethod,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const { t } = useTranslation(['patron_portal', 'common']);

  const { loading: fetchingVenue, data: venueData } = useQuery(
    GET_VENUE_WITH_STORES,
    {
      variables: {
        id: stadium?.value,
        storeFilters: { sectionIDs: section?.value },
      },
      onCompleted: data => {},
    },
  );

  const venue: Venue = venueData?.getVenue;
  const isDisabled = venue?.isDisabled || venue?.isDeleted;

  let stores: Array<StoreWithDetails> = venueData?.getVenue?.sellingStores
    ? venueData?.getVenue?.sellingStores
    : [];

  // Sort Stores to show Open ones first
  if (stores) {
    stores = stores?.slice().sort((a: Store, b: Store) => {
      if (a.isDisabled) return 1;
      if (b.isDisabled) return -1;
      if (a.isOpen && !b.isOpen) return -1;
      if (!a.isOpen && b.isOpen) return 1;

      return ('' + a.storeName).localeCompare(b.storeName);
    });
  }

  const selectedSection = venue?.sections?.find(
    (_section: VenueSection) => _section?.id === section?.value,
  );

  const selectedCollectionPoint = venue?.collectionPoints?.find(
    (_collectionPoint: CollectionPoint) =>
      _collectionPoint?.id === collectionPoint?.value,
  );

  if (fetchingVenue) {
    return <Loader visible={fetchingVenue} />;
  }

  const vendorListImageSource = venue.vendorListImageUrl || '';

  const backIconRoute = `/?id=${appContext.stadium?.value}`;

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ zIndex: 2 }}>
          <Header
            backIconRoute={backIconRoute}
            middleComponent={
              <SeatInfo
                stadium={venue?.name}
                section={selectedSection?.label}
                row={row?.value}
                seat={seat?.value}
                collectionPoint={selectedCollectionPoint?.label}
                isClickAndCollectEnabled={
                  isSectionClickAndCollectEnabled?.value
                }
                serviceMethod={serviceMethod?.value}
              />
            }
          />
        </Grid>
        {/* Muted banner for stores */}
        {/* <Grid item xs={12} sx={{ zIndex: 1 }}>
          <Box>
            <OrderTypeStatusBar
              serviceAvailable={true}
              venueName={venue.name}
              serviceMethod={serviceMethod?.value || ''}
            />
          </Box>
        </Grid> */}

        {vendorListImageSource && (
          <Box
            style={{
              width: '100%',
              maxHeight: '25vh',
              paddingTop: '12px',
            }}
          >
            <Box
              style={{
                overflow: 'hidden',
              }}
            >
              <Image
                src={vendorListImageSource}
                style={{
                  objectFit: 'contain',
                  maxHeight: '25vh',
                }}
              />
            </Box>
          </Box>
        )}

        {isDisabled && (
          <Grid item xs={12} paddingTop={`${20}px`}>
            <Box p={2} sx={{ textAlign: 'center' }} mt="10vh">
              <Text
                style={{ fontWeight: '800', color: '#D22B2B' }}
                text={t('patron_portal.vendorListing.notOperatingMsg', {
                  ns: 'patron_portal',
                  venueName: venue.name,
                })}
                fontSize={16}
              />
              <Grid
                container
                item
                xs={12}
                lg={6}
                m="auto"
                p={2}
                paddingTop={`${40}px`}
              >
                <CustomButton
                  style={{ marginTop: '40vh' }}
                  title={t('common.home', { ns: 'common' })}
                  onClick={() => navigate(HOME_PAGE_ROUTE)}
                />
              </Grid>
            </Box>
          </Grid>
        )}

        {!isDisabled && (
          <Grid container item xs={12} lg={10} m="auto" px={2}>
            <Grid item mt={2} mb={2} xs={12}>
              <Text
                text={t('patron_portal.label.stores', { ns: 'patron_portal' })}
                fontSize={18}
                fontWeight={700}
              />
            </Grid>

            {stores.length === 0 ? (
              <Grid item xs={12}>
                <Text
                  text={t('patron_portal.storeListing.noStoresAvailableMsg', {
                    ns: 'patron_portal',
                  })}
                />
              </Grid>
            ) : (
              <Grid container spacing={{ sm: 2 }}>
                {stores.map((store: StoreWithDetails, index: number) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={`${store.id}-${index}`}
                    margin={'auto'}
                  >
                    <StoreCard {...store} />
                    <Divider
                      sx={{
                        marginBottom: '16px',
                        marginTop: '16px',
                        borderColor: 'black',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
