const errorCodes = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};

export const extractAllErrorMesasges = (error: any): string[] => {
  const result: string[] = [];

  error?.graphQLErrors.map((e: any) => {
    result.push(e.message);
  });

  if (error?.networkError) {
    result.push('Error while processing request. Please wait.');
  }

  return result;
};
