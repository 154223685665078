export const HOME_PAGE_ROUTE = '/';
export const VENUE_HOME_PAGE = '/v/:venueID';
export const LIST_VENDORS_PAGE_ROUTE = '/vendors';
export const STORES_LIST_ROUTE = '/stores';

// TO be Deprecated
export const VENDOR_MENU_ROUTE = '/vendors/:vendorID/items';
export const STORE_MENU_ROUTE = '/vendors/:vendorID/stores/:storeID';

export const VIEW_ITEM_ROUTE = '/items/:itemID';
export const VIEW_CART_ROUTE = '/cart';
export const LOGIN_ROUTE = '/login';
export const SSO_AUTH_SUCCESS = '/sso-auth-success';
export const VIEW_ALL_ORDERS = '/view-all-orders';
export const LOG_OUT_ROUTE = '/logout';
export const PROFILE_ROUTE = '/profile-route';
export const PRE_ORDER_SUMMARY_ROUTE = '/pre-order-summary';
export const ORDER_TRACK_ROUTE = '/orders/:orderID';
export const REDIRECT_ORDER_ROUTE = '/redirectOrder/';
export const ORDERS_ROUTE = '/orders';
export const PAYMENT_ROUTE = '/payment';
export const CAPTURE_PATRON_DETAILS = '/capture-patron-details';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const VERIFY_AGE = '/verify-age';
export const RSA_LIMIT = '/rsa-compliance';
export const PAST_ORDER_TRACK_ROUTE = '/past-orders/:orderID';
export const RSA_GUIIDELINES =
  'https://www.liquorandgaming.nsw.gov.au/working-in-the-industry/training-to-work-in-the-industry/getting-trained/responsible-service-of-alcohol-rsa';

export const CUSTOMER_SUPPORT_URL =
  'https://a9zw03h0g11.typeform.com/to/iZCuM7zs';
export const TERMS_OF_SERVICES_URL = 'https://liive.ly/privacypolicy.html';
export const PRIVACY_POLICY_URL = 'https://liive.ly/privacypolicy.html';
export const PAYTO_POLICY_URL = 'https://liive.ly/azupay-policy/index.html';


export const getListItemsRoute = (vendorID: string) => {
  return `/vendors/${vendorID}/items`;
};

export const getStoreMenuRoute = (storeOwnerID: string, storeID: string) => {
  return `/vendors/${storeOwnerID}/stores/${storeID}`;
};

export const getViewItemRoute = (itemID: string) => {
  return `/items/${itemID}`;
};

export const getOrderTrackingRoute = (orderID: string) => `/orders/${orderID}`;
export const getPastOrderTrackingRoute = (orderID: string) => `/past-orders/${orderID}`;