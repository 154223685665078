import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { customTheme } from '../../theme';
import { CustomisationOption } from '../../types/customisation';
import { getPriceWithCurrency } from '../../utils/charges';
import { RadioOptionProps } from './types';

export function RadioOptions(props: RadioOptionProps) {
  const controller = useController({ name: props.name });
  const { field } = controller;
  const { options, itemCount } = props;
  let error = field.value ? false : props.error;
  let helperText = props.helperText;
  const handleRadioChange = () => {
    error = false;
    helperText = '';
  };

  return (
    <RadioGroup {...field}>
      {error && (
        <Typography fontWeight={200} fontSize={12} color="red">
          {helperText}
        </Typography>
      )}
      {options.map((option: CustomisationOption) => (
        <Stack direction={'row'} key={option._id} my={1} ml={2} mr={1}>
          <FormControlLabel
            value={option._id}
            control={
              <Radio
                sx={{
                  color: error ? 'red' : 'black',
                  padding: 0,
                  mr: 1.5,
                  alignSelf: 'start'
                }}
                onChange={() => field.onChange(handleRadioChange())}
              />
            }
            label={option.name}
            sx={{
              fontFamily: customTheme.fontFamily,
              flexGrow: 1
            }}
          />
          {option.price !== 0 && (
            <Typography fontFamily={customTheme.fontFamily} variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              {getPriceWithCurrency(option.markupPrice)}
            </Typography>
          )}
          {option.price !== 0 && field.value === option._id && itemCount > 1 && (
            <Typography fontFamily={customTheme.fontFamily} variant="caption" color="grey" sx={{ mt: '2px' }}>
              (×{itemCount})
            </Typography>
          )}
        </Stack>
      ))
      }
    </RadioGroup >
  );
}
