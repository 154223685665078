import React from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { customTheme } from '../theme';
import { ExpandMoreRounded } from '@mui/icons-material';

export type MenuItemType = {
  id: string;
  label: string;
  venueCode?:string
  venueType?:string
};

interface Props {
  data?: Array<MenuItemType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  disabled?: boolean;
  title: string;
}

export function CustomSelect({
  data = [],
  onChange,
  value = '',
  disabled,
  title,
}: Props) {
  return (
    <FormControl fullWidth>
      <Box mb={1}>
        <Typography fontFamily={customTheme.fontFamily}>{title}</Typography>
      </Box>

      <Select
        sx={{
          borderRadius: '8px',
          height: '40px',
          color: '#2A2A2A',
          backgroundColor: 'white',
          fontFamily: customTheme.fontFamily,
        }}
        value={value}
        IconComponent={ExpandMoreRounded}
        onChange={e => {
          onChange(e.target.value);
        }}
        disabled={disabled}
      >
        {data.map((item: MenuItemType) => (
          <MenuItem
            sx={{ fontFamily: customTheme.fontFamily }}
            key={item.id}
            value={item.id}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
