import itemImage from '../assets/images/item.jpg';
import landingBrandingTts from '../assets/images/landing-branding-tts.png';
import landingBrandingCnc from '../assets/images/landing-branding-cnc.png';
import vendorDefaultImg from '../assets/images/vendor-default-img.png';
import landingPageBrandingImage from '../assets/images/landing-page-branding.png';

export const images = {
  ITEM_IMAGE: itemImage,
  DEFAULT_LANDING_BRANDING_TTS: landingBrandingTts,
  DEFAULT_LANDING_BRANDING_CNC: landingBrandingCnc,
  VENDOR_DEFAULT_IMAGE: vendorDefaultImg,
  DEFAULT_LANDING_PAGE_IMAGE: landingPageBrandingImage
};
