import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * [Finds search-keys/optional-params in URL and returns them in an object (also returns JS URLSearchParams instance)]
 * @param  {[string[]]} searchKeys [searchKeys to find in URL]
 * @return {[Record<string, string | null>, URLSearchParams]}     [Object containing key-value paris of requested query-params, URLSearchParams instance]
 */
export const useQueryParams = (
  searchKeys: string[],
): [Record<string, string | null>, URLSearchParams] => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const urlParams: Record<string, string | null> = {};
  searchKeys.forEach(key => {
    const value = searchParams.get(key);
    urlParams[key] = value;
  });
  return [urlParams, searchParams];
};
