import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_VENUE_BY_CODE, GET_VENUE_WITH_STORES } from '../../graphql/query';
import { AppContext } from '../../context/app-context';
import { extractAllErrorMesasges } from '../../utils/graphql';
import { useSnackbar } from 'notistack';
import { Loader } from '../../elements/loader';
import {
  StoreWithDetails,
  integrationStatus,
  integrationTypes,
} from '../../components/vendor-menu/types';
import { IOModelVenueWithStores } from '../../types/io-models';
import { ServiceMethod } from '../../types/order';
import { useTranslation } from 'react-i18next';
import { Image } from '../../elements/image';
import {
  IVenueSectionDeliveryInfoMeta,
  VenueSeatChoice,
  VenueTypes,
} from './home';
import { Alert, Box, Grid, Stack, Typography } from '@mui/material';
import { OrderTypeStatusBar } from '../../components/order-type-status';
import { VenueSectionSeatCaptureForm } from '../../components/patron-capture-details-forms/venue-section-seat-form';
import { TableServiceInfoForm } from '../../components/patron-capture-details-forms/table-service-info-form';
import { HotelRoomGuestInfoForm } from '../../components/patron-capture-details-forms/hotel-room-guest-info';
import { images } from '../../constants/images';
import { CustomSelect } from '../../elements/select';
import { PMSGuestInfoForm } from '../../components/patron-capture-details-forms/pms-guest-info-form';

export const VenueHomePage: React.FC<{
  patronVenueChoices: VenueSeatChoice | undefined;
  venue: IOModelVenueWithStores;
  vendors: StoreWithDetails[];
  refetchVenue: () => void;
  refetchVendor: () => void;
  goNext: (
    selectedVenue: IOModelVenueWithStores,
    selectedServiceMethod: ServiceMethod,
    desiredDeliveryLocInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
    guestInfo?: any,
  ) => void;
}> = ({
  venue,
  vendors,
  patronVenueChoices,
  goNext,
  refetchVenue,
  refetchVendor,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const appContext = useContext(AppContext);

  const [isVenueAvailable, setVenueAvailability] = useState(false);
  const { t } = useTranslation(['patron_portal', 'common']);

  const [hasCustomForm, setHasCustomForm] = useState<boolean>();
  const [venuePMSIntegrationID, setPMSIntegrationID] = useState<string>();

  const [draftPatronVenueChoice, setPatronVenueChoice] =
    useState<VenueSeatChoice>({
      venueID: undefined,
      serviceChoices: {
        serviceMethod: undefined,
        deliveryInfo: {},
      },
    });
  const [brandingImageURI, setBrandingImage] = useState<string>(
    appContext.whitelabelConfig?.value?.landingImageUrl ||
      images.DEFAULT_LANDING_PAGE_IMAGE,
  );

  useEffect(() => {
    if (venue) {
      if (
        venue.venueType === VenueTypes.HOTEL &&
        venue.venueIntegrations &&
        venue.venueIntegrations.length
      ) {
        if (venue?.venueIntegrations[0].meta?.platformMeta?.hotelID) {
          setHasCustomForm(true);
          setPMSIntegrationID(venue.venueIntegrations[0].id);
        }
      }

      if (venue.cncBrandingImageUrl && venue.cncBrandingImageUrl != '') {
        setBrandingImage(venue.cncBrandingImageUrl);
      } else {
        setBrandingImage(
          appContext.whitelabelConfig?.value?.landingImageUrl ||
            images.DEFAULT_LANDING_PAGE_IMAGE,
        );
      }

      if (!venue.isDisabled) {
        if (
          venue.venueType === VenueTypes.DINE_IN ||
          venue.venueType === VenueTypes.HOTEL
        ) {
          if (venue.sellingStores?.length > 0) {
            const hasOpenStore = venue.sellingStores.some(
              store => store.isOpen,
            );
            setVenueAvailability(hasOpenStore);
          } else {
            setVenueAvailability(false);
          }
        } else if (venue.venueType === VenueTypes.EVENT) {
          if (vendors.length > 0) {
            console.log('vendor details', vendors);
            const hasOpenVendor = vendors.some(
              (vendor: any) => vendor.isOpen,
            );

            setVenueAvailability(hasOpenVendor);
          } else {
            setVenueAvailability(false);
          }
        }
      } else {
        console.log('not loading');
      }
    } else {
      setVenueAvailability(false);
    }
  }, [venue, vendors]);

  // Methods

  const onSeatSelectionChange = (
    selectedServiceMethod: ServiceMethod,
    desiredDeliveryLocInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
    guestInfo?: any,
  ) => {
    if (venue) {
      goNext(
        venue,
        selectedServiceMethod,
        desiredDeliveryLocInfo,
        selectedStore,
        guestInfo,
      );
    }
  };

  const onCustomFormSubmit = (
    selectedServiceMethod: ServiceMethod,
    deliveryLocationInfo: IVenueSectionDeliveryInfoMeta,
    guestInfo: any,
    selectedStore?: StoreWithDetails,
  ) => {
    // todo: Save Guest Info in context (TO be used when signing-in Patron)
    // goToNext(venue, selectedServiceMethod, deliveryLocationInfo);
  };

  const getSeatSelectionForm = (venue: IOModelVenueWithStores) => {
    switch (venue.venueType) {
      case VenueTypes.EVENT:
        return (
          <VenueSectionSeatCaptureForm
            venue={venue}
            vendors={vendors}
            serviceAvailable={isVenueAvailable}
            refetchVendor={refetchVendor}
            // allowedServiceMethods={[]}
            onLocationSelection={onSeatSelectionChange}
            serviceChoices={{
              serviceMethod: patronVenueChoices?.serviceChoices?.serviceMethod,
              deliveryInfo: {
                section:
                  patronVenueChoices?.serviceChoices?.deliveryInfo?.sectionID,
                collectionPoint:
                  patronVenueChoices?.serviceChoices?.deliveryInfo
                    ?.collectionPointID,
                row: patronVenueChoices?.serviceChoices?.deliveryInfo?.row,
                seat: patronVenueChoices?.serviceChoices?.deliveryInfo?.seat,
              },
            }}
          />
        );

      case VenueTypes.DINE_IN:
        return (
          <TableServiceInfoForm
            serviceAvailable={isVenueAvailable}
            onLocationSelection={onSeatSelectionChange}
            patronVenueChoices={patronVenueChoices}
            venue={venue}
            refetchVenue={refetchVenue}
          ></TableServiceInfoForm>
        );

      case VenueTypes.HOTEL:
        if (hasCustomForm && venuePMSIntegrationID) {
          return (
            <PMSGuestInfoForm
              venueID={venue.id}
              integrationID={venuePMSIntegrationID}
              onLocationSelection={onCustomFormSubmit}
            ></PMSGuestInfoForm>
          );
        } else {
          return (
            <HotelRoomGuestInfoForm
              venue={venue}
              serviceAvailable={isVenueAvailable}
              onLocationSelection={onSeatSelectionChange}
              patronVenueChoices={patronVenueChoices}
              refetchVenue={refetchVenue}
            ></HotelRoomGuestInfoForm>
          );
        }
    }
  };

  if (venue) {
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              minHeight: { md: '100vh' },
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Image src={brandingImageURI} alt={'Venue Image'} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* TODO: Muting Order status banner */}
          <OrderTypeStatusBar
            serviceAvailable={isVenueAvailable}
            venueName={venue?.name}
            serviceMethod={
              patronVenueChoices?.serviceChoices?.serviceMethod ||
              ServiceMethod.DELIVERY
            }
          />
          <Stack sx={{ bottom: 0 }} spacing={2} py={2} px={2}>
            {/* {venue.isDisabled || venue.sellingStores?.length < 1 ? (
              <Alert severity="error">
                {t('patron_portal.vendorListing.notOperatingMsg', {
                  ns: 'patron_portal',
                  venueName: venue.name,
                })}
              </Alert>
            ) : null} */}

            <CustomSelect
              onChange={(value: string) => {
                // setVenue(value);
              }}
              title={t('common.location', { ns: 'common' })}
              value={venue.id}
              data={[{ id: venue.id, label: venue.name }]}
              disabled
            />

            {venue && getSeatSelectionForm(venue)}
          </Stack>
        </Grid>
      </Grid>
    );
  }
  return <Loader visible={true} />;
};
