/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { createContext, ReactElement } from 'react';

export interface ISubscriber {
  id: string;
  call: any;
}

type NotificationContextType = {
  clear: () => void;
  addSubcribers: (subscriber: ISubscriber) => void;
  getSubscribers: () => ISubscriber[];
  callSubscribers: () => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  clear: () => {},
  callSubscribers: () => {},
  addSubcribers: (subscriber: ISubscriber) => {},
  getSubscribers: () => [],
});

interface Props {
  children: ReactElement;
}

function NotificationContextProvider({ children }: Props) {
  const [subscribers, setSubscribers] = useState<{
    [key: string]: ISubscriber;
  }>({});

  const addSubcribers = (subscriber: ISubscriber) => {
    if (!subscriber) {
      return;
    }

    if (subscriber.id in subscribers) {
      return;
    }
    setSubscribers({
      ...subscribers,
      [subscriber.id]: subscriber,
    });
  };

  const clear = () => {
    setSubscribers({});
  };

  const callSubscribers = () => {
    if (!subscribers) {
      return;
    }

    for (const key of Object.keys(subscribers || {})) {
      const subscriber = subscribers[key];
      if (subscriber && subscriber.call) {
        subscriber.call();
      }
    }
  };

  const value: NotificationContextType = {
    clear,
    addSubcribers,
    callSubscribers,
    getSubscribers: () => {
      if (!subscribers) {
        return [];
      }
      return Object.keys(subscribers).map(k => subscribers[k]);
    },
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider;
