import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { CustomInput } from '../elements/input';

interface Props {
  /* eslint-disable no-unused-vars */
  verifyOTP: (otp: string) => void;
  setOTP: (otp: string) => void;
  otp?: string;
  placeHolder: string
}

const OTP_CODE_LENGTH = 6;
export const PhoneOTP = ({
  // onChangeMobileNumber,
  verifyOTP,
  setOTP,
  otp = '',
  placeHolder
}: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  useEffect(() => {
    if (otp.length === OTP_CODE_LENGTH) {
      verifyOTP(otp);
    }
  }, [otp]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <CustomInput
          title={t('common.label.enterOtpTxt', { ns: 'common' })}
          titleStyle={{ fontWeight: 500, color: '#2A2A2A' }}
          inputPlaceholder={placeHolder}
          value={otp}
          onChange={setOTP}
          inputType={'number'}
        />
      </Grid>
    </Grid>
  );
};
