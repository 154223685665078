import {
  Routes,
  Route,
  matchRoutes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { VendorList } from './pages/vendor-list';
import {
  HOME_PAGE_ROUTE,
  VENDOR_MENU_ROUTE as VENDOR_MENU_ROUTE,
  LIST_VENDORS_PAGE_ROUTE,
  VIEW_ITEM_ROUTE,
  VIEW_CART_ROUTE,
  LOGIN_ROUTE,
  SSO_AUTH_SUCCESS,
  LOG_OUT_ROUTE,
  ORDER_TRACK_ROUTE,
  PAYMENT_ROUTE,
  PROFILE_ROUTE,
  VIEW_ALL_ORDERS,
  CAPTURE_PATRON_DETAILS,
  NOTIFICATIONS_ROUTE,
  VERIFY_AGE,
  RSA_LIMIT,
  STORES_LIST_ROUTE,
  STORE_MENU_ROUTE,
  PAST_ORDER_TRACK_ROUTE,
} from './constants/routes';
import { SeatInfoProtecedRoute } from './components/seat-info-protected-route';
import { VendorItems } from './pages/vendor-items';
import { Cart } from './pages/cart';
import { SSOAuthSuccess } from './pages/sso-auth-success';
import { AuthWizard } from './components/auth-protected';
import { Logout } from './pages/logout';
import { OrderTracking } from './pages/order-tracking';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { ProfileRoute } from './pages/profile-route';
import { PastOrders } from './pages/past-orders';
import { Loader } from './elements/loader';
import { useAuth } from './hooks/use-auth.hook';
import { CapturePatronDetails } from './pages/capture-patron-details';
import { Notification } from './elements/notification';
import { NotificationsPage } from './pages/notifications-page';
import { useContext, useState } from 'react';
import { UserContext } from './context/user-context';
import { InformationModal } from './components/information-modal';
import { useIdleTimer } from 'react-idle-timer';
import { NetworkErrorPage } from './pages/network-error';
import { ReactComponent as WifiIcon } from './assets/icons/ConnectionIssues.svg';
import { AppContext } from './context/app-context';
import { GET_WHITE_LABEL_CONFIG } from './graphql/query';
import { useQuery } from '@apollo/client';
import { APP_DEFAULT_LABEL_CONFIG } from './constants/app-default-label';
import { CheckOutNotice } from './pages/checkout-notice';
import { RSACompliance } from './pages/rsa-compliance';
import { useTranslation } from 'react-i18next';
import VendorMenu from './pages/vendor-menu';
import { StoreListing } from './pages/store-listing';
import { HomePage } from './pages/home/home';
import { PastOrderTracking } from './pages/past-order-tracking';

const Login = React.lazy(
  () => import('./pages/login' /* webpackChunkName: "route.login" */),
);
const Payment = React.lazy(
  () => import('./pages/payment' /* webpackChunkName: "route.payment" */),
);

const routesToExcludeTimeout = [
  { path: ORDER_TRACK_ROUTE },
  { path: VIEW_ALL_ORDERS },
];

const routesWithoutPopup = [{ path: HOME_PAGE_ROUTE }];

export const Wizard = () => {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const { loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal']);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  const handleIdle = () => {
    console.log('User went idle');
  };

  const handleActive = () => {
    const match = matchRoutes(routesToExcludeTimeout, location);
    const matchRoutesWithoutPopup = matchRoutes(routesWithoutPopup, location);
    if (matchRoutesWithoutPopup) {
      window.location.reload();
    }
    if (!match) {
      matchRoutesWithoutPopup
        ? setShowTimeoutModal(false)
        : setShowTimeoutModal(true);
      navigate('/');
    }
  };

  useIdleTimer({
    // Ten minute timer for the timeout
    timeout: 10 * 60000,
    onIdle: handleIdle,
    onActive: handleActive,
  });

  const {
    loading: whiteLabelLoading,
    data,
    error,
  } = useQuery(GET_WHITE_LABEL_CONFIG, {
    variables: {
      input: {
        appId: APP_DEFAULT_LABEL_CONFIG.appId,
        installationId: appContext.hostname?.value,
      },
    },
    onCompleted: data => {
      const config = data.getWhiteLabelConfig;
      if (config) {
        appContext.whitelabelConfig?.setValue(config);
      }
    },
    onError: () => {
      appContext.whitelabelConfig?.setValue(APP_DEFAULT_LABEL_CONFIG);
    },
  });

  useEffect(() => {
    if (appContext.whitelabelConfig?.value) {
      const { pageTitle, faviconUrl, manifestUrl, pageDescription } =
        appContext.whitelabelConfig.value;
      document.title = pageTitle || APP_DEFAULT_LABEL_CONFIG.pageTitle;

      const favIcon = document.getElementById('favicon');
      (favIcon as HTMLAnchorElement).href =
        faviconUrl || APP_DEFAULT_LABEL_CONFIG.faviconUrl;

      if (manifestUrl) {
        const manifestEl = document.getElementById('manifest');
        (manifestEl as HTMLAnchorElement).href = manifestUrl;
      }

      if (pageDescription) {
        const descriptionEl = document.getElementById('meta-description');
        descriptionEl?.setAttribute('content', pageDescription);
      }
    }
  }, [appContext.whitelabelConfig?.value]);

  if (loading || whiteLabelLoading) {
    // Todo: Use Global Suspense instead?
    return <Loader visible={true} />;
  }

  return (
    <>
      {showTimeoutModal && (
        <InformationModal
          open={showTimeoutModal}
          title="Important Information"
          body="Check for changes before placing your order as some options may no longer be available in your location"
          handleContinue={() => {
            setShowTimeoutModal(false);
            navigate('/');
          }}
          buttonTitle={t('patron_portal.labelReturnHome', {
            ns: 'patron_portal',
          })}
        />
      )}
      {userContext.isLoggedIn ? WithSnackBar(<Notification />) : <></>}
      <NoNetworkConnection>
        <Suspense fallback={<Loader visible={true} />}>
          <Routes>
            <Route
              path={HOME_PAGE_ROUTE}
              element={WithSnackBar(<HomePage />)}
            />
            <Route
              path={`${HOME_PAGE_ROUTE}:venueID`}
              element={WithSnackBar(<HomePage />)}
            />
            <Route element={<SeatInfoProtecedRoute />}>
              <Route
                path={LIST_VENDORS_PAGE_ROUTE}
                element={WithSnackBar(<VendorList />)}
              />
              <Route
                path={STORES_LIST_ROUTE}
                element={WithSnackBar(<StoreListing />)}
              />
              <Route
                path={VENDOR_MENU_ROUTE}
                element={WithSnackBar(<VendorItems />)}
              />

              <Route
                path={STORE_MENU_ROUTE}
                element={WithSnackBar(<VendorMenu />)}
              />
            </Route>
            <Route path={VIEW_CART_ROUTE} element={WithSnackBar(<Cart />)} />
            <Route path={LOGIN_ROUTE} element={WithSnackBar(<Login />)} />
            <Route path={LOG_OUT_ROUTE} element={WithSnackBar(<Logout />)} />

            <Route
              path={SSO_AUTH_SUCCESS}
              element={WithSnackBar(<SSOAuthSuccess />)}
            />

            <Route element={<AuthWizard />}>
              <Route path={PAYMENT_ROUTE} element={WithSnackBar(<Payment />)} />
              <Route
                path={VERIFY_AGE}
                element={WithSnackBar(<CheckOutNotice />)}
              />
              <Route
                path={RSA_LIMIT}
                element={WithSnackBar(<RSACompliance />)}
              />
              <Route
                path={ORDER_TRACK_ROUTE}
                element={WithSnackBar(<OrderTracking />)}
              />
              <Route
                path={PROFILE_ROUTE}
                element={WithSnackBar(<ProfileRoute />)}
              />
              <Route
                path={VIEW_ALL_ORDERS}
                element={WithSnackBar(<PastOrders />)}
              />
              <Route
                path={NOTIFICATIONS_ROUTE}
                element={WithSnackBar(<NotificationsPage />)}
              />
              <Route
                path={CAPTURE_PATRON_DETAILS}
                element={WithSnackBar(<CapturePatronDetails />)}
              />
              <Route
                path={PAST_ORDER_TRACK_ROUTE}
                element={WithSnackBar(<PastOrderTracking />)}
              />
            </Route>
          </Routes>
        </Suspense>
      </NoNetworkConnection>
    </>
  );
};

const WithSnackBar = (children: any) => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
      {children}
    </SnackbarProvider>
  );
};

const NoNetworkConnection = (props: any) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <NetworkErrorPage
        statusIcon={<WifiIcon />}
        text={'Temporary Connection Issue.'}
        helperText={
          'If problem persists please head to the vendor to check on your order'
        }
        retryButton={true}
        isOnline={isOnline}
      />
    );
  }
};
