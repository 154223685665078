import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Image } from '../elements/image';
import { customTheme } from '../theme';
import { Charges, Item } from '../types';
import { getItemPrice, getPriceWithCurrency } from '../utils/charges';
import MenuItemModal from './menu-item-modal';
import { GET_CHARGES } from '../graphql/query';
import { useQuery } from '@apollo/client';
import { Loader } from '../elements/loader';
import { useTranslation } from 'react-i18next';
import { MenuItemGroupItem } from './vendor-menu/types';

interface Props {
  item: Item
  storeID?: string
}

export function ItemCard({ item, storeID}: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(['patron_portal', 'common']);

  const {
    data: getChargesData = {
      getCharges: {
        seatDeliveryCharge: 0,
        markupCharge: 0,
      },
    },
    loading: getChargesLoading,
  } = useQuery(GET_CHARGES);

  const charges = getChargesData.getCharges as Charges;

  const openModal = () => {
    if (!item?.outOfStock) {
      setIsModalOpen(true);
      document.body.style.touchAction = 'none';
    }
  };

  const closeBottomSheet = () => {
    setIsModalOpen(false);
    document.body.style.touchAction = 'auto';
  };

  if (getChargesLoading) {
    return <Loader visible={true} />;
  }

  if (!item || item === undefined) {
    return <></>;
  }

  return (
    <>
      {
        <MenuItemModal
          item={item}
          storeID={storeID}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onClose={closeBottomSheet}
        />
      }
      <Box
        display={'flex'}
        width={'100%'}
        height={'100%'}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
        }}
        onClick={openModal}
      >
        <Box
          p={2}
          sx={{
            minWidth: {
              xs: '50px',
              sm: '350px',
            },
            maxWidth: {
              xs: '280px',
              sm: '380px',
            },
          }}
        >
          <Box>
            <Typography
              fontFamily={customTheme.fontFamily}
              fontSize={16}
              fontWeight={500}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item?.name}
            </Typography>
          </Box>
          {!item?.outOfStock ? (
            <Box mt={'2px'}>
              {
                <Typography
                  fontFamily={customTheme.fontFamily}
                  fontSize={14}
                  fontWeight={400}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.price === 0
                    ? t('common.label.selectOptions', { ns: 'common' })
                    : getPriceWithCurrency(getItemPrice(item, charges))}
                </Typography>
              }
            </Box>
          ) : (
            <Box mt={'2px'}>
              <Typography
                fontFamily={customTheme.fontFamily}
                fontSize={14}
                fontWeight={600}
                letterSpacing={1}
                color={'#FF50DD'}
              >
                Out of stock
              </Typography>
            </Box>
          )}
        </Box>
        {item?.imageUrl && (
          <Box
            sx={{
              minWidth: '90px',
              height: '100%',
              width: {
                xs: '90px',
                sm: '150px',
                md: '180px',
                lg: '210px',
                xl: '210px',
              },
              filter: () =>
                item.outOfStock ? 'grayscale(100%)' : 'grayscale(0%)',
            }}
          >
            <Image
              style={{
                height: '100%',
                width: '100%',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
              }}
              src={item?.thumbImageUrl ? item?.thumbImageUrl : item?.imageUrl}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
