import { ReactElement } from 'react';
import { CUSTOMER_SUPPORT_URL } from '../constants/routes';
import { CustomButton } from '../elements/button';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: any;
	title: string;
}

export function CustomerSupportButton({
	onClick,
	title,
}: Props) {
	return (
		<a href={CUSTOMER_SUPPORT_URL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
			<CustomButton
				style={{
					color: '#FFFFFF',
					border: '1.5px solid #FFFFFF',
					background: "#00C2FF",
					boxShadow: '0px 0px 0px 8px #00C2FF',
					'&:hover': {
						background: '#00C2FF',
						border: '1.5px solid #FFFFFF',
					},
					'&:focus': {
						border: '1px solid #FFFFFF',
					},
				}}
				title={title}
			/>
		</a>
	);
}
