import React, { MouseEventHandler, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const UseConfirmationDailog = (): [
  (title: string, body: string) => Promise<unknown>,
  () => JSX.Element,
] => {
  const { t } = useTranslation(['patron_portal', 'common']);

  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState<{
    resolve: ((val: unknown) => void) | undefined;
  }>({ resolve: undefined });
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const getConfirmation = async (
    title: string,
    body: string,
  ): Promise<unknown> => {
    setTitle(title);
    setBody(body);
    setOpen(true);
    const promise = new Promise((resolve, reject) => {
      setResolver({ resolve });
    });

    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    if (resolver && resolver.resolve) {
      resolver?.resolve(status);
    }
  };

  const Confirmation = () => (
    <Dialog
      open={open}
      PaperComponent={props => <Paper {...props} />}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{body}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onClick(false)}
        >
          {t('common.label.cancel', { ns: 'common' })}
        </Button>
        <Button variant="outlined" onClick={() => onClick(true)}>
          {t('common.label.yes', { ns: 'common' })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return [getConfirmation, Confirmation];
};

export default UseConfirmationDailog;
