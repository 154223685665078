/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import { Box, Grid, Link } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../components/header';
import { LOGIN_ROUTE, VIEW_CART_ROUTE } from '../constants/routes';
import { UserContext } from '../context/user-context';
import { Loader } from '../elements/loader';
import {
  ATTACH_PHONE_NUMBER_TO_USER,
  COGNITO_VERIFY_USER_ATTRIBUTE_MUTATION,
  INITIATE_SSO_AUTH_MUTATION,
} from '../graphql/mutation';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { Text } from '../elements/text';
import { CustomButton } from '../elements/button';
import { Auth } from 'aws-amplify';
import { PhoneOTP } from '../components/phone-otp';
import { AppContext } from '../context/app-context';
import { extractAllErrorMesasges } from '../utils/graphql';
import { PhoneNumberInput } from '../components/phone-input';
import { useCreateOrder } from '../hooks/use-order.hook';
import { usePostLoginNavigation } from '../hooks/use-post-login-navigate.hook';

export default {
  CAPTURE_PHONE_NUMBER: 'CAPTURE_PHONE_NUMBER',
  VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
  ALLOW: 'ALLOW',
};

const MIN_VERIFICATION_TO_PROCEED_TO_CHECKOUT = 2;

export const SSOAuthSuccess = () => {
  const { ssoOTPVerificationFailures } = useContext(AppContext);
  const { t } = useTranslation(['patron_portal', 'common']);
  const [createOrder, { loading: createOrderLoading }] = useCreateOrder({
    onError: () => {
      navigate(VIEW_CART_ROUTE);
    },
  });
  const userContext = useContext(UserContext);
  const [showOTPScreen, setShowOTPScreen] = useState(false);
  const [showPhoneCaptureScreen, setShowPhoneCatpureScreen] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState('');
  const [otp, setOTP] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);

  const [phoneNumber, setPhonenumber] = useState<string>(
    userContext.phoneNumber || '',
  );

  const { postNavigationHandler: postLoginNavigationHandler } =
    usePostLoginNavigation();

  const [initiateAuth, { loading: initiateAuthLoading }] = useMutation(
    INITIATE_SSO_AUTH_MUTATION,
    {
      onError: e => {
        if (e.message.includes('UserNotFoundException')) {
          Auth.signOut();
        }
      },
    },
  );

  const [attachPhoneNumberToUser, { loading: attachPhoneNumberToUserLoading }] =
    useMutation(ATTACH_PHONE_NUMBER_TO_USER, {
      onError: e => {
        setError(extractAllErrorMesasges(e.message).join(' ,'));
      },
      onCompleted: () => {
        setShowPhoneCatpureScreen(false);
        setShowOTPScreen(true);
      },
    });

  const [attachPhoneNumberToUserWithoutOTP, {loading: attachPhoneNumberToUserWithOutOTPLoading}] =
  useMutation(ATTACH_PHONE_NUMBER_TO_USER, {
    onError: e => {
      setError(extractAllErrorMesasges(e.message).join(' ,'));
    },
    onCompleted: () => {
      setShowPhoneCatpureScreen(false);
      window.location.reload()
    },
  })

  const [
    cognitoVerifyUserAttribute,
    { loading: cognitoVerifyUserAttributeLoading },
  ] = useMutation(COGNITO_VERIFY_USER_ATTRIBUTE_MUTATION, {
    onCompleted: () => {
      window.location.reload();
    },
    onError: err => {
      ssoOTPVerificationFailures?.setValue(
        (ssoOTPVerificationFailures?.value || 0) + 1,
      );
      setError(extractAllErrorMesasges(err).join(', '));
    },
  });

  useEffect(() => {
    init();
  }, []);

  async function isLoggedIn() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  }

  const init = async () => {
    try {
      setInitialLoading(true);
      const isUserLoggedIn = await isLoggedIn();
      if (!isUserLoggedIn) {
        setInitialLoading(false);
        navigate(LOGIN_ROUTE);
        return;
      }
      setError('');
      if (!userContext.phoneNumber || !userContext.phoneNumber) {
        initiateAuth({
          context: {
            headers: {
              authorization: userContext.token,
            },
          },
        });
      } else {
        postLoginNavigationHandler();
      }
      setInitialLoading(false);
    } catch (e: any) {
      setError(e);
      setInitialLoading(false);
    }
  };

  const verifyOTP = async (otp: string) => {
    await cognitoVerifyUserAttribute({
      context: {
        headers: {
          authorization: userContext.token,
        },
      },
      variables: {
        payload: {
          code: otp,
          attribute: 'phone_number',
        },
      },
    });
  };

  const resendOTP = async () => {
    ssoOTPVerificationFailures?.setValue(
      (ssoOTPVerificationFailures.value || 0) + 1,
    );
    setOTP('');
    try {
      setError('');
      setLoading(true);
      await Auth.verifyUserAttribute(userContext.cognitoUser, 'phone_number');
      setLoading(false);
    } catch (e: any) {
      setError(e.message);
      setLoading(false);
    }
  };

  const onContinueButtonHandler = async () => {
    try {
      setError('');
      setLoading(true);
      ssoOTPVerificationFailures?.setValue(0);
      const payload = {
        context: {
          headers: {
            authorization: userContext?.token,
          },
        },
        variables: {
          payload: {
            phoneNumber: phoneNumber?.trim(),
            userType: 'PATRON',
          },
        },
      }
      if (phoneNumber !== userContext.phoneNumber && process.env.REACT_APP_DISABLE_OTP === "false") {
        attachPhoneNumberToUser(payload);
      }
       else if (phoneNumber !== userContext.phoneNumber && process.env.REACT_APP_DISABLE_OTP === "true"){
        attachPhoneNumberToUserWithoutOTP(payload);
       }

       else if (!userContext.phoneVerified) {
        await Auth.verifyUserAttribute(userContext.cognitoUser, 'phone_number');
        setShowPhoneCatpureScreen(false);
        setShowOTPScreen(true);
      } else {
        setLoading(false);
        window.location.reload();
      }
      setLoading(false);
    } catch (e: any) {
      setError(e.message);
      setLoading(false);
    }
  };

  if (initialLoading) {
    return <Loader visible={true} />;
  }

  const continueToCheckoutHandler = async () => {
    ssoOTPVerificationFailures?.setValue(0);
    navigate(0);
    // await createOrder();
  };

  return (
    <Grid container>
      <Loader
        visible={
          loading ||
          initiateAuthLoading ||
          cognitoVerifyUserAttributeLoading ||
          attachPhoneNumberToUserLoading ||
          attachPhoneNumberToUserWithOutOTPLoading ||
          createOrderLoading
        }
      />
      <Grid item xs={12}>
        <Header
          disableProfileIcon={true}
          backIconRoute={VIEW_CART_ROUTE}
          middleComponent={
            <Text
              text={t('patron_portal.label.capturePhone', {
                ns: 'patron_portal',
              })}
              fontSize={18}
              fontWeight={700}
            />
          }
        />
      </Grid>
      {showPhoneCaptureScreen && (
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={3}
          margin="auto"
          p={2}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <PhoneNumberInput
              onChange={(val: string) => {
                setPhonenumber(val);
              }}
              value={phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <Text text={err} isError={true} fontSize={16} />
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              disabled={!phoneNumber}
              title={t('common.label.Continue', { ns: 'common' })}
              onClick={onContinueButtonHandler}
            />
          </Grid>
        </Grid>
      )}

      {showOTPScreen && (
        <Grid item xs={12} m="auto" p={2} mt={'10%'}>
          <Box mb={2} sx={{ textAlign: 'center' }}>
            <Text
              fontWeight={700}
              fontSize={24}
              text={t('common.auth.msgEnterOTP', {
                ns: 'common',
                phoneNumber: phoneNumber,
              })}
            />
          </Box>
          <Box width={220} margin="auto">
            <PhoneOTP
              otp={otp}
              setOTP={setOTP}
              // onChangeMobileNumber={() => {
              //   setError('');
              //   setShowPhoneCatpureScreen(true);
              //   setShowOTPScreen(false);
              // }}
              placeHolder={t('common.label.enterOtpHelperTxt', { ns: 'common' })}
              verifyOTP={verifyOTP}
            />
          </Box>
          <Grid mt={2} item xs={12}>
            <Box display="flex" justifyContent={'center'}>
              <Link
                sx={{ fontSize: 16 }}
                onClick={() => {
                  resendOTP();
                }}
              >
                {t('common.auth.labelResendOTP', { ns: 'common' })}
              </Link>
            </Box>
          </Grid>

          {err && (
            <Box mt={2} sx={{ textAlign: 'center' }}>
              <Text fontWeight={700} isError={true} fontSize={14} text={err} />
            </Box>
          )}
        </Grid>
      )}

      {(ssoOTPVerificationFailures?.value || 0) >=
        MIN_VERIFICATION_TO_PROCEED_TO_CHECKOUT && (
        <Grid item xs={12} pl={2} pr={2} mt={2}>
          <CustomButton
            title={t('patron_portal.order.labelContinueToCheckout', {
              ns: 'patron_portal',
            })}
            onClick={continueToCheckoutHandler}
          />
        </Grid>
      )}
    </Grid>
  );
};
