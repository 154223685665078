import React, { useContext } from 'react';
import { Badge, Box, Divider, IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { UserContext } from '../context/user-context';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import {
  CAPTURE_PATRON_DETAILS,
  LOGIN_ROUTE,
  ORDER_TRACK_ROUTE,
  PAYMENT_ROUTE,
  PROFILE_ROUTE,
  RSA_LIMIT,
  VERIFY_AGE,
  VIEW_ALL_ORDERS,
  VIEW_CART_ROUTE,
} from '../constants/routes';
import PastOrdersBanner from './past-orders-banner';
import { OrderContext } from '../context/order-context';
import { ReactComponent as ProfileIcon } from '../assets/icons/ICON_PROFILE.svg';
import { ReactComponent as CartIcon } from '../assets/icons/ICON_CART.svg';
import { ReactComponent as BackIcon } from '../assets/icons/ICON_BACK.svg';
import { ReactComponent as ProfileFilledIcon } from '../assets/icons/ICON_PROFILE_FILLED.svg';
import { CartContext } from '../context/cart-context';

const routesToExcludeBanner = [
  { path: ORDER_TRACK_ROUTE },
  { path: VIEW_ALL_ORDERS },
  { path: PAYMENT_ROUTE },
  { path: PROFILE_ROUTE },
  { path: CAPTURE_PATRON_DETAILS },
  { path: VERIFY_AGE },
  { path: RSA_LIMIT },
];

interface Props {
  leftComponent?: ReactElement;
  middleComponent?: ReactElement;
  rightComponent?: ReactElement;
  disableProfileIcon?: boolean;
  backIconRoute?: string;
}

export function Header({
  leftComponent,
  middleComponent,
  rightComponent,
  disableProfileIcon = false,
  backIconRoute,
}: Props) {
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);
  const { cartItemsCount } = useContext(CartContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleProfile = (event: React.MouseEvent<HTMLElement>) => {
    userContext.cognitoUser ? navigate(PROFILE_ROUTE) : navigate(LOGIN_ROUTE);
  };

  const handleCart = (event: React.MouseEvent<HTMLElement>) => {
    navigate(VIEW_CART_ROUTE);
  };

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    backIconRoute ? navigate(backIconRoute) : navigate(-1);
  };

  const showActiveOrderBanner = () =>
    orderContext.hasActiveOrders &&
    !matchRoutes(routesToExcludeBanner, location);

  return (
    <Box>
      <Box
        height={'5vh'}
        display="flex"
        sx={{
          boxShadow: '0 4px 6px -6px grey',
        }}
        pt={2}
        pb={2}
      >
        <Box
          width="20%"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          {leftComponent ? (
            <Box ml={1}>{leftComponent}</Box>
          ) : (
            <IconButton onClick={handleBack} style={{ padding: 0 }}>
              <BackIcon />
            </IconButton>
          )}
        </Box>
        <Divider orientation="vertical" sx={{ borderColor: '#2A2A2A' }} />
        <Box
          width="55%"
          display={'flex'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
          textAlign="center"
        >
          {middleComponent}
        </Box>
        <Divider orientation="vertical" sx={{ borderColor: '#2A2A2A' }} />
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            display: 'flex',
            textAlign: 'center',
            marginX: cartItemsCount ? 2 : 0,
          }}
          width="25%"
        >
          {rightComponent ? (
            <Box ml={1}>{rightComponent}</Box>
          ) : (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <Box marginRight="5px">
                {userContext.cognitoUser && !disableProfileIcon ? (
                  <IconButton onClick={handleProfile} style={{ padding: 0 }}>
                    <ProfileFilledIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleProfile} style={{ padding: 0 }}>
                    <ProfileIcon />
                  </IconButton>
                )}
              </Box>
              <Box>
                <IconButton onClick={handleCart} style={{ padding: 0 }}>
                  <Badge color="error" badgeContent={cartItemsCount}>
                    <CartIcon />
                  </Badge>
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {showActiveOrderBanner() && <PastOrdersBanner />}
    </Box>
  );
}
