import { Box, Dialog, DialogProps, Divider, Grid, Paper } from '@mui/material';
import React, { MouseEventHandler, ReactComponentElement, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { ReactComponent as ReceiptIcon } from '../assets/icons/Receipt.svg';
import { ReactComponent as ReceiptBannerIcon } from '../assets/icons/ReceiptSticker.svg';
import { CustomButton } from '../elements/button';

interface Props extends DialogProps {
  handleCancel?: MouseEventHandler | undefined;
  handleContinue?: MouseEventHandler | undefined;
  title?: string;
  body?: string;
  bodyIcon?: ReactElement;
  buttonTitle?: string;
}

export const InformationModal = ({
  handleCancel = () => { },
  handleContinue = () => { },
  open = false,
  title,
  body,
  bodyIcon,
  buttonTitle
}: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);

  return (
    <Dialog
      open={open}
      PaperComponent={props => (
        <Paper {...props} sx={{ borderRadius: '18px' }} />
      )}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              m: 2,
              px: 2,
            }}
          >
            {<ReceiptIcon />}
            <Box px={{ xs: '12px', sm: 6, md: 6 }}>
              <Text text={title || t('patron_portal.modal.titleImpInfo', {
                ns: 'patron_portal',
              })} fontWeight={600} fontSize={18} />
            </Box>
          </Box>
          <Divider
            orientation="horizontal"
            sx={{ borderColor: 'black', mx: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              my: 5,
            }}
          >
            {bodyIcon || <ReceiptBannerIcon />}
          </Box>
          <Box style={{ textAlign: 'center' }} mx={2} my={6}>
            <Text
              text={body}
              fontWeight={600}
              fontSize={20}
              fontColor={'#1D2306'}
            />
          </Box>
          <Grid mt={{ md: 'auto', xs: 2 }} mb={'20px'} item xs={12} mx={4}>
            <CustomButton
              title={buttonTitle || t('common.label.Continue', {
                ns: 'common',
              })}
              onClick={handleContinue}
              style={{ height: '48px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
