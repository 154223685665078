import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useMediaQuery, Grid, Box, Collapse } from '@mui/material';
import i18next from 'i18next';
import { useContext, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { CompoundText } from '../../elements/compound-text';
import { VendorOrder, OrderStatus, ServiceMethod } from '../../types/order';
import { orderStatusConfig, VendorOrderCard } from '../vendor-order-card';
import { OrderStatusText } from './order-status-text';
import { Image } from '../../elements/image';
import { Text } from '../../elements/text';
import { OrderStatusStepper } from './order-status-stepper';
import itemImage from '../../assets/images/item-burger.jpg';
import { AppContext } from '../../context/app-context';
import { VenueTypes } from '../../pages/home/home';
import TableBarIcon from '@mui/icons-material/TableBar';
import RoomServiceIcon from '@mui/icons-material/RoomService';

interface IOrderTrackingInfoProps {
  order: VendorOrder;
  orderNo: string;
  orderID: string;
  orderStatus: OrderStatus;
  isClickAndCollectEnabled: boolean;
  orderBannerImage?: string;
  index: number;
  serviceMethod: string;
  collectionPointLabel?: string;
}

export const OrderTrackingInfo = ({
  order,
  orderNo,
  orderID,
  orderStatus,
  isClickAndCollectEnabled,
  serviceMethod,
  orderBannerImage,
  index,
  collectionPointLabel,
}: IOrderTrackingInfoProps) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const [isExpanded, setIsExpanded] = useState(false);
  const isNarrowScreen = useMediaQuery('(max-width: 410px)');
  const { venueType, seat } = useContext(AppContext);

  const getOrderTrackingText = () => {
    console.log(orderStatus);
    switch (orderStatus) {
      case (OrderStatus.PAYMENT_PENDING, OrderStatus.RECEIVED):
        return i18next.t('patron_portal.order.msgOrderStatusRecieved', {
          ns: 'patron_portal',
        });

      case OrderStatus.PREPARING:
        return i18next.t('patron_portal.order.msgOrderStatusPreparing', {
          ns: 'patron_portal',
        });

      case OrderStatus.ACCEPTED:
        return i18next.t('patron_portal.order.msgOrderStatusPreparing', {
          ns: 'patron_portal',
        });

      case OrderStatus.PREPARED: {
        let statusMessage = '';
        if ((venueType?.value === VenueTypes.DINE_IN
            || venueType?.value === VenueTypes.HOTEL)  && seat?.value !== '0') {
          statusMessage = 'patron_portal.order.readyForDelivery';
        } else {
          statusMessage = serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR
            ? 'patron_portal.order.msgOrderStatusPreparedCNC'
            : 'patron_portal.order.msgOrderStatusPreparedTTS';
        }
        return i18next.t(statusMessage, {
          ns: 'patron_portal',
        });
      }
      case OrderStatus.READY_TO_COLLECT:
        return i18next.t(
          'patron_portal.order.msgOrderStatusReadyToCollectFull',
          {
            ns: 'patron_portal',
          },
        );
      case OrderStatus.ASSIGNED:
        return i18next.t('patron_portal.order.msgOrderStatusAssignedFull', {
          ns: 'patron_portal',
        });

      case OrderStatus.PICKED:
        return i18next.t('patron_portal.order.msgOrderStatusPicked', {
          ns: 'patron_portal',
        });

      case OrderStatus.AT_COLLECTION_POINT:
        return i18next.t(
          'patron_portal.order.msgOrderStatusAtCollectionPoint',
          {
            ns: 'patron_portal',
          },
        );

      case OrderStatus.DELIVERED:
        return i18next.t('patron_portal.order.msgOrderStatusDelivered', {
          ns: 'patron_portal',
        });

      case OrderStatus.COMPLETE:
        return i18next.t('patron_portal.order.msgOrderStatusDelivered', {
          ns: 'patron_portal',
        });

      case OrderStatus.PATRON_CANCELLED:
        return i18next.t('patron_portal.order.msgOrderStatusPatronCancelled', {
          ns: 'patron_portal',
        });

      case OrderStatus.REJECTED:
        return i18next.t('patron_portal.order.msgOrderStatusRejected', {
          ns: 'patron_portal',
        });

      case OrderStatus.PAYMENT_REJECTED:
        return i18next.t('patron_portal.order.msgOrderStatusPaymentFailed', {
          ns: 'patron_portal',
        });

      default:
        return '';
    }
  };

  const orderStatusConfigItem = orderStatusConfig[orderStatus];

  const statusTextByServiceMethod =
    orderStatusConfigItem?.textByServiceMethod &&
    orderStatusConfigItem.textByServiceMethod(
      serviceMethod,
      venueType?.value,
      seat?.value,
    );

  const isReadyToCollectByPatron =
    orderStatus === OrderStatus.PREPARED &&
    serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR;

  const validVendorImageStatuses = [
    OrderStatus.PREPARED,
    OrderStatus.ASSIGNED,
    OrderStatus.PICKED,
    OrderStatus.AT_COLLECTION_POINT,
    OrderStatus.COMPLETE,
    OrderStatus.PAYMENT_REJECTED,
  ];
  const showVendorImage = validVendorImageStatuses.includes(orderStatus);

  const hideVendorTradingName =
    (serviceMethod === ServiceMethod.COLLECTION_POINT &&
      (orderStatus === OrderStatus.ASSIGNED ||
        orderStatus === OrderStatus.PICKED ||
        orderStatus === OrderStatus.AT_COLLECTION_POINT)) ||
    orderStatus === OrderStatus.COMPLETE;

  return (
    <Grid container borderRadius={'8px'} position={'relative'}>
      <Grid
        container
        item
        xs={12}
        display={'flex'}
        margin={'auto'}
        position={'relative'}
      >
        <Box
          sx={{
            aspectRatio: '4/1',
            // TODO: change this minHeight before pushing
            minHeight: '100px',
          }}
          position={'relative'}
          borderRadius={'8px'}
          width={'100%'}
        >
          <Image
            src={orderBannerImage || itemImage}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'rgba(255,  255,  255,  0.8)',
            width: '100%',
          }}
          position={'absolute'}
          bottom={'0px'}
          borderRadius={'0px 0px 8px 8px'}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: '4px',
              mx: '8px',
              flexGrow: 1,
            }}
          >
            <Text
              text={order.vendor?.tradingName}
              fontWeight={700}
              fontSize={16}
              style={{
                lineHeight: 1.2,
              }}
            />
            <CompoundText
              text={'Order'}
              boldText={orderNo}
              fontSize={isNarrowScreen ? 16 : 18}
            />
          </Box>
        </Box>
      </Grid>

      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              flexWrap: 'nowrap',
              mt: '18px',
            }}
          >
            <OrderStatusText
              orderStatus={i18next.t(
                orderStatusConfig[orderStatus]?.text ||
                statusTextByServiceMethod,
                {
                  ns: 'patron_portal',
                  collectionPoint: collectionPointLabel,
                },
              )}
            />
            {(venueType?.value === VenueTypes.DINE_IN || venueType?.value === VenueTypes.HOTEL) && seat?.value !== '0' && (
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                py={'4px'}
                px={'12px'}
                sx={{
                  color: 'white',
                  background: 'black',
                  borderRadius: '20px',
                }}
              >
                {venueType?.value === VenueTypes.DINE_IN ? <TableBarIcon/> : <RoomServiceIcon/>}
                <Box sx={{ marginLeft: '4px' }}>
                  <Text
                    text={seat?.value}
                    fontWeight={500}
                    fontSize={20}
                    fontColor={'#fff'}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box>
            <Text
              text={getOrderTrackingText()}
              fontWeight={500}
              fontSize={20}
              fontColor={'#2A2A2A'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} marginY={3}>
          <OrderStatusStepper orderStatus={orderStatus} />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <VendorOrderCard order={order} index={index} isPastOrder={false}/>
      </Grid>
    </Grid>
  );
};
