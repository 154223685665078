import { useQuery } from '@apollo/client';
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PatronOrder, PatronOrderStatus } from '../types/order';
import { UserContext } from './user-context';
import { GET_ALL_PATRON_ORDERS } from '../graphql/query';
import { useQueryHook } from '../hooks/use-query.hook';
import { CartContext } from './cart-context';

export const activeOrderStatusList = [
  PatronOrderStatus.ASSIGNED,
  PatronOrderStatus.PARTIALLY_ASSIGNED,
  PatronOrderStatus.PREPARED,
  PatronOrderStatus.PREPARING,
  PatronOrderStatus.RECEIVED,
  PatronOrderStatus.READY_TO_COLLECT,
  PatronOrderStatus.PARTIALLY_READY_TO_COLLECT,
  PatronOrderStatus.PICKED,
];

type OrderContextType = {
  _id?: string;
  patronID?: string;
  orderStatus?: string;
  location?: any;
  pricing?: any;
  paymentIntentClientSecret?: string;
  createdAt?: string;
  updatedAt?: string;
  hasActiveOrders?: boolean;
  notes?: string;
  // eslint-disable-next-line no-unused-vars
  setNotes: (notes: string) => void;
  setOrder: (order: any) => void;
  setHasActiveOrders: (orders: PatronOrder[]) => void;
  clearOrders: () => void;
  clear: () => void;
  fetchActiveOrders: () => void;
};

export const OrderContext = createContext<OrderContextType>({
  // eslint-disable-next-line no-unused-vars
  setNotes: (notes: string) => {},
  // eslint-disable-next-line no-unused-vars
  setOrder: (order: any) => {},
  setHasActiveOrders: (orders: any) => {},
  clearOrders: () => {},
  clear: () => {},
  fetchActiveOrders: () => {},
});

interface Props {
  children: ReactElement;
}

const localStorageOrderKey = 'current-order-context';

function OrderContextProvider({ children }: Props) {
  const existingOrderContext = JSON.parse(
    localStorage.getItem(localStorageOrderKey) || `{}`,
  );
  const [order, setOrder] = useState<any>(existingOrderContext.order || {});
  const [notes, setNotes] = useState<string>(existingOrderContext.notes || '');
  const [hasActiveOrders, setHasActiveOrders] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const { cartItemsCount } = useContext(CartContext);

  const {
    data: activeOrders,
    loading: loadingActiveOrders,
    fetch: fetchActiveOrders,
  } = useQueryHook({
    query: GET_ALL_PATRON_ORDERS,
    skip: !userContext.patron,
  });

  useEffect(() => {
    if (userContext.patron?._id) {
      fetchActiveOrders({
        payload: {
          filter: {
            orderStatus: activeOrderStatusList,
            patronID: userContext.patron?._id,
          },
        },
      });
    }
  }, [userContext.patron?._id]);

  useEffect(() => {
    if (!loadingActiveOrders) {
      if (activeOrders?.getAllPatronOrders?.data) {
        filterActiveOrders(activeOrders.getAllPatronOrders.data);
      } else {
        setHasActiveOrders(false);
      }
    }
  }, [loadingActiveOrders]);

  const filterActiveOrders = (orders: PatronOrder[]) => {
    setHasActiveOrders(
      orders.some((order: PatronOrder) =>
        activeOrderStatusList.includes(order.orderStatus),
      ),
    );
  };

  const value: OrderContextType = {
    _id: order?._id,
    patronID: order?.patronID,
    orderStatus: order?.orderStatus,
    location: order?.location,
    pricing: order?.pricing,
    paymentIntentClientSecret: order?.paymentIntentClientSecret,
    createdAt: order?.createdAt,
    updatedAt: order?.updatedAt,
    hasActiveOrders: hasActiveOrders,
    notes: notes,
    setNotes,
    setHasActiveOrders: filterActiveOrders,

    clear: () => {
      localStorage.setItem(localStorageOrderKey, '{}');
      setOrder({});
      setNotes('');
    },
    clearOrders: () => {
      setHasActiveOrders(false);
    },
    setOrder,
    fetchActiveOrders: () => {
      fetchActiveOrders({
        payload: {
          filter: {
            orderStatus: activeOrderStatusList,
            patronID: userContext.patron?._id,
          },
        },
      });
    },
  };

  useEffect(() => {
    //TODO: Store cart in localstorage for now.
    //This need to be stored in the database once the backend supports.
    localStorage.setItem(localStorageOrderKey, JSON.stringify({
      order: order,
      notes: value.notes
    }));
  }, [order, notes]);

  useEffect(()=>{
    if (cartItemsCount != 0){
      return
    }
    setNotes('')
    const order = JSON.parse(localStorage.getItem(localStorageOrderKey) || '')
    localStorage.setItem(localStorageOrderKey, JSON.stringify({...order,notes:''}))
  },[cartItemsCount])

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

export default OrderContextProvider;
