import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { customTheme } from '../theme';

interface Props extends TypographyProps {
  text?: string;
  fontWeight?: number;
  fontSize?: number | string;
  isError?: boolean;
  fontColor?: string;
  style?: React.CSSProperties;
}

export const Text = ({
  text = '',
  isError = false,
  fontWeight = 500,
  fontSize = 14,
  fontColor = '#2A2A2A',
  style,
}: Props) => {

  return (
    <Typography
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontFamily={customTheme.fontFamily}
      sx={{
        color: isError ? 'red' : fontColor,
        letterSpacing: 0,
        lineHeight: 'normal',
        ...style,
      }}
    >
      {text &&
        (<Trans>
          {text}
        </Trans>
        )}
    </Typography>
  );
};
