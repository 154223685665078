import { Badge, Box } from '@mui/material';
import { MouseEventHandler, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { CategoryType } from '../types';
import { CustomisationOptionGroup } from '../types/customisation';
import { getPriceWithCurrency } from '../utils/charges';
import { CartController } from './cart-controller';
import { AppContext } from '../context/app-context';

interface Props {
  storeIsClosed?: boolean;
  name?: string;
  price?: number;
  count: number;
  outOfStock?: boolean;
  vendorIsClosed?: boolean;
  showInMenu?: boolean;
  category: string;
  alcoholSDE?: number;
  customisations?: CustomisationOptionGroup[];
  onIncrement?: MouseEventHandler | undefined;
  onDecrement?: MouseEventHandler | undefined;
}

const optionSymbol = {
  singleSelect: '',
  multiSelect: '+',
};

export const CartLineItem = (props: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);

  const {
    storeIsClosed,
    name,
    price,
    outOfStock,
    vendorIsClosed,
    customisations = [],
    onIncrement,
    onDecrement,
    count,
    category,
    alcoholSDE = 0,
    showInMenu,
  } = props;
  const addOptions =
    customisations?.map(customisation => {
      return `${optionSymbol[customisation.type]}${customisation.name}`;
    }) || [];

  const maxCustomisationSDE = customisations.reduce((max, customisation) => {
    return Math.max(max, customisation?.additionalAttributes?.stdDrinkEquivalence || 0);
  }, 0);

  const finalSDE = Math.max(alcoholSDE, maxCustomisationSDE);
  const itemSDETotalValue = (finalSDE * count).toFixed(1)
  const appContext = useContext(AppContext);

  const isItemUnavailable = () => {
   return appContext.venueType?.value === 'EVENT' ? (outOfStock || !showInMenu || vendorIsClosed) : (outOfStock || !showInMenu || vendorIsClosed || storeIsClosed)
  }
  return (
    <Box>
      <Box
        pt={2}
        display="flex"
        justifyContent={'space-between'}
        alignItems="center"
        sx={{
          filter: () =>
            isItemUnavailable() ? 'grayscale(100%)' : 'grayscale(0%)',
        }}
      >
        <Box>
          <Box display={'flex'} alignItems={'center'}>
            <Text
              fontSize={16}
              fontWeight={500}
              text={name}
              fontColor={category === CategoryType.ALCOHOL ? '#FF50DD' : '#1D2306'}
              style={{
                textDecoration: isItemUnavailable() ? 'line-through' : 'none',
              }}
            />
            {alcoholSDE > 0 && category === CategoryType.ALCOHOL &&
              <Badge
                badgeContent={itemSDETotalValue}
                sx={{
                  marginLeft: '24px',
                  "& .MuiBadge-badge": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF50DD",
                  }
                }}
              />}
          </Box>
          <Text
            fontSize={14}
            text={getPriceWithCurrency(price)}
            fontWeight={400}
            style={{
              textDecoration: isItemUnavailable() ? 'line-through' : 'none',
            }}
          />
          {isItemUnavailable() && (
            <Box mt={1}>
              <Text
                text={t('common.text.NotAvailable', { ns: 'common' })}
                style={{ letterSpacing: 1 }}
              />
            </Box>
          )}
          {addOptions?.length > 0 && (
            <Box maxWidth={210}>
              <Text fontSize={14} text={addOptions.join(', ')} fontWeight={400} fontColor={'#5C5C5C'} />
            </Box>
          )}
        </Box>
        <CartController
          count={count}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
        />
      </Box>
    </Box>
  );
};

