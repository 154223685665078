import { Box } from '@mui/material';
import { Text } from '../../elements/text';
import { CustomisationProps, inputType } from './types';

export function Customisation(props: CustomisationProps) {
  const { customisation, itemCount, error, helperText } = props;
  const { options, name, _id } = customisation;
  const selectType = customisation.type;
  const Input = inputType[selectType];

  return (
    <Box mt={2} mb={1}>
      <Box display='flex' justifyContent={'space-between'} alignItems={'center'} mb={'4px'}>
        <Text text={name} fontWeight={600} fontSize={18} />
        {selectType === 'singleSelect' &&
          <Text text='REQUIRED' fontSize={12} fontWeight={500} fontColor={'#5C5C5C'}
            style={{
              border: '0.985px solid #5C5C5C',
              borderRadius: '8px',
              padding: '2px 6px 2px 6px',
              backgroundColor: '#FFFF'
            }} />}
      </Box>
      <Input
        name={_id}
        options={options}
        itemCount={itemCount}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}
