import React, { MouseEventHandler } from 'react';
import { Box, styled } from '@mui/material';
import { Text } from '../elements/text';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Elipse = styled(Box)(() => ({
  width: '24px',
  height: '24px',
  background: '#F6FFD6',
  borderRadius: '12px',
}));

interface Props {
  onIncrement?: MouseEventHandler | undefined;
  onDecrement?: MouseEventHandler | undefined;
  count?: number;
}

export const CartController = ({
  count = 0,
  onIncrement = () => undefined,
  onDecrement = () => undefined,
}: Props) => {
  return (
    <Box
      display={'flex'}
      p={1}
      width={100}
      height={40}
      sx={{
        background: '#FFFFFF',
        boxSizing: 'border-box',
        border: 'solid #aaa 1px',
        borderRadius: '8px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Elipse sx={{ border: 'dotted #888 1px' }}>
        <RemoveIcon onClick={onDecrement} />
      </Elipse>
      <Box>
        <Text fontSize={24} text={count.toString()} />
      </Box>
      <Elipse sx={{ border: 'dotted #888 1px' }}>
        <AddIcon onClick={onIncrement} />
      </Elipse>
    </Box>
  );
};
