import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

import config from '../config';
import localeCommon from '../assets/locales/en/common.json';
import localePatronPortal from '../assets/locales/en/patron_portal.json';

export const defaultNS = 'common';


i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        resources: {

        },
        /*
        backend: {
            loadPath: `${config.host}/public/locales/{{lng}}/{{ns}}.json`,
        },*/
        debug: true, // TODO: Environment check.
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true,
        },
        fallbackLng: 'en',
        lng: 'en',
        ns: ['common', 'patron_portal'],
        defaultNS: defaultNS,
        load: 'currentOnly',
    });

i18n.addResourceBundle('en', 'common', localeCommon);
i18n.addResourceBundle('en', 'patron_portal', localePatronPortal);
i18n.languages = ['en']; // Todo: From Region Config

export default i18n;