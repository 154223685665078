import styled from '@emotion/styled';
import { Box } from "@mui/material";

export const NavigateBox = styled(Box)(() => ({
  width: '100%',
  padding: '12px',
  background: '#2A2A2A',
  color: '#D2FF28',
  borderRadius: '12px',
  textAlign: 'center',
  boxSizing: 'border-box',
  alignContent: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  outline: '1px solid #D2FF28',
  borderWidth: '2px',
  outlineOffset: '-8px',
}));