import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Image } from '../../elements/image';
import { useNavigate } from 'react-router-dom';
import { images } from '../../constants/images';
import { Text } from '../../elements/text';
import { useTranslation } from 'react-i18next';
import { StoreWithDetails } from '../vendor-menu/types';

export function StoreCard(store: StoreWithDetails) {
  const { t } = useTranslation(['patron_portal']);
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        if (store.isOpen && store.menu) {
          navigate(`/vendors/${store.ownerID}/stores/${store.id}`);
        }
      }}
    >
      <Image
        style={{
          height: 'auto',
          borderRadius: '12px',
          filter:
            !store.isOpen || !store.menu ? 'grayscale(100%)' : 'grayscale(0%)',
        }}
        src={
          store.storeFrontImageUrl ||
          store.owner.imageUrl ||
          images.VENDOR_DEFAULT_IMAGE
        }
      />
      <Box mt={1.5}>
        <Text text={store.displayName} fontSize={16} fontWeight={500} />
        {!store.isOpen ? (
          <Text
            text={t('patron_portal.vendor.currentlyUnavailable', {
              ns: 'patron_portal',
            })}
            fontSize={14}
            fontWeight={400}
            fontColor={'#FF50DD'}
          />
        ) : null}
      </Box>
    </Box>
  );
}
