import { Box, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { customTheme } from '../theme';

interface Props extends TypographyProps {
  text?: string;
  boldText?: string;
  fontSize?: number;
  fontColor?: string;
  style?: React.CSSProperties;
}

export const CompoundText = ({
  text = '',
  boldText = '',
  fontSize = 14,
  fontColor = 'black',
  style,
}: Props) => {
  return (
    <Box display={'inline-flex'}>
      <Typography
        fontWeight={400}
        fontSize={fontSize}
        fontFamily={customTheme.fontFamily}
        sx={{
          color: fontColor,
          mr: 1,
          ...style,
        }}
      >
        {text}
      </Typography>
      <Typography
        fontWeight={600}
        fontSize={fontSize}
        fontFamily={customTheme.fontFamily}
        sx={{
          color: fontColor,
          ...style,
        }}
      >
        {boldText}
      </Typography>
    </Box >
  );
};
