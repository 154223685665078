import React from 'react';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { ItemCard } from '../item-card';
import { Text } from '../../elements/text';
import { Item } from '../../types';
import { Menu, MenuItemGroup, MenuItemGroupItem } from './types';
import { GET_ITEMS, GET_MENU } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import { Loader } from '../../elements/loader';
import MenuItemGroupList from './menu-item-group-list';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const MenuItemsList: React.FC<{ menuID: string; ownerID?: string, storeID?: string }> = ({
  menuID,
  ownerID,
  storeID,
}) => {
  const { data: getMenuData, loading: getMenuDataLoading } = useQuery(
    GET_MENU,
    {
      variables: {
        id: menuID,
        vendorID: ownerID,
      },
    },
  );

  let sortedItemGroups: MenuItemGroup[] = [];

  if (getMenuDataLoading) {
    return <Loader visible={true} />;
  }

  const menu: Menu & { items: Item[] } = getMenuData?.VendorMenu;

  if (menu) {
    const itemGroups = menu.itemGroups;
    const itemIDs: string[] = [];
    itemGroups.forEach(itemGroup => {
      itemGroup.itemIDs.forEach(item => itemIDs.push(item.itemID));
    });

    const items: Item[] = menu.items;

    //arrange items
    sortedItemGroups = itemGroups.map(itemGroup => {
      const itemIdsWithName: MenuItemGroupItem[] = itemGroup.itemIDs
        .filter(itemID => {
          return items.find(item => item.id === itemID.itemID);
        })
        .map(itemId => {
          const item = items.find(item => item.id === itemId.itemID);
          return { ...itemId, item: item, storeID } as MenuItemGroupItem;
        });
      itemIdsWithName.sort((item1, item2) => item1.position - item2.position);
      return { ...itemGroup, itemIDs: itemIdsWithName };
    });
    sortedItemGroups.sort((item1, item2) => item1.position - item2.position);
  }

  if (!menu || sortedItemGroups.length === 0 || !menu?.items?.length) {
    return (
      <Grid item xs={12} p={{ xs: 2, md: 0 }} mb={10}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Text
              text={t('patron_portal.vendor.noItemsMsg', {
                ns: 'patron_portal',
              })}
              fontSize={18}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  
  const defaultExpanded= sortedItemGroups.length > 1 ? false : true;
  return (
    <Grid item xs={12} pb={{ xs: 2, md: 2 }}>
      <Grid item xs={12} pb={2}>
        {sortedItemGroups.map(itemGroup => {
          return itemGroup.itemIDs.length ? (
            <MenuItemGroupList key={itemGroup.id} itemGroup={itemGroup} defaultExpanded= {defaultExpanded}/>
          ) : null;
        })}
      </Grid>
    </Grid>
  );
};

export default MenuItemsList;
