import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_ROUTE } from '../constants/routes';
import { AppContext } from '../context/app-context';
import { CartContext } from '../context/cart-context';
import { OrderContext } from '../context/order-context';
import { UserContext } from '../context/user-context';
import { CREATE_ORDER } from '../graphql/mutation';
import { extractAllErrorMesasges } from '../utils/graphql';
import { useSnackbar } from 'notistack';
import { useMutationHook } from './use-mutation';
import { PatronConsent, NoticeName } from '../types';

interface Props {
  onError: (e: any) => void;
  savePatronAgeConsent?: boolean;
}

export const useCreateOrder = ({
  onError,
  savePatronAgeConsent = false,
}: Props) => {
  const userContext = useContext(UserContext);
  const { cart = {} } = useContext(CartContext);
  const {
    stadium,
    row,
    seat,
    section,
    collectionPoint,
    serviceMethod,
    isSectionClickAndCollectEnabled,
  } = useContext(AppContext);
  const orderContext = useContext(OrderContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createOrder,
    loading,
    data,
  } = useMutationHook({
    mutation: CREATE_ORDER,
    onError: e => {
      enqueueSnackbar(extractAllErrorMesasges(e), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onError(e);
    },
    onCompleted(data) {
      orderContext.setOrder(data.createOrder);
      navigate(PAYMENT_ROUTE);
    },
  });

  const patronAgeConsent: PatronConsent = {
    patronID: userContext.patron?._id,
    consentName: NoticeName.AGE_CONFIRMATION,
    declaration: 'I AM OVER 18',
    ts: new Date(Date.now()),
  };

  const createOrderHandler = () => {
    createOrder({
      variables: {
        createOrderTypeInput: {
          cartItems: Object.keys(cart).map(key => {
            return {
              itemID: cart[key].id,
              count: cart[key].count,
              storeID: cart[key].storeID,
              customisations: cart[key].customisations,
              serviceMethod: serviceMethod?.value,
            };
          }),
          patronID: userContext?.patron?._id,
          location: {
            venue: stadium?.value,
            section: section?.value,
            collectionPoint: collectionPoint?.value,
            row: row?.value,
            seat: seat?.value,
          },
          serviceMethod: serviceMethod?.value,
          isClickAndCollect: isSectionClickAndCollectEnabled?.value,
          consents: savePatronAgeConsent ? patronAgeConsent : null,
          notes: orderContext?.notes,
        },
      },
    });
  };

  return [createOrderHandler, { loading, data }] as any;
};
