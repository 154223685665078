import { Box, Dialog, Grid, Icon, Paper } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import '../styles/network-error-page.css';
import { ReactComponent as PizzaIcon } from '../assets/icons/PizzaSticker.svg';
import { Text } from '../elements/text';
import { CustomButton } from '../elements/button';

interface INetworkError {
  text?: string;
  helperText?: string;
  statusIcon?: ReactNode;
  retryButton?: boolean;
  isOnline?: boolean;
}

export const NetworkErrorPage = ({ retryButton = false, isOnline = true, ...props }: INetworkError) => {
  // TODO: make the timer as a prop or config.
  const [countdown, setCountdown] = useState(3);

  const handleRefresh = () => {
    if (isOnline) {
      window.location.reload();
    }
  };


  useEffect(() => {
    if (!retryButton) {
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);

        return () => {
          clearTimeout(timer);
        };
      } else {
        handleRefresh();
      }
    }
  }, [countdown]);



  return (

    <Grid container height={retryButton ? '100vh' : 'auto'} py={retryButton ? 0 : 2}>
      <Grid item margin={'auto'} alignContent={'center'}>
        <Box textAlign={'center'} mx={'20px'}>
          <Box m="auto">
            <Icon style={{ animation: 'scale-up 0.5s ease', height: '100%', width: '100%' }}>
              {props.statusIcon || <PizzaIcon />}
            </Icon>
          </Box>

          <Box mt={'8px'} mx={''}>
            <Text
              fontSize={24}
              fontWeight={700}
              text={props.text || "Something went wrong!"}
            />
          </Box>

          <Box mt={'2px'}>
            <Text
              fontSize={15}
              text={props.helperText || 'Enjoy the event until we fix it'}
              fontColor={'#F84244'}
            />
          </Box>
        </Box>
        {retryButton &&
          <Box width={'80%'} margin={'auto'} marginTop={'24px'}>
            <CustomButton
              onClick={handleRefresh}
              title={'Refresh Page'}
            />
          </Box>}
        {!retryButton &&
          <Box mt={'14px'} display={'flex'} justifyContent={'center'}>
            <Text fontSize={12} text={`Refreshing the page in ${countdown} seconds`} fontColor={'#5C5C5C'} />
          </Box>}
      </Grid>
    </Grid>
  );
}
