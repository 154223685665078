import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../components/header';
import {
  HOME_PAGE_ROUTE,
  LIST_VENDORS_PAGE_ROUTE,
  VIEW_CART_ROUTE,
} from '../constants/routes';
import { Charges, Item, PreOrderSummary, Pricing, Vendor } from '../types';
import { ItemCard } from '../components/item-card';
import { customTheme } from '../theme';
import {
  GET_CHARGES,
  GET_ITEMS,
  GET_PRE_ORDER_SUMMARY,
  GET_VENDOR,
} from '../graphql/query';
import { Image } from '../elements/image';
import { Loader } from '../elements/loader';
import { AppContext } from '../context/app-context';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import { CustomButton } from '../elements/button';
import { CartContext } from '../context/cart-context';
import { getPriceWithCurrency } from '../utils/charges';
import { ReactComponent as CartSmallIcon } from '../assets/icons/ICON_CART_SMALL.svg';
import { useTranslation } from 'react-i18next';
import { images } from '../constants/images';
import { Text } from '../elements/text';
import { VenueTypes } from './home/home';
import { ServiceMethod } from '../types/order';

export const VendorItems = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['patron_portal', 'common']);

  const { vendorID = '' } = useParams();
  const {
    selectedVendor,
    stadium,
    isSectionClickAndCollectEnabled,
    serviceMethod,
    venueType,
    row,
    seat,
    section: sectionId,
    sections,
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { cartItemsCount, cart } = useContext(CartContext);

  const queryItems = Object.keys(cart).map(key => {
    return {
      itemID: cart[key].id,
      count: cart[key].count,
      storeID: cart[key].storeID,
      customisations: cart[key].customisations,
      serviceMethod: serviceMethod?.value,
    };
  });

  const { data: getSummaryData, loading: preOrderSummaryLoading } = useQuery(
    GET_PRE_ORDER_SUMMARY,
    {
      variables: {
        preOrderSummaryInput: {
          items: queryItems,
          venueId: stadium?.value,
          serviceMethod: serviceMethod?.value,
        },
      },
    },
  );

  const preOrderSummary =
    (getSummaryData?.getPreOrderSummary as PreOrderSummary) || {};
  const { pricing = {} } = preOrderSummary;
  const { subTotal } = pricing as Pricing;

  const { data = { getPublicVendor: {} }, loading: getVendorLoading } =
    useQuery(GET_VENDOR, {
      onCompleted: data => {
        selectedVendor?.setValue(data.getPublicVendor);
      },
      variables: {
        id: vendorID,
      },
    });

  const {
    data: getChargesData = {
      getCharges: {
        seatDeliveryCharge: 0,
        markupCharge: 0,
      },
    },
    loading: getChargesLoading,
  } = useQuery(GET_CHARGES);
  const charges = getChargesData.getCharges as Charges;

  const {
    data: getItemsData = { getItems: [] },
    loading: getItemsDataLoading,
  } = useQuery(GET_ITEMS, {
    variables: {
      itemQueryParams: {
        filter: {
          vendorID,
          showInMenu: true,
        },
      },
    },
  });

  const vendor: Vendor = data.getPublicVendor;
  const items: Item[] = getItemsData.getItems;

  useEffect(() => {
    if (vendor.isDisabled) {
      navigate(LIST_VENDORS_PAGE_ROUTE);
      const message = t('patron_portal.vendor.notAvailableMsg', {
        ns: 'patron_portal',
        tradingName: vendor.tradingName,
      });
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  }, [vendor]);

  if (getVendorLoading || getItemsDataLoading || getChargesLoading) {
    return <Loader visible={true} />;
  }
  const selectedSection = sections?.value?.find(
    section => section.id === sectionId?.value,
  );
  const tableNumberTitle = `${t('common.tableNo', { ns: 'common' })}: ${
    seat?.value
  }`;
  const ttsTitle = t('patron_portal.order.msgLocationTTS', {
    ns: 'patron_portal',
    sectionName: selectedSection?.label,
    row: row?.value,
    seat: seat?.value,
  });

  const FloatingViewCartButton = () => {
    const navigateCart = useNavigate();
    return (
      <Box
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
          minWidth: '50%',
          padding: '16px',
          display: 'flex',
          justifyContent: 'center',
          background: 'linear-gradient(to top, #FFFF, #F5F5F5)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow: '0px 8px 32px 6px #F5F5F5',
        }}
      >
        <CustomButton
          title={t('patron_portal.label.viewCart', { ns: 'patron_portal' })}
          badgeTitle={cartItemsCount.toString()}
          rightTitle={getPriceWithCurrency(subTotal || 0)}
          icon={<CartSmallIcon />}
          onClick={() => navigateCart(VIEW_CART_ROUTE)}
          loading={preOrderSummaryLoading}
        />
      </Box>
    );
  };

  const itemSorter = (it1: Item, it2: Item) => {
    const nullOrUndefined = (o: any) => o === null || o === undefined;
    // null and undefined go to the end of list
    if (
      nullOrUndefined(it1.serialNumber) &&
      nullOrUndefined(it2.serialNumber)
    ) {
      return -1;
    }
    if (nullOrUndefined(it1.serialNumber)) {
      return 1;
    }
    if (nullOrUndefined(it2.serialNumber)) {
      return -1;
    }
    return it1.serialNumber - it2.serialNumber;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header
            backIconRoute={
              venueType?.value === VenueTypes.DINE_IN
                ? HOME_PAGE_ROUTE
                : LIST_VENDORS_PAGE_ROUTE
            }
            middleComponent={
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  fontFamily={customTheme.fontFamily}
                  fontSize={18}
                  fontWeight={600}
                >
                  {vendor.tradingName}
                </Typography>
                <Typography
                  fontFamily={customTheme.fontFamily}
                  fontSize={12}
                  fontWeight={400}
                >
                  {serviceMethod?.value === ServiceMethod.DELIVERY && ttsTitle}
                  {venueType?.value === VenueTypes.DINE_IN &&
                    serviceMethod?.value ===
                      ServiceMethod.COLLECT_FROM_VENDOR &&
                    tableNumberTitle}
                </Typography>
              </Box>
            }
          />
        </Grid>

        <Grid container item md={8} margin="auto">
          <Grid item xs={12}>
            <Image
              src={vendor.imageUrl || images.VENDOR_DEFAULT_IMAGE}
              style={{ maxHeight: '75vh' }}
            />
          </Grid>
          <Grid item xs={12} p={{ xs: 2, md: 0 }} mb={10}>
            {items.length === 0 ? (
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'center' }}>
                  <Text
                    text={t('patron_portal.vendor.noItemsMsg', {
                      ns: 'patron_portal',
                    })}
                    fontSize={18}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} pb={2}>
                {items
                  .slice()
                  .sort(itemSorter)
                  .map((item: Item, index: number) => (
                    <Box
                      sx={{
                        height: {
                          xs: '90px',
                          sm: '150px',
                          md: '180px',
                          lg: '210px',
                          xl: '210px',
                        },
                      }}
                      key={`${item._id}-${index}`}
                      mb={1.5}
                    >
                      <ItemCard item={item} />
                    </Box>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {cartItemsCount > 0 && <FloatingViewCartButton />}
    </>
  );
};
