import { Charges, Item } from '../types';
import  currency from 'currency.js';
import { useContext } from 'react';
import { AppContext } from '../context/app-context';

export const getItemPrice = (item: Item, charges: Charges) => {
  const markupRate =  charges.markupCharge / 100;
  const markupPrice = currency(item.price * markupRate, { precision: 2 });
  const itemPrice = currency(item.price + markupPrice.value, { precision: 2 });
  return itemPrice.value;
};

export const getPriceWithCurrency = (number = 0, symbol = '') => {
  const { venueCurrencySymbol } = useContext(AppContext);
  return `${symbol ? symbol : venueCurrencySymbol?.value} ${currency(number, { precision: 2 }).value.toFixed(2)}`;
}

export const getMinimumOrderPriceWithCurrency = (minOrderPrice: number, subTotal: number) => {
  const { venueCurrencySymbol } = useContext(AppContext);
  return `${venueCurrencySymbol?.value}${(minOrderPrice - subTotal).toFixed(2)}`
}