import React, { useState } from 'react';
import { ViewItem } from './view-item';
import { Box, Drawer, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Item } from '../types';

type MenuItemModalProps = {
  item: Item;
  storeID?: string
  isModalOpen: boolean;
  onClose: any;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuItemModal = ({
  item,
  storeID,
  isModalOpen,
  setIsModalOpen,
  onClose,
}: MenuItemModalProps) => {
  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={isModalOpen}
        onClose={onClose}
        disableAutoFocus={true}
        closeAfterTransition
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            maxHeight: '80%',
            overflowY: 'auto',
            boxShadow: 0,
          },
        }}
      >
        <Box display={'flex'} justifyContent={'center'}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'relative',
            }}
          >
            <CloseRoundedIcon
              sx={{
                fontSize: '20px',
                color: '#2A2A2A',
                cursor: 'pointer',
                padding: '2px',
                borderRadius: '48%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                boxShadow: 1,
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            pointerEvents: 'auto',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
          }}
        >
          <ViewItem item={{itemID:item._id, storeID}} setIsModalOpen={onClose} />
        </Box>
      </Drawer>
    </>
  );
};

export default MenuItemModal;
