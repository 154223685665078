import { useContext } from 'react';
import { Typography, useMediaQuery, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { customTheme } from '../theme';
import { ServiceMethod } from '../types/order';
import { AppContext } from '../context/app-context';
import { VenueTypes } from '../pages/home/home';

interface SeatInfoProps {
  stadium?: string;
  section?: string;
  collectionPoint?: string;
  row?: string;
  seat?: string;
  isClickAndCollectEnabled?: boolean;
  serviceMethod?: string;
}

export function SeatInfo({
  stadium,
  section,
  collectionPoint,
  row,
  seat,
  isClickAndCollectEnabled,
  serviceMethod,
}: SeatInfoProps) {
  const isNarrowScreen = useMediaQuery('(max-width: 380px)');
  const { t } = useTranslation(['patron_portal', 'common']);
  const { venueType } = useContext(AppContext);
  const getPatronLocationText = () => {
    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        return t('patron_portal.order.msgLocationTTS', {
          ns: 'patron_portal',
          sectionName: section,
          row: row,
          seat: seat,
        });

      case ServiceMethod.COLLECT_FROM_VENDOR:
        if (venueType?.value === VenueTypes.HOTEL) {
          return '';
        }
        return t('patron_portal.order.msgLocationCNC', { ns: 'patron_portal' });

      case ServiceMethod.COLLECTION_POINT:
        return t('patron_portal.order.msgLocationCP', {
          ns: 'patron_portal',
          collectionPointName: collectionPoint,
        });

      default:
        return;
    }
  };

  return (
    <Grid container item xs={12} display={'block'} px={2}>
      <Typography
        fontFamily={customTheme.fontFamily}
        fontSize={isNarrowScreen ? '14px' : '18px'}
        fontWeight={500}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {stadium}
      </Typography>
      <Grid item xs={12} display="flex" width="fit-content" margin="auto">
        <Typography
          fontFamily={customTheme.fontFamily}
          fontSize={isNarrowScreen ? '12px' : '14px'}
          fontWeight={500}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {getPatronLocationText()}
        </Typography>
      </Grid>
    </Grid>
  );
}
