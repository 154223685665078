/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery } from '@apollo/client';
import clone from 'lodash/clone';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v1 } from 'uuid';
import { GET_PRE_ORDER_SUMMARY } from '../graphql/query';
import { PreOrderSummary, SummaryItem } from '../types';
import { AppContext } from './app-context';

export type PreOrderSummaryItemInput = {
  itemID: string;
  count: number;
  customisations?: string[];
};

export type CartItem = {
  id: string;
  name: string;
  count: number;
  storeID?: string;
  customisations?: string[];
};

export type Cart = {
  [cartItemID: string]: CartItem;
};

type CartContextType = {
  cart: Cart;
  addToCart: (
    cartItemID: string,
    item: CartItem,
    count: number,
    onCartUpdate?: () => void,
  ) => void;
  addMultipleItemsToCart: (
    items: CartItem[],
    onCartUpdate?: any,
  ) => void;
  cartLoading: boolean;
  clear: () => void;
  empty: () => boolean;
  removeFromCart: (cartItemID: string, count: number) => void;
  removeMultipleFromCart: (cartItemIds: string[]) => void;
  cartItemsCount: number;
  replaceCart: (cart: Cart) => void;
};

function addToCartClone(
  cart: Cart,
  cartItemID: string,
  item: CartItem,
  count: number,
): Cart {
  const cartClone = clone(cart);
  if (cartItemID in cartClone) {
    // console.log(`matching item: ${JSON.stringify(cartClone)}`)
    return {
      ...cartClone,
      [cartItemID]: {
        ...cartClone[cartItemID],
        count: cartClone[cartItemID].count + count,
      },
    };
  }
  // console.log(`not matching item: ${JSON.stringify(cartClone)}, \n ${JSON.stringify(cart)} \n looking for ${cartItemID}`)
  return {
    ...cartClone,
    [cartItemID]: {
      id: item.id,
      name: item.name,
      count: count,
      storeID: item.storeID,
      customisations: item.customisations,
    },
  };
}

function removeFromCartClone(
  cart: Cart,
  cartItemID: string,
  count: number,
): Cart {
  const cartClone = clone(cart);
  if (cartItemID in cartClone && cart[cartItemID].count === 1) {
    return omit(cart, cartItemID);
  }
  if (cartItemID in cartClone) {
    return {
      ...cart,
      [cartItemID]: { ...cart[cartItemID], count: cart[cartItemID].count - 1 },
    };
  }
  return cartClone;
}

function removeMultipleFromCartClone(
  cartClone: Cart,
  cartItemIds: string[],
): Cart {
  for (const itemId of cartItemIds) {
    if (itemId in cartClone) {
      cartClone = omit(cartClone, itemId);
    }
  }
  return cartClone;
}

function preparePreOrderSummaryInput(cart: Cart, serviceMethod: string): PreOrderSummaryItemInput[] {
  const preOrderSummaryItemInput = map(Object.keys(cart), (key: string) => {
    return {
      itemID: cart[key]?.id,
      count: cart[key]?.count,
      storeID: cart[key].storeID,
      customisations: cart[key]?.customisations,
      serviceMethod: serviceMethod
    };
  });
  return preOrderSummaryItemInput;
}

export const CartContext = createContext<CartContextType>({
  cart: {},
  addToCart: () => { },
  cartLoading: false,
  removeFromCart: () => { },
  removeMultipleFromCart: () => { },
  cartItemsCount: 0,
  clear: () => 0,
  empty: () => false,
  replaceCart: () => { },
  addMultipleItemsToCart: () => { },
});

interface Props {
  children: ReactElement;
}

const localStorageCartKey = 'livelly-cart';

// Pre order summary is the single source of truth for cart
function CartContextProvider({ children }: Props) {
  //TODO: handle error
  const [fetchPreOrderSummary, { loading, error }] = useLazyQuery(
    GET_PRE_ORDER_SUMMARY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { isSectionClickAndCollectEnabled, stadium, serviceMethod } = useContext(AppContext);

  const existingCart = JSON.parse(
    localStorage.getItem(localStorageCartKey) || `{}`,
  );
  const [cart, setCart] = useState<Cart>(existingCart || {});
  
  const cartItemsCount = Object.keys(cart).reduce(
    (previousValue, currentKey) => {
      return previousValue + (cart[currentKey].count || 0);
    },
    0,
  );

  async function prepareCart(cartItems: PreOrderSummaryItemInput[]) {
    // console.log(`start prepareCart: ${JSON.stringify(cartItems)}`)
    const { data } = await fetchPreOrderSummary({
      variables: {
        preOrderSummaryInput: {
          items: cartItems,
          venueId: stadium?.value,
          serviceMethod: serviceMethod?.value,
        },
      },
    });

    const preOrderSummary = data.getPreOrderSummary as PreOrderSummary;
    const { available = [], unavailable = [] } = preOrderSummary;
    const newCartItems: any[] = [];
    available.forEach((summaryItem: SummaryItem) =>
      newCartItems.push({
        [summaryItem.key as string]: {
          id: summaryItem._id,
          count: summaryItem.count,
          storeID: summaryItem.storeID,
          customisations: map(summaryItem.customisations, '_id'),
        },
      }),
    );
    unavailable.forEach((summaryItem: SummaryItem) =>
      newCartItems.push({
        [summaryItem.key as string]: {
          id: summaryItem._id,
          count: summaryItem.count,
          storeID: summaryItem.storeID,
          customisations: map(summaryItem.customisations, '_id'),
        },
      }),
    );
    // console.log(`end: ${JSON.stringify(cartItems)}`)
    return Object.assign({}, ...newCartItems);
  }

  const addToCart = async (
    cartItemID: string,
    item: CartItem,
    count: number,
    onCartUpdate?: any, //callback function
  ) => {
    const cartClone = clone(cart);
    const cartItems = preparePreOrderSummaryInput(
      addToCartClone(cartClone, cartItemID, item, count), serviceMethod?.value || ""
    );
    const newCart = await prepareCart(cartItems);
    setCart(newCart);
    onCartUpdate();
  };

  const addMultipleItemsToCart = async (items: CartItem[], onCartUpdate?: any) => {
    const cartClone = clone(cart);
    const cartItems = items.map((item) =>
      preparePreOrderSummaryInput(
        addToCartClone(cartClone, v1(), item, item.count),
        serviceMethod?.value || ""
      )
    );
    const newCart = await prepareCart(cartItems.flat());
    setCart(newCart);
    onCartUpdate();
  };

  const removeFromCart = async (cartItemID: string, count: number) => {
    const cartClone = clone(cart);
    const cartItems = preparePreOrderSummaryInput(
      removeFromCartClone(cartClone, cartItemID, count), serviceMethod?.value || ""
    );
    const newCart = await prepareCart(cartItems);
    setCart(newCart);
  };

  const removeMultipleFromCart = async (cartItemIds: string[]) => {
    const cartClone = clone(cart);
    const cartItems = preparePreOrderSummaryInput(
      removeMultipleFromCartClone(cartClone, cartItemIds), serviceMethod?.value || ""
    );
    const newCart = await prepareCart(cartItems);
    setCart(newCart);
  };

  const replaceCart = (cart: Cart) => {
    setCart(cart);
  };

  const value: CartContextType = {
    cart,
    addToCart,
    cartLoading: loading,
    clear: () => {
      setCart({});
      localStorage.setItem(localStorageCartKey, '{}');
    },
    empty: () => Object.keys(cart).length == 0,
    cartItemsCount,
    removeFromCart,
    removeMultipleFromCart,
    replaceCart,
    addMultipleItemsToCart,
  };

  useEffect(() => {
    //TODO: Store cart in localstorage for now.
    //This need to be stored in the database once the backend supports.
    // console.log(`saving: ${JSON.stringify(value.cart, undefined, 2)}`)
    localStorage.setItem(localStorageCartKey, JSON.stringify(value.cart));
  }, [value]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export default CartContextProvider;
