import { WhiteLabelConfig } from "../context/app-context";
import { images } from "./images";


const publicFolder = process.env.PUBLIC_URL;
export const APP_DEFAULT_LABEL_CONFIG: WhiteLabelConfig = {
    appId: "patron-client",
    installationId: "default",
    displayName: "Liively",
    pageTitle: "Liively | Order Food Online | In-venue Delivery",
    pageDescription: "We're the cure for queuing",
    faviconUrl: `${publicFolder}/favicon-32x32.png`,
    appLogoImageUrl: `${publicFolder}/favicon-96x96.png`,
    landingImageUrl: images.DEFAULT_LANDING_PAGE_IMAGE,
    manifestUrl: `${publicFolder}/manifest.json`,
    features: {
        useNewMenu: false,
        enableAzupay: true
    }
}