import { Currency, Item } from '.';

export enum OptionGroupSelectionType {
  multiSelect = 'multiSelect',
  singleSelect = 'singleSelect',
}
export interface Location {
  venue: string;
  venueName: string;
  sectionName: string;
  section: string;
  seat: string;
  row: string;
  collectionPoint: string;
}
export enum PatronOrderStatus {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  RECEIVED = 'RECEIVED',
  PREPARING = 'PREPARING',
  PREPARED = 'PREPARED',
  READY_TO_COLLECT = 'READY_TO_COLLECT',
  PARTIALLY_ASSIGNED = 'PARTIALLY_ASSIGNED',
  PARTIALLY_READY_TO_COLLECT = 'PARTIALLY_READY_TO_COLLECT',
  ASSIGNED = 'ASSIGNED',
  PICKED = 'PICKED',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
  PATRON_CANCELLED = 'PATRON_CANCELLED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
}
export interface OrderPatron {
  _id: string;
  name: string;
  phoneNumber: string;
}

export interface OrderCharges {
  markUpRate: number;
  deliveryCharge: number;
  stripeFee: number;
}

export interface OrderVendor {
  _id: string;
  tradingName: string;
  phoneNumber: string;
  imageUrl: string;
}

export interface OrderPatron {
  _id: string;
  name: string;
  phoneNumber: string;
}

export interface OrderRunner {
  _id: string;
  name: string;
  phoneNumber: string;
}

export enum PaymentStatus {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_AUTHORISED = 'PAYMENT_AUTHORISED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
}

export enum ServiceMethod {
  DELIVERY = 'DELIVERY',
  COLLECTION_POINT = 'COLLECTION_POINT',
  COLLECT_FROM_VENDOR = 'COLLECT_FROM_VENDOR',
}

export interface PatronOrder {
  _id: string;
  patron?: OrderPatron;
  orderStatus: PatronOrderStatus;
  location: Location;
  vendorOrders: VendorOrder[];
  pricing: Pricing;
  paymentIntentId: string;
  paymentIntentClientSecret: string;
  charges?: OrderCharges;
  paymentStatus: PaymentStatus;
  orderNumber: string;
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;
  isClickAndCollectEnabled: boolean;
  serviceMethod: string;
}

export interface OptionWithType {
  _id: string;
  name: string;
  description: string;
  optionGroupID: string;
  type?: OptionGroupSelectionType;
  price?: number;
  markupPrice?: number;
}

export interface OrderedItem {
  _id: string;
  name: string;
  price: number;
  markupPrice: number;
  count: number;
  updatedQuantity: number;
  isRejected?: boolean;
  isUpdated?: boolean;
  customisations?: OrderedItemOption[];
}

export interface OrderedItemOption {
  _id: string;
  name: string;
  price: number;
  type: string;
  markupPrice: number;
}

export interface VendorOrder {
  _id: string;
  orderStatus: OrderStatus;
  orderNumber: string;
  patronOrderID: string;
  items: OrderedItem[];
  isOrderUpdated?: boolean;
  isPaymentComplete: boolean;
  pricing?: Pricing;
  patronOrder?: PatronOrder;
  runner?: OrderRunner;
  patron?: OrderPatron;
  vendor?: OrderVendor;
  options?: OptionWithType[];
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;
  isClickAndCollectEnabled: boolean;
  orderCurrency: Currency;
  serviceMethod: string;
}
export enum OrderStatus {
  RECEIVED = 'RECEIVED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  READY_TO_COLLECT = 'READY_TO_COLLECT',
  PATRON_CANCELLED = 'PATRON_CANCELLED',
  ADMIN_CANCELLED = 'ADMIN_CANCELLED',
  PREPARING = 'PREPARING',
  PREPARED = 'PREPARED',
  ASSIGNED = 'ASSIGNED',
  PICKED = 'PICKED',
  COMPLETE = 'COMPLETE',
  AT_COLLECTION_POINT = 'AT_COLLECTION_POINT',
  DELIVERED = 'DELIVERED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
}
export interface OrderedItems {
  itemID: string;
  count: number;
  customisations: string[];
}

export interface Pricing {
  subTotal: number;
  deliveryCharge: number;
  serviceCharge: number;
  total: number;
  initialTotal?: number;
}
