import { CardMedia } from '@mui/material';
import React from 'react';
interface Props {
  src: string;
  style?: React.CSSProperties;
  height?: number;
  alt?: string;
}

export const Image = (props: Props) => {
  return (
    <CardMedia
      component={'img'}
      image={props.src}
      alt={props.alt}
      style={{
        minHeight: '50px',
        height: props.height || 'auto',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        ...props.style,
      }}
    />
  );
};
