import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Image } from '../elements/image';
import { customTheme } from '../theme';
import { Vendor } from '../types';
import { useNavigate } from 'react-router-dom';
import { getListItemsRoute } from '../constants/routes';
import { images } from '../constants/images';
import { Text } from '../elements/text';
import { useTranslation } from 'react-i18next';

export function VendorCard(vendor: Vendor) {
  const { t } = useTranslation(['patron_portal']);
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        if (vendor.isOpen) {
          navigate(getListItemsRoute(vendor._id));
        }
      }}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      sx={{ aspectRatio: '1.61' }}
    >
      <Image
        style={{
          height: 'auto',
          maxHeight: '180px',
          borderRadius: '12px',
          filter: !vendor.isOpen ? 'grayscale(100%)' : 'grayscale(0%)',
        }}
        src={vendor.imageUrl || images.VENDOR_DEFAULT_IMAGE}
      />
      <Box mt={1.5}>
        <Text text={vendor.tradingName} fontSize={16} fontWeight={500} />
        {!vendor.isOpen ? (
          <Text
            text={t('patron_portal.vendor.currentlyUnavailable', {
              ns: 'patron_portal',
            })}
            fontSize={14}
            fontWeight={400}
            fontColor={'#FF50DD'}
          />
        ) : null}
      </Box>
    </Box>
  );
}
